import {Component} from "@angular/core";

@Component({
  selector: "app-terms-condition",
  templateUrl: "./terms-condition.component.html",
})

export class TermsConditionComponent {
  constructor() {
  }
}
