import {
  Component,
  OnDestroy,
  ViewChild,
  ViewEncapsulation,
  ElementRef,
  HostListener,
  OnInit,
  Input, ChangeDetectorRef, AfterViewInit, ChangeDetectionStrategy
} from "@angular/core";
import {ImagePathService} from "../../constants/image-path.service";

@Component({
  selector: "popup-layout",
  templateUrl: "./popup-layout.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: [
    "popup-layout.component.scss"
  ],
  encapsulation: ViewEncapsulation.None
})
export class PopupLayoutComponent implements OnDestroy, OnInit, AfterViewInit {
  @ViewChild("popup", { static: true}) popup;
  @ViewChild("content", { static: true}) contentElem: ElementRef;
  public windowHeight: number;
  @Input() showLogo = true;

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.windowHeight = window.innerHeight;
  }

  ngOnDestroy() {
  }

  constructor(public imageService: ImagePathService,
              public cdRef: ChangeDetectorRef) {

  }

  ngOnInit() {
    this.windowHeight = window.innerHeight;
  }

  get tooTall(): boolean {
    return this.contentElem.nativeElement.offsetHeight >= this.windowHeight;
  }

  close() {
    // this.popup.close();
  }

  ngAfterViewInit() {
    this.cdRef.detectChanges();
  }
}
