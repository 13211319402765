import {NgModule} from "@angular/core";
import {NgxSpinnerModule} from "ngx-spinner";
import {DynamicFormComponent} from "./dynamic-form/dynamic-form.component";
import {DynamicInputComponent} from "./dynamic-form/dynamic-input";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BrowserModule} from "@angular/platform-browser";
import {ValidatorService} from "../../../../../app/shared/Validator.provider";
import {PullTranslationService} from "./translate.service";
import {DynamicCustomInputComponent} from "./dynamic-form/dynamic-custom-input.component";
import {UploaderModule} from "src/app/uploader/src/lib/uploader.module";
import {SharedModule} from "../../../../../app/shared/shared.module";

@NgModule({
  imports: [
    NgxSpinnerModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserModule,
    UploaderModule,
    SharedModule
  ],
  declarations: [
    DynamicFormComponent,
    DynamicInputComponent,
    DynamicCustomInputComponent
  ],
  exports: [
    DynamicFormComponent,
    DynamicInputComponent,
    DynamicCustomInputComponent
  ],
  providers: [
    ReactiveFormsModule,
    FormsModule,
    ValidatorService,
    PullTranslationService
  ],
  entryComponents: []
})
export class LibSharedModule {
}
