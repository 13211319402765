import {Component, EventEmitter, Input, Output, ViewEncapsulation} from "@angular/core";
import {HiveUtil} from "../../../hive/hive.util";
import {HiveService} from "../../../hive/hive.service";
import {HiveApiService} from "../../../hive/hive-api.service";
import { config } from "@app/config";
import Cookies from "js-cookie";
import { AppRoutePaths } from "@core/constants/route.path";
import { checkUserLoggedIn } from "src/app/utils/backend-jwt";

export interface IMenuListItem {
  title: string;
  groupTitle?: string;
  subheader?: boolean;
  isOwn?: boolean;
  url?: string;
  submenus?: IMenuListItem[];
  mobileIndex?: number;
}


@Component({
  selector: "app-header-footer-layout",
  templateUrl: "./header-footer-layout.component.html",
  styleUrls: ["./header-footer-layout.component.scss"],
  encapsulation: ViewEncapsulation.None
})

export class HeaderFooterLayoutComponent {
  @Input() myHiveMenu;
  @Input() hiveName = "";
  @Input() displayHeader = true;
  @Input() show_sidebar = true;
  @Input() floating_sidebar = false;
  @Input() floating_sidebar_items = [];
  @Input() show_menubar = true;
  @Input() centre = false;
  @Output() onSelect = new EventEmitter();
  @Input() blank = false;
  @Input() btn_visible = "sign_up";
  isUserLoggedIn = false;

  // header menu list
  headerMenuList: IMenuListItem[] = [
    {
      "title": "my hive",
      "url": "/users/my-hive",
      mobileIndex: 5},
    {
      "title": "new listing",
      "url": "/items",
      mobileIndex: 4},
    {
      "title": "borrow something",
      "url": "/find-something",
      mobileIndex: 3
    },
    {
      "title": "ask the community",
      "url": "/request/community",
      mobileIndex: 2
    },
  ];

  constructor(private hiveService: HiveApiService) {
    if (checkUserLoggedIn()) {
      this.isUserLoggedIn = true;
    }

    if (this.isUserLoggedIn) {
      this.hiveService.getHives()
        .subscribe((hives: any[]) => {
          const list = hives.map(hive => HiveUtil.hiveItemToListItem(hive));
          const myHives = list.filter(item => item.isOwn);
          const joinedHives = list.filter(item => !item.isOwn);

          // TODO -- Review if this is useful
          if (myHives.length === 0 && joinedHives.length === 0) {
            this.headerMenuList[0].title = "hives";
          }

        }, (error) => {
          console.error(error);
        });
    }
  }

  sideMenuList: IMenuListItem[] = [
    {
      title: "my account",
      groupTitle: "my sharebee",
      url: AppRoutePaths.MY_PROFILE,
      mobileIndex: 1,
      submenus: [
        {
          title: "account verification",
          url: "/users/account-verification"
        },
      ]
    }
  ];

}


