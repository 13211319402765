<alert-banner></alert-banner>
<app-header-footer-layout [show_sidebar]="false" [blank]="true">
  <div class="wrapper">
    <!-- menu -->
    <div class="page-container">
      <nav class="navbar navbar-expand-lg navbar-nav-thin d-none d-md-block">
        <div class="container-fluid justify-content-center">
          <menu data-testid="menu-categories" class="navbar-nav mx-auto mb-2 mb-lg-0 d-md-none d-xs-none d-lg-flex ">
            <li class="nav-item">
              <a class="nav-link text-dark text-underline-hover" aria-current="page" href="/find-something?category=household">Household</a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-dark text-underline-hover" aria-current="page" href="/find-something?category=sports%20%26%20recreation">Sports & Recreation </a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-dark text-underline-hover" aria-current="page" href="/find-something?category=tools">Tools</a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-dark text-underline-hover" aria-current="page" href="/find-something?category=transport">Transport</a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-dark text-underline-hover" aria-current="page" href="/find-something?category=space">Space</a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-dark text-underline-hover" aria-current="page" href="/find-something?category=children%27s%20toys">Children, toys & Equipment</a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-dark text-underline-hover" aria-current="page" href="/find-something?category=clothing">Clothing</a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-dark text-underline-hover" aria-current="page" href="/find-something?category=technology">Technology</a>
            </li>
          </menu>
        </div>
      </nav>
      <app-search-area></app-search-area>
      <!-- <div class="homepage-inner-wrapper">
        <app-sip></app-sip>
      </div> -->

      <!-- <div class="homepage-inner-wrapper">
        <app-recently-shared
          (navigateToSignUp)="navigateToSignUp()"
          [recently_shared_items]="sharedItems"
        ></app-recently-shared>
      </div> -->

      <section class="reasons--container">
        <div class="feel-good">
          <div class="align_center mr-80-20">
            <div class="mr-50">
              <app-shr-home-title>
                {{ "HOME_PAGE.REASONS_FEEL_GOOD" | translate }}
              </app-shr-home-title>
            </div>
            <div class="values">
              <div class="first">
                <div class="header">C02 emissions avoided</div>
                <div class="impact">
                  1,667kg
                </div>
                <label class="description"
                  >The amount of CO2 emissions avoided because people
                  borrowed rather than bought</label
                >
              </div>
              <div class="second">
                <div class="header">money saved by borrowers</div>
                <div class="impact">
                  £46,575
                </div>
                <label class="description"
                  >The amount of money people have saved so far by borrowing
                  what they need</label
                >
              </div>
              <div class="second">
                <div class="header">money made by top sharers</div>
                <div class="impact">
                  £1,390
                </div>
                <label class="description"
                  >The amount of money people have made so far by sharing
                  what they have</label
                >
              </div>
            </div>
          </div>
        </div>
      </section>

      <div class="homepage-inner-wrapper homepage-inner-wrapper--about">
        <app-about-sharebee></app-about-sharebee>
      </div>

      <app-how-it-works></app-how-it-works>

      <app-share-members-say
        [title]="membersSay"
        [description]="memberDesc"
      ></app-share-members-say>

      <div
        class="homepage-inner-wrapper homepage-inner-wrapper--colony add-pd-20"
      >
        <app-community></app-community>
      </div>

      <div
        class="homepage-inner-wrapper homepage-inner-wrapper--about add-pd-btm-20"
      >
        <app-my-hive></app-my-hive>
      </div>

      <div class="add-pd-20" *ngIf="false">
        <app-share-members-say
          [title]="organization"
        ></app-share-members-say>
      </div>
    </div>
  </div>
</app-header-footer-layout>
