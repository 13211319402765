<section class="padding-box-30">
  <section class="left-content">
    <a href="/">
      <img  alt="logo" class="left-content_logo" [src]="imageService.HEADER_LOGO">
    </a>
  </section>
  <div class="align_center">
    <span class="heading_txt">KPI</span>
  </div>

  <div class="kpi-option-csv-container">

    <div class="csv-wrapper">
      <i class="fa fa-download download-icon" (click)="downloadCSV()">
      </i>
    </div>

    <div class="option-wrapper">
      <label for="static-page-template" class="why-lbl">Select KPI period:</label>
      <select id="static-page-template" class="form-control font_nu"
              (change)="kpiSelected($event)">
        <option *ngFor="let cms of KpiList" class="font_nu">{{cms.name}}</option>
      </select>
    </div>

  </div>

  <div class="table-wrapper">
    <table id="dtHorizontalExample" class="table table-striped table-bordered table-sm" cellspacing="0"
           width="100%">
      <thead>
      <tr>
        <th scope="col" *ngFor="let column of header;let i = index;" class="border">
          <div *ngIf="i !== 0">
            <span *ngIf="selectedKPI === 'month' || selectedKPI === ''">{{column | date:'MMM - yy'}}</span>
            <span *ngIf="selectedKPI !== 'month' && selectedKPI !== ''">{{column | date:'dd/MM/yy'}}</span>
          </div>
          <span *ngIf="i === 0 || i === 1">{{column}}</span>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let row of RowsData">
        <td *ngFor="let column of header;let i = index;" class="border" [ngClass]="{'align-right': i !==0}">
          {{row[column] === undefined ? 0 : row[column]}}
        </td>
      </tr>
      </tbody>
    </table>
  </div>

</section>
