<app-header-footer-layout [show_sidebar]='true'>
  <div class='my-activity-details-page'>
    <p class='my-activity_title'>
      {{ item_name }}
    </p>

    <section class='my-activity_content'>

      <div class='activity-label-container'>
        <span class='activity-label_title' *ngFor = 'let label of labels'>
          {{ label }}
        </span>
      </div>

      <div class='activity_details-container' *ngFor = 'let item of items'>
        <span class='activity_details_title'>
          {{ item.renter }}
        </span>

        <span class='activity_details_title'>
          {{ item.start_date | TimePipe }}
        </span>

        <span class='activity_details_title'>
          {{ item.end_date | TimePipe }}
        </span>

        <span class='activity_details_title'>
          <select class='activity_details_status' (change)='changeStatus(item, $event.target.value)' [(ngModel)]='item.current_status'>

            <optgroup *ngFor='let category of statusCategories' [label]='category.label'>
              <option *ngFor='let option of category.options'
                      [disabled]="!isStatusAllowedToChange(item, option.key)"
                      [value]='option.key'>{{option.label}}</option>
            </optgroup>
          </select>
        </span>
      </div>

    </section>

  </div>

  <app-confirmation-popup
    *ngIf='statusUpdate'
    (confirm)='statusUpdate = !statusUpdate'>
    Status has been updated successfully
  </app-confirmation-popup>
</app-header-footer-layout>
