import {RouterModule, Routes} from "@angular/router";
import {NgModule} from "@angular/core";
// import {HomeComponent} from './home/home.component';
import {PasswordRecoveryComponent} from "./user/password-recovery/password-recovery.component";
import {userRoutes} from "./user-auth/user.routes";
import {AccessGuard} from "./user-auth/shared/access.guard";
import {ItemDetailsComponent} from "./items/item-details/item-details.component";
import {MyStuffComponent} from "./items/my-stuff/my-stuff.component";
import {MyProfileComponent} from "./user/my-profile/my-profile.component";
import {FindSomethingComponent} from "./items/search-filter/find-something/find-something.component";
import {MyActivityDetailsComponent} from "./my-activity/my-activity-details.component";
import {NotFoundComponent} from "./shared/error/404/not-found.component";
import {AccountVerificationComponent} from "./user/account-verification/account-verification.component";
import {HomePageComponent} from "./home/home-page/home-page.component";
import {RequestCommunityComponent} from "./request/request-community/request-community.component";
import {AboutSharebeeDetailsComponent} from "./home/home-page/about-sharebee-details/about-sharebee-details.component";
import {ContactUsComponent} from "./home/contact-us/contact-us.component";
import {TransactionHistoryComponent} from "./user/transaction-history/transaction-history.component";
import {FaqComponent} from "./home/faq/faq.component";
import {RequestItemComponent} from "./items/request-item/request-item.component";
import {MailSubscriptionComponent} from "./home/mail-subscription/mail-subscription.component";
import {TermsRulesCondition} from "./shared/terms-condition/terms-rules-condition";
import {AppRoutePaths} from "@core/constants/route.path";
// import {RegisterComponent} from './home/register/register.component';
import {InviteCodesComponent} from "./home/invite-codes/invite-codes.component";
import {MyHivesComponent} from "./hive/my-hives/my-hives.component";
import {CreateHiveComponent} from "./hive/create-hive/create-hive.component";
import {HiveItemListComponent} from "./hive/hive-item-list/hive-item-list.component";
import {ViewMembersComponent} from "./hive/view-members/view-members.component";
import {HiveMyItemsComponent} from "./hive/hive-my-items/hive-my-items.component";
import {MyActivityContainerComponent} from "./my-activity/my-activity-container";
import {HiveActivityContainerComponent} from "./hive/hive-list/hive-activity";
// import {TestComponent} from './test/test.component';
import {AuthLoginComponent} from "./auth/login/login.component";
import {AuthSignUpComponent} from "./auth/sign-up/sign-up.component";
import {AuthForgotPasswordComponent} from "./auth/forgot-password/forgot-password.component";
import {AuthPasswordRecoveryComponent} from "./auth/forgot-password/password-recovery.component";
import {ResetPasswordComponent} from "./user-auth/reset-password/reset-password.component";
import {AuthResetPasswordComponent} from "./auth/forgot-password/reset-password.component";
import {KpiComponent} from "./admin/kpi/kpi.component";

export const routes: Routes = [
  // TODO: Replace the path name with appropriate name
  {
    path: AppRoutePaths.HOME_GUEST,
    redirectTo: "/",
    pathMatch: "full"
  },
  {
    path: "login",
    pathMatch: "full",
    component: AuthLoginComponent,
    data: {
      title: "login"
    }
  },
  {
    path: "register",
    pathMatch: "full",
    component: AuthSignUpComponent,
    data: {
      title: "sign-up"
    }
  },
  {
    path: "users/auth/forgot-password",
    pathMatch: "full",
    component: AuthForgotPasswordComponent,
    data: {
      title: "forgot-password"
    }
  },
  {
    path: "users/auth/reset-password",
    data: {
      mode: ResetPasswordComponent.RESET_PASSWORD_MODE,
      title: "reset-password"
    },
    component: AuthResetPasswordComponent,
    pathMatch: "full"
  },
  {
    path: "",
    component: HomePageComponent,
    pathMatch: "full",
    data: {
      title: "home-page"
    }
  },

  {
    path: AppRoutePaths.INVITE_CODES,
    component: InviteCodesComponent,
    // canActivate: [AccessGuard],
    pathMatch: "full"
  },

  {
    path: "users/auth/password-recovery-notification",
    component: AuthPasswordRecoveryComponent,
    pathMatch: "full",
    data: {
      title: "password-recovery-screen"
    }
  },

  // { path: 'user/items', component: MyStuffComponent, canActivate: [AccessGuard], pathMatch: 'full' },
  {
    path: "items",
    component: MyStuffComponent,
    // canActivate: [AccessGuard],
    pathMatch: "full",
    data: {
      title: "User Items"
    }
  },

  // { path: 'users/item/list', component: ItemsComponent, canActivate: [AccessGuard], pathMatch: 'full' },
  {
    path: "hive/create",
    component: CreateHiveComponent,
    // canActivate: [AccessGuard],
    pathMatch: "full",
    data: {
      title: "create-hive"
    }
  },
  {
    path: "users/item/details",
    component: ItemDetailsComponent,
    // canActivate: [AccessGuard],
    pathMatch: "full",
    data: {
      title: "item-detail"
    }
  },
  {
    path: "find-something",
    component: FindSomethingComponent,
    pathMatch: "full",
    data: {
      title: "find-something"
    }
  },
  {
    path: "users/account-verification",
    component: AccountVerificationComponent,
    canActivate: [AccessGuard],
    pathMatch: "full",
    data: {
      title: "account-verification"
    }
  },
  {
    path: "about-sharebee",
    component: AboutSharebeeDetailsComponent,
    pathMatch: "full",
    data: {
      title: "about-sharebee"
    }
  },
  {
    path: "users/my-hive",
    component: MyHivesComponent,
    // canActivate: [AccessGuard],
    pathMatch: "full",
    data: {
      title: "my-hive"
    }
  },
  {
    path: "users/my-hive/:id/items",
    component: HiveItemListComponent,
    canActivate: [AccessGuard],
    pathMatch: "full",
    data: {
      title: "hive-items"
    }
  },
  {
    path: "users/my-hive/:id/manage-items",
    component: HiveMyItemsComponent,
    canActivate: [AccessGuard],
    pathMatch: "full",
    data: {
      title: "manage-hive-items"
    }
  },
  {
    path: "users/my-hive/:id/members"
    , component: ViewMembersComponent,
    canActivate: [AccessGuard],
    pathMatch: "full",
    data: {
      title: "hive-members"
    }
  },
  {
    path: AppRoutePaths.MY_PROFILE,
    component: MyProfileComponent,
    canActivate: [AccessGuard],
    pathMatch: "full",
    data: {
      title: "profile"
    }
  },
  {
    path: "my-activity",
    component: MyActivityContainerComponent,
    pathMatch: "full",
    data: {
      title: "my-activity"
    }
  },
  {
    path: "admin/kpi",
    component: KpiComponent,
    canActivate: [AccessGuard],
    pathMatch: "full",
    data: {
      title: "kpi"
    }
  },
  {
    path: "users/:id/hive-activity",
    canActivate: [AccessGuard],
    component: HiveActivityContainerComponent,
    pathMatch: "full",
    data: {
      title: "hive-activity"
    }
  },
  {
    path: "request-item",
    component: RequestItemComponent,
    pathMatch: "full",
    data: {
      title: "request-item"
    }
  },
  {
    path: "my-activity/item-details",
    component: MyActivityDetailsComponent,
    pathMatch: "full",
    data: {
      title: "my-activity-item-details"
    }
  },
  {
    path: "request/community",
    component: RequestCommunityComponent,
    // canActivate: [AccessGuard],
    pathMatch: "full",
    data: {
      title: "request-community"
    }
  },

  {
    path: AppRoutePaths.CONTACT_US,
    component: ContactUsComponent,
    pathMatch: "full",
    data: {
      title: "contact-us"
    }
  },
  {
    path: "transaction-history",
    component: TransactionHistoryComponent,
    pathMatch: "full",
    data: {
      title: "transaction-history"
    }
  },
  {
    path: "term_rules_condition",
    component: TermsRulesCondition,
    pathMatch: "full",
    data: {
      title: "tn-c"
    }
  },
  {
    path: AppRoutePaths.FAQ,
    component: FaqComponent,
    pathMatch: "full",
    data: {
      title: "faq"
    }
  },
  {
    path: "mailSubscription",
    component: MailSubscriptionComponent,
    pathMatch: "full",
    data: {
      title: "email-subscription"
    }
  },


  ...userRoutes,
  {path: "**", component: NotFoundComponent, pathMatch: "full"},
  {
    path: "",
    redirectTo: "/",
    pathMatch: "full"
  },

];


@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      anchorScrolling: "enabled",
      scrollPositionRestoration: "enabled"
    }),
  ],
  exports: [RouterModule],

})
export class AppRoutingModule {
}
