<app-hive-container [showOwnerDetails]="true">
  <app-hive-header [border]="false"></app-hive-header>
  <div class="list-container">

    <div class="create-item__tile hive_create_item show--cursor" (click)="uploadItemToHive()">
      <div class="create-item__icon">
      </div>
      <div class="create-item_info">
        <p>{{"MY_ACTIVITY_PAGE.UPLOAD_SOMETHING" | translate}}</p>
      </div>
    </div>

    <ng-container *ngFor="let item of items | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalCount}; let i = index;">
      <app-shr-item-card class="item-card show--cursor"
                          [title]="item.title"
                          (onClick)="navigateTo(item)"
                          [img_path]="item.images[0] || ''"
                          [item_rate]="item?.rent_rate"
                          [item_location]="item.location"
      ></app-shr-item-card>
    </ng-container>
  </div>
  <div class="add__pd top sp30 text-align-center">
    <pagination-controls class="my-pagination"
                          (pageChange)="onChangePage(currentPage = $event)"></pagination-controls>
  </div>
</app-hive-container>
<app-create-item *ngIf="createItem" [isPrivate]="true" (createItemFlag)="createItem = false"
                  (onSuccess)="itemCreated($event)">
</app-create-item>

<popup-layout *ngIf="guestUser">
  <section class="padding-box">
    <div class="mt-2">
      <label>Please login or sign up to Sharebee to enroll to the hive</label>
    </div>
    <div class="button-container ms-fix-container align_center margin--bottom">
      <bee-btn type="primary" (click)="loginUser()">Login</bee-btn>
      <!--          <button class="button" (click)="loginUser()">Login</button>-->
      <!--          <button class="button active" (click)="signUpUser()">Sign up</button>-->
      <bee-btn type="primary" (click)="signUpUser()">Sign up</bee-btn>
    </div>
    <p class="close-button" (click)="cancelGuestUser()">close</p>
    <!--<div class="mt-2">
    </div>-->
  </section>
</popup-layout>

<popup-layout *ngIf="updateProfile">
  <div class="item-booking__popup hive_item">
    <label class="label md1 bold-font popup-title">Complete Your Profile</label>
    <div class="sm-1 make-it-black mt-1 align_center">
      Complete your profile before you add your item or request to borrow something,
    </div>
    <div class="label sm-1 make-it-black mt-1 align_center">
      this is important to create trust in our community
    </div>
    <div class="popup-actions">
      <bee-btn type="secondary" [size]="'medium'" [block]="true" (click)="updateProfile = false">Later</bee-btn>
      <bee-btn type="primary" [size]="'medium'" [block]="true" (click)="completeProfile()">Setup Profile</bee-btn>
    </div>
    <p class="close-button no_margin" (click)="updateProfile = !updateProfile">close</p>
  </div>
</popup-layout>

<popup-layout *ngIf="uploadItem">
  <div class="item-booking__popup hive_item">
    <label class="label md1 bold-font popup-title">Upload Item to hive</label>
    <div class="popup-actions">
      <!--          <button (click)="createNewItem()" class="button">New Item</button>-->
      <!--          <button (click)="existingItem()" class="button active padding_lr_10">Existing Item</button>-->
      <bee-btn type="secondary" [size]="'medium'" [block]="true" (click)="createNewItem()">New Item</bee-btn>
      <bee-btn type="primary" [size]="'medium'" [block]="true" (click)="existingItem()">Existing Item</bee-btn>
    </div>
    <p class="close-button no_margin" (click)="uploadItem = !uploadItem">close</p>
  </div>
</popup-layout>

<popup-layout *ngIf="setupStripePopUp">
  <div class="my-stuff__popup align_center">
    <label class="label md1 bold-font popup-title align_center">Stripe Setup</label>
    <div class="label sm-1 make-it-black mt-1 align_center">
      To receive payments for your item you will need to set up stripe.
    </div>
    <div class="label sm-1 make-it-black mt-1 align_center">
      Setting this up will take 5 minutes.
    </div>
    <div class="my-stuff__actions mt-1">
      <!--<button (click)="createItemFlow()" class="button">Setup later</button>
      <button (click)="navigateToSetupAccount()" class="button active">Setup now</button>-->
      <bee-btn type="secondary" [size]="'medium'" [block]="true" (click)="createItemFlow()">Setup later</bee-btn>
      <bee-btn type="primary" [size]="'medium'" [block]="true" (click)="navigateToSetupAccount()">Setup now
      </bee-btn>
    </div>
  </div>
</popup-layout>

<popup-layout *ngIf="isApproved">
  <div class="hive_pending__popup align_center">
    <div class="label sm-1 make-it-black mt-1 align_center">
      Please give us a moment, we’re just waiting for <span
      class="primary_text">{{hiveDetails?.owner_details?.name}}</span> to accept your membership
    </div>
    <div class="my-stuff__actions mt-1">
      <!--          <button (click)="cancelIsApproved()" class="button active">Okay</button>-->
      <bee-btn type="primary" (click)="cancelIsApproved()">Okay</bee-btn>
    </div>
  </div>
</popup-layout>

<popup-layout *ngIf="isGuestUser">
  <div class="hive_pending__popup align_center">
    <div class="label sm-1 make-it-black mt-1 align_center">
      <!--          Please give us a moment, we’re just waiting for {{hiveDetails?.owner_details?.name}} to accept your membership-->
      Welcome to <span class="primary_text">{{hiveDetails?.owner_details?.name}}'s {{hiveDetails?.name}}. </span>
      You can borrow things that are listed and add things you are happy to share privately with members of this
      hive.
    </div>
    <div class="my-stuff__actions mt-1">
      <!--          <button (click)="cancelIsGuestUser()" class="button active">Ok</button>-->
      <bee-btn type="primary" (click)="cancelIsGuestUser()">Okay</bee-btn>
    </div>
  </div>
</popup-layout>

<popup-layout *ngIf="isHiveEnroller">
  <div class="hive_pending__popup align_center">
    <div class="label sm-1 make-it-black mt-1 align_center">
      <!--          Please give us a moment, we’re just waiting for {{hiveDetails?.owner_details?.name}} to accept your membership-->
      Welcome to <span class="primary_text">{{hiveDetails?.owner_details?.name}}'s {{hiveDetails?.name}}. </span>
      You can borrow things that are listed and add things you are happy to share privately with members of this
      hive.
    </div>
    <div class="button-container align_center ms-fix-container align_center">
      <!--          <button (click)="enrollToHive()" class="button">Join</button>-->
      <!--          <button (click)="cancelIsHiveEnroller()" class="button active">Cancel</button>-->
      <bee-btn type="secondary" (click)="enrollToHive()">Join</bee-btn>
      <bee-btn type="primary" (click)="cancelIsHiveEnroller()">Cancel</bee-btn>
    </div>
  </div>
</popup-layout>
