import {Component, OnInit, ViewEncapsulation} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {DomainResource} from "../../user-auth/configs/config.helper";
import {Role} from "../../user-auth/shared/user.services";
import {ActivatedRoute, Router} from "@angular/router";
import {IMenuListItem} from "../../shared/page-layout/header-footer-sidemenu-layout/header-footer-layout.component";
import {HiveApiService} from "../hive-api.service";
import {HiveUtil} from "../hive.util";
import {switchMap} from "rxjs/operators";
import {of} from "rxjs";
import { Title } from "@angular/platform-browser";
import { checkUserLoggedIn } from "src/app/utils/backend-jwt";

@DomainResource({
  name: "MY_HIVES",
  access: [Role.ADMIN, Role.USER]
})
@Component({
  selector: "app-my-hive",
  templateUrl: "./my-hives.component.html",
  styleUrls: ["./my-hives.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class MyHivesComponent implements OnInit {

  myHives = [];
  joinedHives = [];
  isManageEnabled = false;
  isUserLoggedIn = false;

  get hiveListTitle() {
    return `My Hives (${this.myHives.length})`;
  }

  get memberListTitle() {
    return `My Memberships (${this.joinedHives.length})`;
  }

  constructor(public translate: TranslateService,
    protected router: Router,
    protected route: ActivatedRoute,
    private title: Title,
    private api: HiveApiService) {
    // this.title.setTitle("My Hives | Sharebee");
    this.isUserLoggedIn = checkUserLoggedIn();
  }

  create() {
    this.router.navigate(["/hive/create"]);
  }

  manage() {
    this.isManageEnabled = !this.isManageEnabled;
  }

  ngOnInit(): void {
    if (this.isUserLoggedIn) {
      this.loadData();
      this.enrollHive();
    }
  }

  loadData(): void {
    this.api.getHives()
      .subscribe((hives: any[]) => {
        const list = hives.map(hive => HiveUtil.hiveItemToListItem(hive));
        this.myHives = list.filter(item => item.isOwn);
        this.joinedHives = list.filter(item => !item.isOwn);
      }, console.warn);
  }

  enrollHive() {
    this.route.queryParamMap
      .pipe(
        switchMap((params) => {
          const enroll = params.get("enroll")
            , inviteId = params.get("invitation")
            , hiveId = params.get("hive");
          console.warn(enroll, inviteId, hiveId, "Invite");
          if (!(enroll && inviteId && hiveId)) {
            return of();
          }
          return this.api.enrollInvitation(hiveId, inviteId);
        })
      ).subscribe(console.warn);
  }

  view(hive) {
    this.router.navigate([`/users/my-hive/${hive._id}/items`]);
  }

  remove(hive) {
    this.api.removeHive(hive._id)
      .subscribe(() => this.loadData());

  }

  members(hive) {
    this.router.navigate([`/users/my-hive/${hive._id}/members`], {queryParams: {invite: true}});
  }

  leave(hive) {
    this.route.paramMap
      .pipe(
        switchMap((params) => {
          return this.api.leaveHive(hive._id);
        })
      ).subscribe(({data}) => {
        this.loadData();
      });
  }
}
