//noinspection TypeScriptValidateTypes
import {Routes} from "@angular/router";
import {ResetPasswordComponent} from "./reset-password/reset-password.component";
import {AccessGuard} from "./shared/access.guard";
import {InviteComponent} from "./invite/invite.component";
import {ProfileComponent} from "./profile/profile.component";
import {ActivatedComponent} from "./activated/activated.component";
import {PullTranslationService} from "../usermodule/src/lib/shared/translate.service";

// NOTE: Never ever change the exiting router path configuration
export const userRoutes: Routes = [
  {
    path: "users",
    canActivateChild: [AccessGuard],
    children: [
      {
        path: "auth/activate",
        data: { mode: ResetPasswordComponent.ACTIVATION_MODE },
        component: ResetPasswordComponent,
        pathMatch: "full",
        resolve: {
          translate: PullTranslationService
        }
      },
      {
        path: "auth/invite", component: InviteComponent, pathMatch: "full", resolve: {
          translate: PullTranslationService
        }
      },
      {
        path: "auth/account-activated",
        component: ActivatedComponent,
        pathMatch: "full"
      },
      {
        path: "profile", component: ProfileComponent, pathMatch: "full", resolve: {
          translate: PullTranslationService
        }
      },
    ]
  }
];






