
<div [class.floating-sidebar]="floating_sidebar"
    [ngClass]="{'header-footer-layout-container': !blank}">
  <app-floating-sidebar *ngIf="floating_sidebar" (onSelect)="onSelect.emit($event)" [hiveName]="hiveName"
                        [floating_sidebar_items]="floating_sidebar_items"
                        [myHiveMenu]="myHiveMenu">
  </app-floating-sidebar>
  <div [ngClass]="{'header-footer-layout-component': !blank}">
    <app-header *ngIf="displayHeader" [headerMenuList]="headerMenuList" [sideMenuList]="sideMenuList"
                [floatingSideBarList]="floating_sidebar_items" [btn_visible]="btn_visible"></app-header>
    <div class="layout-container">
      <app-side-menu *ngIf="!floating_sidebar && show_sidebar && isUserLoggedIn"
                    [sideMenuList]="sideMenuList"></app-side-menu>
      <ng-content class="side-menu-component" select="[custom_sidebar]"></ng-content>
      <div [ngClass]="{'logged_in_user': !isUserLoggedIn}">
      </div>
      <div class="page-content" [ngClass]="{'page-content--centre': centre}">
        <ng-content></ng-content>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</div>
