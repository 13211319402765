import {Injectable} from "@angular/core";
import { AccessControl } from "accesscontrol";
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router} from "@angular/router";
import {RbacService} from "./rbac/rbac.service";
import {ResourcesService} from "../configs/resources.config";
import {AuthConfig} from "./config.service";
import {Role} from "../../user-auth/shared/user.services";
import { config } from "@app/config";
import Cookies from "js-cookie";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { CustomJTWPayload } from "src/app/utils/backend-jwt";

@Injectable()
export class AccessGuard implements CanActivateChild, CanActivate {
  access_control: AccessControl;

  constructor(private rbac: RbacService, private resources: ResourcesService,
              private router: Router, private config: AuthConfig) {}

  /**
   * Guard for child
   *
   * param route
   * param state
   */
  canActivateChild(route: ActivatedRouteSnapshot): Promise<boolean> {
    const resourceName = this.resources.getResourceName(route.component);
    return Promise
      .resolve(this.rbac.canAccess(resourceName))
      .then(({ granted }) => {
        this.rbac.getUserRole()
          .then((role) => {
            if(!granted){
              // Determines where to redirect user after denial
              let redirection = this.config.guest_access_denied_redirection;
              switch (role) {
              case Role.USER:
                redirection = this.config.user_access_denied_redirection;
                break;
              case Role.ADMIN:
                redirection = this.config.admin_access_denied_redirection;
                break;
              }
              this.router.navigate([redirection]);
            } else {
              if (role !== Role.GUEST){
                const userData = jwtDecode<CustomJTWPayload>(Cookies.get(config.AUTH_COOKIE_KEY));

                if (!userData.profileUpdated) {
                  // TODO -- Bring the below back as we want the user to finish onboarding

                  // const redirection = this.config.post_signin_redirection;
                  // this.router.navigate([redirection]);
                }
              }
            }
          });

        return granted;
      });
  }

  /**
   *
   * param route
   * param state
   */
  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    return this.canActivateChild(route);
  }
}
