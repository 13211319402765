import { Injectable } from "@angular/core";
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { CanActivate } from "@angular/router";
import { GlobalEventsManager } from "./GlobalEventsManager.provider";
import { AppRoutePaths } from "@core/constants/route.path";
import { checkUserLoggedIn } from "src/app/utils/backend-jwt";

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private _router: Router,
    private _globalEventManager: GlobalEventsManager) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const {url} = state;
    const isLoggedIn = checkUserLoggedIn();

    if (url === AppRoutePaths.HOME_GUEST) {
      return !isLoggedIn;
    }

    if (!isLoggedIn) {
      this._router.navigate([AppRoutePaths.HOME_GUEST]);
    }

    return isLoggedIn;
  }
}
