import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {HiveApiService} from "../hive-api.service";
import { Router} from "@angular/router";
import {config} from "src/config";
import {AppRoutePaths} from "@core/constants/route.path";
import Cookies from "js-cookie";
import { checkUserLoggedIn, parseJWTCookie } from "src/app/utils/backend-jwt";

@Component({
  selector: "app-hive-invite",
  templateUrl: "./hive-invite.component.html",
  styleUrls: ["./hive-invite.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class HiveInviteComponent implements OnChanges, OnInit {

  @Input() hiveId: any;
  inviteByEmail = false;
  localUrl = process.env.URL + "/users/my-hive/";
  //  localUrl = 'http://localhost:4200/users/my-hive/';
  createItem = false;
  setupStripePopUp = false;
  private isUserLoggedIn = false;
  url = config.base_url;
  hiveDetails: any;
  isMobile = false;
  inviteDescription = "";
  description = "";
  guestUser = false;
  loggedInUser: any;
  isApproved = "";
  isApprovedPopUp = false;

  constructor(public translate: TranslateService,
              private api: HiveApiService,
              protected router: Router) {
  }

  ngOnInit() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.isMobile = true;
    }

    if (checkUserLoggedIn()) {
      this.loggedInUser = parseJWTCookie();
      this.isUserLoggedIn = true;
    }

    if (this.hiveId !== undefined) {
      this.api.getHive(this.hiveId).subscribe((data) => {
        this.hiveDetails = data.data;
        console.warn(this.hiveDetails);
        const existing: any = this.hiveDetails.bees.find((i: any) => i.user_id === this.loggedInUser.userid || i.email === this.loggedInUser.email);
        this.description = `Hi, ${this.hiveDetails.owner_details.name} is inviting you to join their private ${this.hiveDetails.name}, sharing hive powered by sharebee. Please click the link to check it out.`;
        if (existing && existing.status === "enrolled") {
          this.isApproved = "enrolled";
        } else if (existing && existing.status === "accepted") {
          this.isApproved = "accepted";
        }
        // this.inviteDescription = `Hi, <owner_name> is inviting you to join their private <hive_name> sharing hive powered by sharebee. Please click the link to check it out.`;
      });
    }
  }


  invite(email) {
    console.warn(email);
    this.api.inviteMember(this.hiveId, email)
      .subscribe((e) => {
        console.warn(e);
        this.inviteByEmail = false;
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.localUrl = this.localUrl + this.hiveId + "/items";
    // this.url = this.url + this.hiveId + '/items';
    // console.warn(this.url);
  }

  createNewItem() {
    if (this.isUserLoggedIn) {
      if (this.isApproved === "accepted" || this.hiveDetails.isOwn) {
        this.createItem = !this.createItem;
      } else {
        this.isApprovedPopUp = !this.isApprovedPopUp;
      }
    } else {
      this.guestUser = !this.guestUser;
    }
  }

  addExistingItem() {
    if (this.isUserLoggedIn) {
      if (this.isApproved === "accepted" || this.hiveDetails.isOwn) {
        this.router.navigate([`/users/my-hive/${this.hiveId}/manage-items`]);
      } else {
        this.isApprovedPopUp = !this.isApprovedPopUp;
      }
    } else {
      this.guestUser = !this.guestUser;
    }
  }

  itemCreated(itemObject: any) {
    this.api.addItemsIntoHive(this.hiveId, {
      item_id: itemObject._id,
      prices: {
        rate_per_day: itemObject.rent_rate,
        rate_per_week: itemObject.rent_rate_per_week,
        rate_per_month: itemObject.rent_rate_per_month
      }
    }).subscribe((result) => {
      this.router.navigate([`/users/my-hive/${this.hiveId}/items`]);
    });
  }

  loginUser() {
    this.router.navigate([AppRoutePaths.LOGIN_USER], {
      queryParams: {
        hiveId: this.hiveId
      }
    });
  }

  signUpUser() {
    this.router.navigate([AppRoutePaths.HOME_GUEST_REGISTER], {
      queryParams: {
        hiveId: this.hiveId
      }
    });
  }

  cancelGuestUser() {
    this.guestUser = !this.guestUser;
  }

  cancelIsApproved() {
    this.isApprovedPopUp = !this.isApprovedPopUp;
  }

}
