<app-header-footer-layout [show_sidebar]="false" [centre]="true">
  <div class="app-invite-codes">
    <h3>Existing Codes</h3>
    <ul>
      <li *ngFor="let inviteCode of inviteCodes | async"><span>{{inviteCode.code}}</span> <button class="button button--small" (click)="deleteCode(inviteCode)">Delete</button></li>
    </ul>
    <h3>Add New Code</h3>
    <app-dynamic-form [dataObject]="registerFormBuilder"
        (formSubmit)="addCode($event)"
        id="ngForm" #dynamic_form>
    </app-dynamic-form>
    <div class="register__button-group">
      <button class="button active" (click)="error=false; dynamic_form.onSubmit()">Add code</button>
    </div>
  </div>
</app-header-footer-layout>
