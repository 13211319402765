import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { ImagePathService } from "../../../shared/constants/image-path.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-about-sharebee",
  templateUrl: "./about-sharebee.component.html",
  styleUrls: ["./about-sharebee.component.scss"],
})
export class AboutSharebeeComponent {
  constructor(
    private _router: Router,
    public imageService: ImagePathService,
    public translate: TranslateService
  ) {}

  goToLink(url) {
    window.open(url, "_blank");
  }

  knowMore() {
    this._router.navigate(["/about-sharebee"]);
  }
}
