import { Component, EventEmitter, OnInit, Output, ViewChild} from "@angular/core";
import * as moment from "moment";

@Component({
  selector: "app-choose-duration",
  templateUrl: "./choose-duration.component.html",
  styleUrls: ["choose-duration.component.scss"]
})

export class ChooseDurationComponent implements OnInit {
  @ViewChild("dateRangePicker", { static: true}) dateRangePicker;
  @Output() dateRangeSelected = new EventEmitter<any>();
  @Output() goBack = new EventEmitter<boolean>();
  dateSelected: {start: Date, end: Date};
  dateSelected1: any;
  public selectedMoment = new Date();

  constructor() {
    console.warn("dateRangePicker", this.dateRangePicker);
  }

  ngOnInit() {
  }

  choosedDate(date: any) {
    console.warn(date);
  }


  rangeSelected() {
    console.warn("date range", this.selectedMoment);
    const startDate = (moment(this.selectedMoment[0], "MM/DD/YYYY")).format("DD/MM/YYYY");
    const endDate = (moment(this.selectedMoment[1], "MM/DD/YYYY")).format("DD/MM/YYYY");

    this.dateRangeSelected.emit({
      startDate: startDate,
      endDate: endDate
    });
  }

  cancel() {
    this.goBack.emit(true);
  }

}
