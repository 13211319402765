<section class="app-my-hive">
  <div class="hive_wrapper">
  <article class="hive_image image-info-wrapper__image">
    <img [src]="imageService.HOMEPAGE_HIVE">
  </article>

    <article class="hive_info">
      <app-shr-home-title>
        {{ 'HOME_PAGE.HIVE_TITLE_1' | translate}}
      </app-shr-home-title>
      <div class="hive_info__text">
        <p>{{ 'HOME_PAGE.HIVE_DESC_1' | translate}}</p>
        <h3>{{ 'HOME_PAGE.HIVE_TITLE_2' | translate}}</h3>
        <p>{{ 'HOME_PAGE.HIVE_DESC_2' | translate}}</p>
        <h3>{{ 'HOME_PAGE.HIVE_TITLE_3' | translate}}</h3>
        <p>{{ 'HOME_PAGE.HIVE_DESC_3' | translate}}</p>
        <h3>{{ 'HOME_PAGE.HIVE_TITLE_4' | translate}}</h3>
        <p>{{ 'HOME_PAGE.HIVE_DESC_4' | translate}}</p>
      </div>
      <bee-btn type="primary" (click)="goToHive()">{{ 'HOME_PAGE.VISIT_HIVE_BTN' | translate}}</bee-btn>
<!--          <button class="button active" (click)="goToHive()">{{ 'HOME_PAGE.VISIT_HIVE_BTN' | translate}}</button>-->

    </article>
  </div>

  <popup-layout *ngIf="showHiveLogin">
    <section class="padding-box">
      <div class="mt-2">
        <label>Please login or sign up to Sharebee to enroll to the hive</label>
      </div>
      <div class="button-container ms-fix-container align_center margin--bottom">
        <bee-btn type="secondary" (click)="loginUser()">Login</bee-btn>
        <bee-btn type="primary" (click)="signUpUser()">Sign up</bee-btn>
      </div>
      <p class="close-button" (click)="showHiveLogin = false">close</p>
    </section>
  </popup-layout>
</section>
