
import Cookies from "js-cookie";
import { config } from "@app/config";
import jwtDecode, { JwtPayload} from "jwt-decode";

const getIDToken = () => {
  const loggedInUser = Cookies.get(config.AUTH_COOKIE_KEY);
  return jwtDecode<JwtPayload>(JSON.parse(loggedInUser), {header: false});
};

export { getIDToken };
