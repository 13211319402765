import {Component} from "@angular/core";
import {ImagePathService} from "../constants/image-path.service";
import {Router} from "@angular/router";
import {AppRoutePaths} from "@core/constants/route.path";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: [
    "footer.component.scss"
  ]
})

export class FooterComponent {
  displayTermsCondition: boolean;
  displayPrivacyPolicy: boolean;
  constructor(private _router: Router,
              public imageService: ImagePathService) {
  }
}
