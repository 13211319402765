import {Component, OnInit} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {DomainResource} from "../../user-auth/configs/config.helper";
import {Role} from "../../user-auth/shared/user.services";
import {ActivatedRoute, Router} from "@angular/router";
import {HiveApiService} from "../hive-api.service";
import {switchMap} from "rxjs/operators";
import {HiveUtil} from "../hive.util";

@DomainResource({
  name: "HIVE_VIEW_MEMBERS",
  access: [Role.ADMIN, Role.USER, Role.GUEST]
})
@Component({
  selector: "app-view-members",
  template: `
    <app-hive-container>
      <section class="mt-2">
        <app-create-hive-input *ngIf="hive" [hive]="hive" [isReadOnly]="hive?.isOwn" [buttonTitle]="'Update'"
                               (hiveContent)="updateHive($event)">
        </app-create-hive-input>

        <div>
          <app-hive-invite [hiveId]="hiveId"></app-hive-invite>
        </div>

        <div *ngIf="hive?.isOwn">
          <app-hive-list
            [title]="pendingBeesTitle"
            emptyMessage="No pending bees"
            [items]="pending"
            itemPrimaryActionLabel="accept"
            (itemPrimaryAction)="accept($event)"
            itemSecondaryActionLabel="delete"
            (itemSecondaryAction)="delete($event)">
          </app-hive-list>
        </div>

      </section>

      <app-hive-list
        *ngIf="hive"
        [title]="beesInHiveTitle"
        [items]="bees"
        [isOwner]="hive?.isOwn"
        emptyMessage="you're the first to join"
        itemPrimaryActionLabel="chat"
        itemSecondaryActionLabel="remove"
        (itemSecondaryAction)="delete($event)">
      </app-hive-list>
    </app-hive-container>

  `,
  styleUrls: ["./view-members.component.scss"]
})
export class ViewMembersComponent implements OnInit {
  pending = [];
  bees = [];
  hive: any = {};
  hiveName = "";
  hiveImage: string;
  hiveDescription: string;
  // nameChange = new Subject();
  // hiveImageChanged = new Subject();
  hiveId: any;

  get pendingBeesTitle() {
    return `pending members (${this.pending.length})`;
  }

  get beesInHiveTitle() {
    return `members in hive (${this.bees.length})`;
  }

  constructor(public translate: TranslateService,
              protected router: Router,
              protected route: ActivatedRoute,
              private api: HiveApiService) {
    this.route.paramMap.subscribe(params => {
      this.hiveId = params.get("id");
    });
  }

  create() {
    this.router.navigate(["/hive/create"]);
  }

  ngOnInit(): void {
    this.loadData();
  }


  updateHive(hiveObject: { name: string, hiveImagePath: string, description: string }) {
    this.hiveName = hiveObject.name;
    this.hiveImage = hiveObject.hiveImagePath;
    this.hiveDescription = hiveObject.description;
    /*if (this.hiveName === '' || this.hiveName === undefined) {
      this.hiveName = this.hive.name;
    }
    if (this.hiveImage === '' || this.hiveImage === undefined) {
      this.hiveImage = this.hive.hiveImagePath;
    }*/
    return this.api.updateHive({
      _id: this.hive._id,
      name: this.hiveName,
      hiveImagePath: this.hiveImage,
      description: this.hiveDescription
    }).subscribe((data) => {
      this.loadData();
    });
  }

  loadData(): void {
    console.warn({hiveId: this.hiveId});
    this.api.getHive(this.hiveId).subscribe((hiveData) => {
      // console.warn(hiveData.data);
      this.hive = hiveData.data;
      this.api.getHiveMembers(this.hive._id).subscribe((dataObj) => {
        let data = dataObj.data;
        console.warn(data);
        data = data.map((u) => HiveUtil.userToListItem(u));
        this.bees = data.filter(b => b.status === "accepted");
        console.warn(this.bees.length);
        this.pending = data.filter(b => b.status === "enrolled");
      }, (error) => {
        console.error(error);
      });
    }, (error) => {
      console.error(error);
    });

    /* switchMap(({data, ...rest}) => {
       this.hive = data;
       console.warn(this.hive);
       return this.api.getHiveMembers(data._id);
     })
   ).subscribe(({data}) => {
   data = data.map((u) => HiveUtil.userToListItem(u));
   this.bees = data.filter(b => b.status === 'accepted');
   this.pending = data.filter(b => b.status === 'enrolled');
 });*/
  }

  accept(user) {
    this.route.paramMap
      .pipe(
        switchMap((params) => {
          return this.api.acceptMemberIntoHive(this.hive._id, user.user_id);
        })
      ).subscribe(({data}) => {
        this.loadData();
      });
  }

  delete(user) {
    this.route.paramMap
      .pipe(
        switchMap((params) => {
          return this.api.removeMemberFromHive(this.hive._id, user.user_id);
        })
      ).subscribe(({data}) => {
        this.loadData();
      });
  }
}
