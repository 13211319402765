import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {AppHttpService} from "@core/http.service";
import {EndpointsService} from "../shared/constants/endpoints.service";
import {ItemObject} from "../shared/models/item.model";
import qs from "qs";

@Injectable()

export class ItemApiService extends AppHttpService {
  constructor(private endpoints: EndpointsService, public http: HttpClient) {
    super(http);
  }

  /**
   * Create Item
   * param item<Object>
   * return Observable<Object>
   */
  public createItem(item: ItemObject, request_item_id: string): Observable<any> {
    return this.post(this.endpoints.CREATE_ITEM, {
      data: {
        item: item,
        request_item_id: request_item_id
      }
    });
  }

  /**
   * Get category
   * return Observable<Object>
   */
  public getCategory(): Observable<any> {
    return this.get(this.endpoints.GET_CATEGORY);
  }

  /**
   * Get Items
   * return Observable<Object>
   */
  public getItems(params: {[key: string]: unknown} = {}, userExists: boolean): Observable<any> {
    const queryString = qs.stringify({page_number: 1, page_size: 0, ...params});
    const loggedInURL = `${this.endpoints.SEARCH_ITEM}/?${queryString}`;
    const guestUserURL = `${this.endpoints.GUEST_USER_SEARCH_ITEM}/?${queryString}`;
    const URL = userExists === true ? loggedInURL : guestUserURL;
    return this.get(URL);
  }

  /**
   * Get My Items
   * return Observable<Object>
   */
  public getMyItems(params: {[key: string]: unknown} = {}): Observable<any> {
    const queryString = qs.stringify({page_number: 1, page_size: 0, ...params});
    const URL = `${this.endpoints.GET_ITEMS_UPLOADED_BY_ME}/?${queryString}`;
    return this.get(URL);
  }

  /**
   * Search Item
   * return Observable<Object>
   **/
  public findItems(): Observable<Object> {
    return this.get(this.endpoints.SEARCH_ITEM);
  }

  /**
   * Get category
   * return Observable<Object>
   */
  public getItemDetails({
    item_id,
    hive_id
  }: { item_id: string, hive_id: string }, isUserLoggedIn?: boolean): Observable<any> {
    const url = isUserLoggedIn ? `${this.endpoints.CREATE_ITEM}/${item_id}?hive=${hive_id}` : `${this.endpoints.GUEST_USER_SEARCH_ITEM}/${item_id}?hive=${hive_id}`;
    return this.get(url);
  }

  /**
   * Get category
   * return Observable<Object>
   */
  public unpublishItem({item_id}: { item_id: string }): Observable<any> {
    return this.get(`${this.endpoints.DEACTIVATE_ITEM}/${item_id}`);
  }


  /**
   * Book a item
   */
  public bookItem(date_range: object) {
    return this.post(this.endpoints.ITEM_BOOK, {data: date_range});
  }


  /**
   * Loads borrowed and landed items
   */
  public getMyActivity({hiveId}: { hiveId: string }) {
    if (hiveId === undefined) {
      return this.get(`${this.endpoints.GET_MY_ACTIVATES}`);
    } else {
      return this.get(`${this.endpoints.GET_MY_ACTIVATES}/${hiveId}`);
    }
  }

  /**
   * Loads details of an item
   */
  public getItemActivityDetails({id}) {
    return this.get(`${this.endpoints.GET_MY_ACTIVITY_DETAILS}/${id}`);
  }


  /**
   * Change booked item status
   */
  public changeItemStatus({order_id, status}) {

    return this.post(`${this.endpoints.UPDATE_ORDER_STATUS}`, {
      data: {
        order_id,
        updateField: {
          current_status: status
        }
      }
    });
  }

  /**
   * setup stripe
   */
  public setupStripe(packageBody: Object) {
    // window.location = this.endpoints.SETUP_STRIPE as any;
    return this.post(this.endpoints.SAVE_STRIPE_DETAILS, packageBody);
  }

  /**
   * Make payment
   */
  public makePayment(packageBody: Object) {
    // window.location = this.endpoints.SETUP_STRIPE as any;
    return this.post(this.endpoints.MAKE_PAYMENT, packageBody);
  }

  /**
   * update Order Status
   */
  public updateOrderStatus(status: string, orderId: string) {
    // window.location = this.endpoints.SETUP_STRIPE as any;
    return this.get(this.endpoints.UPDATE_PAYMENT_STATUS + status + "/" + orderId);
  }

  /**
   * Create Session for checkout
   */
  public sessionCheckout(packageBody: Object) {
    // window.location = this.endpoints.SETUP_STRIPE as any;
    return this.post(this.endpoints.STRIPE_CHECKOUT, packageBody);
  }

  /**
   * Delete an item
   */
  public deleteItem(item_id) {
    const url = this.endpoints.DELETE_ITEM as any;
    return this.get(`${url}/${item_id}`);
  }

  /**
   * Delete request
   */
  public deleteRequest(item_id) {
    const url = this.endpoints.DELETE_REQUEST as any;
    return this.get(`${url}/${item_id}`);
  }

  /**
   * Get transaction history
   */
  public getTransactionHistory() {
    const url = this.endpoints.GET_TRANSACTION_HISTORY;
    return this.get(`${url}`);
  }

  /**
   * Edit an item
   */
  public editItem(item_id: string, data: any) {
    const url = this.endpoints.EDIT_ITEM as any;
    return this.post(`${url}/${item_id}`, {data});
  }

  /**
   * Submit contact us form
   */

  public submitContactUsForm(data) {
    const url = this.endpoints.CONTACT_US_FORM_SUBMIT as any;
    return this.post(`${url}`, {data});
  }

  /**
   * Get category
   * return Observable<Object>
   */
  public createRequest(requestItem: any): Observable<any> {
    return this.post(this.endpoints.CREATE_REQUEST, {data: requestItem});
  }

  /**
   * Get items upload by me
   */
  public getItemsUploadedByMe(pageNo: number, count: number) {
    const url = `${this.endpoints.GET_ITEMS_UPLOADED_BY_ME}/?${qs.stringify({page_number: pageNo, page_size: count})}`;
    return this.get(`${url}`);
  }

  /**
   * Account verification image update
   */
  public imageUpdateAccountVerification(imagePath: string) {
    const url: string = this.endpoints.ACCOUNT_VERIFICATION;
    return this.post(`${url}`, {imagePath});
  }

  /**
   * Get account details
   */
  public getAccountDetails() {
    const url: string = this.endpoints.ACCOUNT_VERIFICATION;
    return this.get(`${url}`);
  }
}
