import {Component, EventEmitter, Input, Output, ViewEncapsulation} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {ImagePathService} from "../constants/image-path.service";
import {Router} from "@angular/router";

export interface FloatingSidebarOptions {
  title: string;
  submenus: {
    title: string,
    subheader?: boolean,
    url?: string
  }[];
}

@Component({
  selector: "app-floating-sidebar",
  template: "./floating-sidebar.component.html",
  styleUrls: ["./floating-sidebar.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class FloatingSidebarComponent {

  @Input() floating_sidebar_items: FloatingSidebarOptions[];
  @Output() onSelect = new EventEmitter();
  @Input() hiveName;
  @Input() myHiveMenu;
  hideList = ["manage hive", "my stuff", "hive activity", "Hive settings"];

  constructor(public translate: TranslateService, public imageService: ImagePathService, private router: Router) {
  }

  go(item) {
    console.warn(item);
    this.hiveName = item.title;
    this.onSelect.emit(item);
    this.router.navigate([item.url]);
  }
}
