import {Component} from "@angular/core";
import {Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";

@Component({
  selector: "app-auth-user-password-recovery",
  templateUrl: "./password-recovery.component.html",
  styleUrls: ["./../auth-common.component.scss"]
})
export class AuthPasswordRecoveryComponent {
  constructor(public router: Router,
              private spinner: NgxSpinnerService) {
  }

  navigateToHome() {
    this.router.navigate(["/"]);
  }
}
