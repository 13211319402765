<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="medium" color="#fff" type="ball-pulse"></ngx-spinner>

<section class="mail-subscription-page full-page">
  <div class="transparent-layer">

    <section class="app-white-box-with-logo-layout-wrapper" *ngIf="!subscribeEmailPopUp">
      <div class="inner-container">
        <app-white-box-with-logo-layout [boxTitle]="'about us'">
          <section class="padding-wrapper">
            <iframe width="100%" height="275px" src="https://player.vimeo.com/video/321457641" frameborder="0" allow="accelerometer;" allowfullscreen></iframe>

            <article>
              <p class="t_r_c">
                Will you help us build a future with more sharing? More borrowing when we don’t need to buy. More lending what we already have. Less defaulting to Amazon. Less needless pollution. Less waste.
                <br/><span>That’s what sharebee is all about.</span>
              </p>
              <p class="t_r_c">
                Sharebee makes sharing easy - connecting people who want to lend with people who want to borrow. It’s useful and it’s good.
              </p>

              <p class="t_r_c">
                <span class="text_orange">Useful for people</span> <span> – who can make money by lending and save money by borrowing.</span>
              </p>
              <p class="t_r_c">
                <span class="text_green">Good for the planet </span>
                <span>- because the more we share, the less we need to make.</span>
              </p>
              <p class="t_r_c">We’d love you to be a part of our sharing adventure.</p>
            </article>

            <article class="link-wrapper">
              <a class="subscribe-link" (click)="goToSignUp()">
                sign me up
              </a>
            </article>


            <footer>
              <div>
                <bee-btn type="primary" (click)="goBackToLandingPage()">{{ 'Back' | translate}}</bee-btn>
              </div>
            </footer>
          </section>

        </app-white-box-with-logo-layout>
      </div>
    </section>
  </div>
</section>

<popup-layout *ngIf="subscribeEmailPopUp">
  <div class="email-subscription-popup">
    <label class="label md1 bold-font popup-title">newsletter</label>

    <section>
      <app-dynamic-form [dataObject]="subscriptionFormBuilder"
                        (formSubmit)="subscribe($event)"
                        id="ngForm" #dynamic_form></app-dynamic-form>

      <div class="button-wrapper ms-fix-container">
        <bee-btn type="secondary" (click)="cancel()">cancel</bee-btn>
        <bee-btn type="primary" (click)="dynamic_form.onSubmit()">Subscribe</bee-btn>
      </div>
    </section>

  </div>
</popup-layout>

<popup-layout *ngIf="successfulEmailSubscription" >
  <section class="pop-up">
    <label class="label md1 bold-font popup-title">Email Subscription</label>
    <p>
      We will use your email address to send you updates about Sharebee
    </p>
    <div class="button-wrapper">
      <bee-btn type="primary" (click)="closeSuccessPopUp()">Close</bee-btn>
    </div>
  </section>

</popup-layout>
