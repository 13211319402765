import { Component } from "@angular/core";
import { ItemApiService } from "../../items/item-api.service";
import { TimePipe } from "../../shared/pipes/time.pipe";
import {NgxSpinnerService} from "ngx-spinner";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";

import {config} from "src/config";

@Component({
  selector: "app-transaction-history",
  templateUrl: "./transaction-history.component.html",
  styleUrls: ["./transaction-history.component.scss"]
})

export class TransactionHistoryComponent {
  labels = [ "item", "payment date", "name", "amount" ];
  showBottomBorrow = true;
  historyData: any;
  currentData = [];

  interestDeduction: number;

  constructor( public apiService: ItemApiService,
               private spinner: NgxSpinnerService,
               private toastrService: ToastrService,
               private translateService: TranslateService) {
    this.getHistoryTransactions();
    this.interestDeduction = config.shareBeeInterest;
  }

  /**
   * Switch borrowed / lent history
   */
  switchTransactionsHistory() {
    this.showBottomBorrow = !this.showBottomBorrow;
    this.getHistoryTransactions();
  }

  /**
   * Get transactions history from api
   */
  getHistoryTransactions() {
    this.spinner.show();
    this.apiService.getTransactionHistory()
      .subscribe((data) => {
        this.spinner.hide();
        this.historyData = data.data;

        if (this.showBottomBorrow) {
          this.currentData = this.historyData.borrowed_history;
        } else {
          this.currentData = this.historyData.lent_history;
        }
      },
      ({error}) => {
        this.spinner.hide();
        this.toastrService.error(error, "Error");
      });
  }
}
