<div class="uploader-container">
  <div class="image--uploader__container" *ngIf="image_crop; else preview">
    <!-- TODO: Crop Image here -->
    <lib-file-cropper [configs]="image_crop"></lib-file-cropper>
  </div>
  <ng-template #preview>
    <div *ngIf="!disabled">
      <div class="profile-view-container" *ngIf="selected_file || img_path; else select_placeholder" >
        <lib-file-viewer *ngIf="selected_file" class="file-viewer" [file]="selected_file"></lib-file-viewer>
        <img *ngIf="img_path && !selected_file" [src]="img_path"  class="image-viewer" (click)="change(input)" />
      </div>

      <ng-template #select_placeholder>
        <ng-content></ng-content>
      </ng-template>

      <ng-template #tipContent>Change image</ng-template>

      <input class="uploader-input" type="file" #input (change)="onFile($event)" accept="image/*"/>

      <div class="custom-tooltip" (click)="change(input)">Upload image</div>

      <div *ngIf="allow_retry && upload_failed" class="retry-overlay">
        <button (click)="upload()">Retry</button>
        <button (click)="change(input)">Change</button>
      </div>
    </div>

    <div class="profile-view-container" *ngIf="disabled">
      <img class="image_file" [src]="img_path">
    </div>
  </ng-template>
</div>
