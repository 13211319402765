import {Component, Input, OnInit, ViewEncapsulation} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {Role, UserServices} from "../../user-auth/shared/user.services";
import {ImagePathService} from "../constants/image-path.service";
import {ApiService} from "../../user-auth/shared/api.service";
import {config} from "src/config";
import {AppRoutePaths} from "@core/constants/route.path";
import * as R from "ramda";
import {IMenuListItem} from "../page-layout/header-footer-sidemenu-layout/header-footer-layout.component";
import {FloatingSidebarOptions} from "../floating-sidebar/floating-sidebar.component";
import {HiveUtil} from "../../hive/hive.util";
import {HiveApiService} from "../../hive/hive-api.service";
import {HiveService} from "../../hive/hive.service";
import {StateService} from "../../service/state.service";
import { checkUserLoggedIn } from "src/app/utils/backend-jwt";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: [
    "header.component.scss"
  ],
  encapsulation: ViewEncapsulation.None
})

export class HeaderComponent implements OnInit {
  selectedMenu = "";
  openOrClose: boolean;
  user_role: Role;
  toggle = {};
  isUserLoggedIn = false;

  // header menu list
  @Input() headerMenuList: IMenuListItem[];
  @Input() btn_visible = "sign_up";

  @Input() floatingSideBarList: FloatingSidebarOptions[];
  guestUserHeaderMenuList: IMenuListItem[] = [
    {"title": "new listing", "url": "/items", mobileIndex: 3},
    {"title": "borrow something", "url": "/find-something", mobileIndex: 1},
    {"title": "ask the community", "url": "/request/community", mobileIndex: 2},
  ];


  // side menu list
  @Input() sideMenuList: IMenuListItem[];
  myHives = [];
  joinedHives = [];

  disableHeader = true;

  adminList = ["Download Users", "Download Profile"];

  // TODO -- Turn this into 🍦 JS
  get allItems(): IMenuListItem[] {
    const list: IMenuListItem[] = R.pipe(
      R.concat(this.headerMenuList),
      R.sortBy(R.prop("mobileIndex")),
      R.filter((i: IMenuListItem) => {
        return !i.mobileIndex || i.mobileIndex > 0;
      })
    )(this.sideMenuList);

    if (this.floatingSideBarList[0]) {
      const tempList = [...this.floatingSideBarList[0].submenus, ...this.floatingSideBarList[1].submenus];
      const myHiveList = [{
        "title": "hive menu",
        "mobileIndex": 10,
        "submenus": tempList
      }];
      const newList: IMenuListItem[] = list.filter((item) => item.title !== "my hive");
      return newList.concat(myHiveList);
    } else {
      return list;
    }
  }

  constructor(public router: Router,
              private user: UserServices,
              private hiveService: HiveService,
              private api: ApiService,
              private stateService: StateService,
              private route: ActivatedRoute,
              private hiveApi: HiveApiService,
              public imageService: ImagePathService) {
    setTimeout(() => {
      this.isUserLoggedIn = checkUserLoggedIn();
    }, 100);
  }


  ngOnInit() {

    this.selectedMenu = this.stateService.getSubMenu();
    this.user.getUser()
      .then((user) => {
        this.user_role = user.role;
      });

    if (this.isUserLoggedIn) {
      this.hiveApi.getHives()
        .subscribe((hives: any[]) => {
          const list = hives.map(hive => HiveUtil.hiveItemToListItem(hive));
          this.myHives = list.filter(item => item.isOwn);
          this.joinedHives = list.filter(item => !item.isOwn);
        });
    }
  }

  /*ngDoCheck() {
    this.user.getUser()
      .then((user) => {
        this.user_role = user.role;
        this.disableHeader = user['profileUpdated'];
      });
  }*/

  toggleSmallScreenMenuList(menu?: any) {
    this.stateService.setSubMenu(menu);
    this.openOrClose = !this.openOrClose;
  }

  closeSmallScreenMenuList() {
    this.openOrClose = false;
  }

  logout() {
    this.user.logout()
      .then(() => {
        this.router.navigate(["/"]);
      })
      .catch((error) => console.error(error));
  }

  downloadUsers() {
    this.api.downloadUserCSV()
      .toPromise()
      .then((data: any) => {
        this.downloadFile(data.data.download_link);
      })
      .catch(({error}) => {
        console.error("error", error);
      });
  }

  downloadProfiles() {
    this.api.downloadProfileCSV()
      .toPromise()
      .then((data: any) => {
        this.downloadFile(data.data.download_link);
      })
      .catch(({error}) => {
        console.error("error", error);
      });
  }

  downloadItems() {
    this.api.downloadItemsCSV()
      .toPromise()
      .then((data: any) => {
        this.downloadFile(data.data.download_link);
      })
      .catch(({error}) => {
        console.error("error", error);
      });
  }

  downloadRequest() {
    this.api.downloadRequestCSV()
      .toPromise()
      .then((data: any) => {
        this.downloadFile(data.data.download_link);
      })
      .catch(({error}) => {
        console.error("error", error);
      });
  }

  downloadOrders() {
    this.api.downloadOrdersCSV()
      .toPromise()
      .then((data: any) => {
        this.downloadFile(data.data.download_link);
      })
      .catch(({error}) => {
        console.error("error", error);
      });
  }

  downloadHive() {
    this.api.downloadHiveCSV()
      .toPromise()
      .then((data: any) => {
        this.downloadFile(data.data.download_link);
      })
      .catch(({error}) => {
        console.error("error", error);
      });
  }

  downloadFile(data: Response) {
    window.location.href = config.api_host + data;
  }

  goToInviteCodes() {
    return this.router.navigate([AppRoutePaths.INVITE_CODES]);
  }

  goToKPI() {
    return this.router.navigate([AppRoutePaths.KPI]);
  }

  login() {
    this.router.navigate([AppRoutePaths.LOGIN_USER]);
  }

  signUp() {
    this.router.navigate([AppRoutePaths.HOME_GUEST_REGISTER]);
  }

}


