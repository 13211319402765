<app-hive-container [sidebar]="false">
  <section class="create-hive-container">
    <app-create-hive-input [hive]="hive" (hiveContent)="create($event)">
    </app-create-hive-input>
    <popup-layout *ngIf="showHiveLogin">
      <section class="padding-box">
        <div class="mt-2">
          <label>Please login or sign up to Sharebee to enroll to the hive</label>
        </div>
        <div class="button-container ms-fix-container align_center margin--bottom">
          <bee-btn type="secondary" (click)="loginUser()">Login</bee-btn>
          <bee-btn type="primary" (click)="signUpUser()">Sign up</bee-btn>
        </div>
        <p class="close-button" (click)="showHiveLogin = false">close</p>
      </section>
    </popup-layout>
  </section>
</app-hive-container>
