<app-header-footer-layout [show_sidebar]="false" [blank]="true" [btn_visible]="'sign_in'">
  <section class="app-auth-user-login">
    <div class="login-container">
      <div class="input-container reset-margin-1">
        <div class="mt-5">
          <p>Already have an account? <a class="bee-btn bee-btn__tertiary" data-testid="login-link" href="/login">LOGIN</a></p>
        </div>
        <h1>sign up</h1>
        <div class="login-capsule mt-2">
        <form data-testid="signup-form" class="w-100" (change)="onFormChange()">
          <!-- <label class="input--label mt-2">Post Code</label> -->
          <!--  <input type="text" [(ngModel)]="postCode" autocomplete="off" class="input--field"> -->

          <label class="input--label">First name</label>
          <input data-testid="firstname" class="form-control" placeholder="Buzz" type="text" autocomplete="off" class="input--field" [(ngModel)]="name">

          <label class="input--label">Last name</label>
          <input data-testid="lastname" class="form-control" placeholder="Lightyear " autocomplete="off" type="text" class="input--field" [(ngModel)]="lastName">

          <label class="input--label">Email</label>
          <input  data-testid="email" class="form-control" type="text" placeholder="buzz@lightyear.com" autocomplete="off" class="input--field" [(ngModel)]="email">

          <label class="input--label mt-5" type="button" data-bs-toggle="tooltip" data-bs-html="true" title="<span>Minimum eight characters with at least:</span> <ul><li>one uppercase letter</li><li>one lowercase letter</li><li> one number</li></ul>">
           Password
          </label>

          <input data-bs-toggle="tooltip" data-testid="password" pattern="{{ emailRegex }}" autocomplete="new-password" placeholder="e.g., ***********" type="password" [(ngModel)]="password" class="input--field">

          <label class="input--label mt-2">Confirm Password</label>
          <input data-testid="password-confirm" autocomplete="new-password" class="form-control" placeholder="" type="password" [(ngModel)]="confirmPassword" class="input--field">

          <div class="mt-2">
            <input data-testid="tnc" checked type="checkbox" class="form-check-input" [(ngModel)]="tnc" id="terms"><label
            class="add-pd-lt forgot-pwd-txt no_line" for="terms">I agree to Sharebee’s <span
            class="show--cursor forgot-pwd-txt txt-prm"
            (click)="termsCondition()">terms and conditions</span></label>
          </div>

          <div class="mt-2">
            <input data-testid="newsletter" type="checkbox" [(ngModel)]="newsletter" class="form-check-input" id="newsletter" checked><label
            class=" add-pd-lt forgot-pwd-txt no_line" for="newsletter">Sign me up for the Newsletter</label>
          </div>

          <div class="btn-container mt-5 justify-content-center">
            <bee-btn
            testId="button-submit"
            [disabled]="!submitEnabled"
            type="primary" (click)="submitForm()">sign up</bee-btn>
          </div>
        </form>
        </div>
        </div>
        <div class="img--container">
          <img alt="login_placeholder_image" class="img-preview" src="../../../assets/svg/login.svg">
        </div>
    </div>
  </section>
</app-header-footer-layout>


<popup-layout *ngIf="displayTermsCondition">
  <app-terms-rules-condition #app_terms_condition>
    <div class="register__button-group mt-2">
      <bee-btn [block]="true" type="primary" (click)="termsCondition()">Back</bee-btn>
    </div>
  </app-terms-rules-condition>
</popup-layout>
<!--    </div>-->
