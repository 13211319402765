<div [ngSwitch]="true" [formGroup]="form" class="input-wrapper"
      [ngClass]="constructClasses()">
  <!-- text or password or email or number-->

  <input *ngSwitchCase="prop.type === 'text' || prop.type === 'password' || prop.type === 'email' || prop.type === 'number' || prop.type === 'date'"
          [formControlName]="prop.key"
          [id]="prop.key"
          [type]="prop.type"
          [pattern]="prop.pattern?.value"
          [placeholder]="prop.placeholder"
          class="input-field rippler-input"
          (focus)="onFocus()"
          (focusout)="outOfFocus()">

  <textarea *ngSwitchCase="prop.type === 'textarea'"
          [formControlName]="prop.key"
          [id]="prop.key"
          [placeholder]="prop.placeholder"
          class="input-field rippler-input textarea-field"
          (focus)="onFocus()" (focusout)="outOfFocus()"
          [rows]="prop.rows"
          [cols]="prop.cols"></textarea>

  <!-- radio -->
  <div *ngSwitchCase="prop.type === 'radio'">
    <div class="radio-label" *ngFor="let option of prop.options">
      <input
        type="radio"
        name="{{prop.key}}"
        [formControlName]="prop.key"
        [value]="option.value">
      <span> {{ option.label }}</span>
    </div>
  </div>

  <!-- boolean -->
  <div *ngSwitchCase="prop.type === 'boolean'" class="content_check_box">
    <input class="check_box"
      type="checkbox"
      [name]="prop.key"
      [id]="prop.key"
      [formControlName]="prop.key"
      [value]="prop.value">
    <label class="label no_padding_btm" [for]="prop.key">
      {{ prop.label }}
    </label>
  </div>

  <!-- Select dropdown -->
  <div *ngSwitchCase="prop.type === 'file'">
    <file-uploader
      [upload_url]="prop.endpoint"
      [file_formats]="['jpg','jpeg','JPEG','JPG','png','PNG']"
      [file_type]="['video', 'image']"
      [allow_retry]="true"
      [max_file_size]="1e+9"
      [disabled]="prop.disabled"
      [img_path]="prop.value"
      [crop_disable]="true"
      (fileUploadTrigger)="imageUploading($event)"
      [(isFileUploaded)]="prop.isFileUploaded"
      (onUploadDone)="onFileUploadDone(prop, $event)"
      (onValidationError)="safeCall(prop.onFileUploadError, $event)">
      <div class="uploader-title">
        Drag and drop or select
      </div>
    </file-uploader>
  </div>

  <!-- Select dropdown -->
  <div *ngSwitchCase="prop.type === 'select'">
    <select [formControlName]="prop.key">
      <option *ngFor="let option of prop.options" [value]="option.value">
        {{option.label}}
      </option>
    </select>
  </div>

  <!-- terms and conditions -->
  <div *ngSwitchCase="prop.key === 'tnc'" class="content_check_box">
      <input type="checkbox"
              class="check_box"
              [name]="prop.key"
              [id]="prop.key"
              [formControlName]="prop.key"
              [value]="prop.value">
      <label class="label no_padding_btm">{{prop.label}} &nbsp;<span class="tnc_link" (click)="safeCall(prop.click, $event)">terms and condition</span></label>
  </div>


  <!-- text -->
  <div *ngSwitchCase="prop.key === 'text'" class="text">
    <div class="text_content">
      <p>
        {{prop.label}}
      </p>
    </div>
  </div>

  <div *ngSwitchCase="prop.type === 'button'">
<!--        <button class="bee-btn bee-btn__primary add_width" (click)="safeCall(prop.click, $event)">{{prop.value}}</button>-->
    <bee-btn type="primary" (click)="safeCall(prop.click, $event)">{{prop.value}}</bee-btn>
  </div>

  <div *ngSwitchCase="prop.type === 'anchor'">
    <a (click)="safeCall(prop.click, $event)">{{prop.label}}</a>
  </div>

  <label *ngIf="prop.key !== 'tnc'&& prop.key !== 'text' && prop.type !== 'boolean'"
          class="label"
          [attr.for]="prop"
          [for]="prop.key"
          [ngClass]="{'valid': form.controls[prop.key].valid,
          'invalid': form.controls[prop.key].invalid && form.controls[prop.key].touched,'focus': focus}">
    {{prop.label}}<span *ngIf="prop.requiredFlag">*</span>
    <img *ngIf="form.controls[prop.key].valid"
          class="icon" src="../../../assets/icons/verified.png"/>
    <img *ngIf="form.controls[prop.key].touched && form.controls[prop.key].invalid"
          class="icon" src="../../../assets/icons/exclamation-mark.png"/>
  </label>

</div>

<span class="form_error-message"
      *ngIf="!isValid  && form.controls[prop.key].touched">
        {{ errorMessage }}
</span>

<span class="form_info-message">
  {{ prop?.infoMsg }}
</span>
