import {Component, OnInit, ViewChild} from "@angular/core";
import {config} from "src/config";
import {ItemListComponent} from "../../shared/item-list/item-list.component";
import {TranslateService} from "@ngx-translate/core";
import {EndpointsService} from "../../shared/constants/endpoints.service";
import {ToastrService} from "ngx-toastr";
import {ItemApiService} from "../item-api.service";
import {ApiService} from "../../../app/user-auth/shared/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ImagePathService} from "../../shared/constants/image-path.service";
import {NgxSpinnerService} from "ngx-spinner";
import {UserServices} from "../../../app/user-auth/shared/user.services";
import {FormattedUserLocation} from "../../helpers/UserDataHelper";
import { AppRoutePaths } from "@core/constants/route.path";

@Component({
  selector: "app-request-item",
  templateUrl: "./request-item.component.html",
  styleUrls: ["./request-item.component.scss"]
})
export class RequestItemComponent implements OnInit {
  @ViewChild("itemList", { static: true}) item_list;
  item_deleted = false;
  delete_item: any;
  itemObject = {
    title: "",
    user: {},
    description: "",
    images: [],
    location: ""
  };
  createNewRequestItem = false;
  requestItemPreview = false;
  updateProfile: boolean;
  requestCreated = false;
  api_host = config.api_host + "/request/list/own";
  uploadImage = ""; // 'http://localhost:8080/items/image/upload';
  isFileUploaded?: boolean;
  dummyImage = "https://development.sharebee.de/public/uploads/image/1546839063069.jpeg";

  constructor(private toastrService: ToastrService,
              public apiService: ItemApiService,
              public endpoints: EndpointsService,
              public translate: TranslateService,
              public userService: UserServices,
              private itemApi: ItemApiService,
              public router: Router,
              public api: ApiService,
              private http: ApiService,
              private spinner: NgxSpinnerService,
              public imageService: ImagePathService,
              private activated_router: ActivatedRoute) {
    this.uploadImage = this.endpoints.IMAGE_UPLOAD;

    this.activated_router.queryParams.subscribe(params => {
      this.createNewRequestItem = params["createNewRequestItem"];
    });
  }

  ngOnInit() {
    // this.load();
  }

  createRequestItem() {
    this.userService.getUser()
      .then((user) => {
        if (user["profileUpdated"]) {
          this.itemObject = {
            title: "",
            user: {},
            description: "",
            images: [],
            location: ""
          };
          this.api.getProfileDetails()
            .toPromise()
            .then(({data}) => {
              console.warn(data);
              this.itemObject.location = FormattedUserLocation(data);
            });
          this.createNewRequestItem = !this.createNewRequestItem;
        } else {
          this.updateProfile = true;
        }
      });
  }

  /**
   * Configure delete action for the card
   * @param item
   * @param list_ref
   */
  deleteAction(item, list_ref: ItemListComponent) {
    return {
      title: "delete",
      click: () => {
        this.delete_item = item;
      }
    };
  }

  showItemDetails(item: any) {
    this.itemObject = item;
    this.requestItemPreview = !this.requestItemPreview;
  }


  /**
   * Once file is uploaded save the path of image to itemObject array
   * @param1 model
   * @param2 pos
   */
  onFileUploadDone(model, pos) {
    model.value = model.data.file.file.path;
    this.itemObject.images[pos] = model.data.file.file.path;
  }

  safeCall(fn: any, event: any): void {
    this.toastrService.error(arguments[0].message, "Error");
  }

  requestItem() {
    if (this.itemObject.title.length === 0) {
      this.toastrService.error("item name cannot be empty", "Error");
    } else if (this.itemObject.description.length === 0) {
      this.toastrService.error("description cannot be empty", "Error");
    } else {
      this.spinner.show();
      this.apiService.createRequest(this.itemObject)
        .subscribe(
          (result: any) => {
            this.spinner.hide();
            this.createNewRequestItem = !this.createNewRequestItem;
            this.requestCreated = !this.requestCreated;
          },
          (error) => {
            this.spinner.hide();
            console.error(error.error.data.errors.description.message);
            this.toastrService.error(error.error.data.errors.description.message, "Error");
          }
        );
    }
  }


  /**
   * Delete an item
   * @param item
   */
  deleteItem(item) {
    this.itemApi.deleteRequest(item._id)
      .subscribe(this.onDeleteSuccess.bind(this), this.onDeleteError.bind(this));
  }

  /**
   * Handle item delete success
   *
   * @param data
   */
  onDeleteSuccess(data) {
    this.item_deleted = true;
    this.delete_item = undefined;
    this.item_list.refresh();
  }

  newRequestAdded() {
    this.item_list.refresh();
    this.requestCreated = !this.requestCreated;
  }

  /**
   * handle item delete error
   *
   * @param err
   */
  onDeleteError(err) {
  }

  completeProfile() {
    this.updateProfile = false;
    this.router.navigate([AppRoutePaths.MY_PROFILE]);
  }

}
