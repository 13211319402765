import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {Observable} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {ResourcesService} from "../user-auth/configs/resources.config";

@Injectable()
export class PullTranslationService implements Resolve<any> {
  constructor(public resourcesService: ResourcesService,
              public translate: TranslateService) {

  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    if (!route.component) {
      return Promise.resolve({});
    }
    const resource: any = this.resourcesService.getResourceName(route.component);
    /*this.translate.get(resource).subscribe((data) => {
    });*/
    return (this.translate.get(resource));
  }

  getComponentTranslationStrings(component): Observable<any> {

    console.warn(component, "translate");
    const resource: any = this.resourcesService.getResourceName(component);
    /*this.translate.get(resource).subscribe((data) => {
    });*/
    return (this.translate.get(resource));
  }

}
