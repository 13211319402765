<section class="search">
  <div class="search__container">
    <div class="search__col">
      <div class="search__form">
        <h1>together we have<br/> everything</h1>
        <div class="search__form-text my-5">
          share, borrow & discover<br/>
           on the most <span class="text_green">planet friendly </span><span class="text_orange">marketplace</span>
        </div>

        <app-search-item
          (searchQuery)="getSearchQuery($event)"
        ></app-search-item>
        <div class="list_something--container">
          <p class="mt-2">
            Got stuff you're not using right now?
            <a class="bee-btn bee-btn__tertiary" href="/items">list something</a>
          </p>
        </div>
      </div>
    </div>
    <!-- todo : better class name -->
    <div class="search__col hide-when-small">
      <div class="search__image"></div>
    </div>
  </div>
</section>
