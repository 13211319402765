<app-header-footer-layout [hiveName]="hiveName" [floating_sidebar_items]="sideMenuList"
                          [floating_sidebar]="shouldShowSidebar" [myHiveMenu]="myHiveMenu"
                          [show_sidebar]="false" [centre]="true">
  <p class="hive_name no-mr">{{hiveName}}</p>
  <p class="no-mr" *ngIf="showOwnerDetails">
    <span class="text_normal font-sm txt-prm">Owner: {{hiveOwnerName}} </span>
    <span class="text_normal font-sm txt-prm"> Contact: <a class="owner-link" href="mailto:{{hiveOwnerId}}" target="_blank">
      {{hiveOwnerId}}</a></span>
  </p>
  <span class="hive_description mt-2">{{hiveDescription}}</span>
  <ng-content></ng-content>
</app-header-footer-layout>
