import {Component, OnInit} from "@angular/core";
import {ApiService} from "../../user-auth/shared/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {ToastrService} from "ngx-toastr";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./../auth-common.component.scss"]
})
export class AuthResetPasswordComponent implements OnInit {
  password: string;
  confirmPassword: string;
  activation: string;

  constructor(private apiService: ApiService,
                private router: ActivatedRoute,
                private _router: Router,
                private spinner: NgxSpinnerService,
                private title: Title,
                private toastrService: ToastrService) {
    this.title.setTitle("Reset Password | Sharebee");
  }

  ngOnInit() {
    return undefined;
  }

  resetPassword() {
    this.spinner.show();
    if (this.password === this.confirmPassword) {

      this.router.queryParams.subscribe((query) => {
        const token = query["token"],
          userId = query["userId"],
          activation = query["activation"];

        const params: { newPassword: string, verifyPassword: string, activation: string }
                    = {newPassword: this.password, verifyPassword: this.confirmPassword, activation: activation};
        this.apiService.resetPassword({token, userId, params})
          .subscribe(
            (result: any) => this._resetPasswordSuccess(result),
            (error) => this._resetPasswordError(error)
          );
      });
    } else {
      this.toastrService.error("Sorry, your passwords don’t match. Please enter them again.");
    }
  }

  private _resetPasswordSuccess(result: any): any {
    this.spinner.hide();
    this._router.navigate(["/"]);
  }

  /**
     * Function to handle data if sign in failed
     * param err:<any>
     */
  private _resetPasswordError(error: any): any {
    this.spinner.hide();
    this.toastrService.error("Something went wrong");
  }
}
