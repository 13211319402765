import {NgModule} from "@angular/core";
import {ChooseDurationComponent} from "./choose-duration/choose-duration.component";
import {FormsModule} from "@angular/forms";
import {SharedModule} from "../shared/shared.module";
import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";
import {OwlDateTimeModule} from "@danielmoncada/angular-datetime-picker";


@NgModule({
  declarations: [
    ChooseDurationComponent
  ],
  exports: [
    ChooseDurationComponent
  ],
  imports: [
    FormsModule,
    SharedModule,
    NgxDaterangepickerMd,
    OwlDateTimeModule
  ],
  providers: [

  ]
})

export class OrderModule {

}
