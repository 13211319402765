import * as Rollbar from "rollbar"; // When using Typescript < 3.6.0.
// `import Rollbar from 'rollbar';` is the required syntax for Typescript 3.6.x.
// However, it will only work when setting either `allowSyntheticDefaultImports`
// or `esModuleInterop` in your Typescript options.

import {
  Injectable,
  Inject,
  InjectionToken,
  ErrorHandler
} from "@angular/core";

const rollbarConfig: Rollbar.Configuration = {
  accessToken: process.env.ROLLBAR_TOKEN,
  enabled: process.env.NODE_ENV === "production",
  nodeSourceMaps: true,
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: process.env.APP_ENV,
};

export const RollbarService = new InjectionToken<Rollbar>("rollbar");

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

  handleError(err:any) : void {
    this.rollbar.error(err.originalError || err);
  }
}

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}
