import { ConfirmationPopupComponent } from "./confirmation-popup/confirmation-popup.component";
import {NgModule} from "@angular/core";
import {NgxSpinnerModule} from "ngx-spinner";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BrowserModule} from "@angular/platform-browser";
import {ValidatorService} from "./Validator.provider";
import {ContainerComponent} from "./pop-up/container/container.component";
import {PopupHelperComponent, ViewModalComponent} from "./pop-up/view-modal/view-modal.component";
import {PopupService} from "./pop-up/popup.service";
import {PopupLayoutComponent} from "./pop-up/popup-layout/popup-layout.component";
import {TermsConditionComponent} from "./terms-condition/terms-condition.component";
import {EndpointsService} from "./constants/endpoints.service";
import {ItemCardComponent} from "./item-card/item-card.component";
import {HeaderComponent} from "./header/header.component";
import {GlobalEventsManager} from "./providers/GlobalEventsManager.provider";
import {AuthGuard} from "./providers/Auth.Guard";
import {HeaderFooterLayoutComponent} from "./page-layout/header-footer-sidemenu-layout/header-footer-layout.component";
import {ItemListComponent} from "./item-list/item-list.component";
import {FooterComponent} from "./footer/footer.component";
import {SideMenuComponent} from "./side-menu/side-menu.component";
import {RouterModule} from "@angular/router";
import {CommonModule} from "@angular/common";
import {TimePipe} from "./pipes/time.pipe";
import {WhiteBoxWithLogoLayoutComponent} from "./page-layout/white-box-with-logo-layout/white-box-with-logo-layout.component";
import {ImagePathService} from "./constants/image-path.service";
import {AccordionComponent} from "./accordion/accordion.component";
import {AccordionGroupComponent} from "./accordion/accordion-group.component";
import {CreateItemComponent} from "../items/create-item/create-item.component";
import {UploaderModule} from "src/app/uploader/src/lib/uploader.module";
import {TranslateModule} from "@ngx-translate/core";
import {TermsRulesCondition} from "./terms-condition/terms-rules-condition";
import { SipTermsAndConditionsComponent } from "./terms-condition/sip/sip-terms.component";
import {PrivacyPolicy} from "./privacy-policy/privacy-policy";
import {HomeModule} from "../home/home.module";
import {SanitizeHtmlPipe} from "./pipes/sanitize.pipe";
import {FloatingSidebarComponent} from "./floating-sidebar/floating-sidebar.component";
import { HomeTitleComponent } from "./home-title/home-title.component";
import { BeeBtnComponent } from "./bee-btn/bee-btn.component";
import {CategoryItemComponent} from "./category/category-item.component";

@NgModule({
  imports: [
    NgxSpinnerModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserModule,
    RouterModule,
    CommonModule,
    UploaderModule,
    TranslateModule.forChild(),
    // HomeModule
  ],
  declarations: [
    ContainerComponent,
    ViewModalComponent,
    PopupHelperComponent,
    PopupLayoutComponent,
    TermsConditionComponent,
    TermsRulesCondition,
    ItemCardComponent,
    HeaderComponent,
    HeaderFooterLayoutComponent,
    ItemListComponent,
    FooterComponent,
    SideMenuComponent,
    TimePipe,
    WhiteBoxWithLogoLayoutComponent,
    AccordionComponent,
    AccordionGroupComponent,
    CreateItemComponent,
    SipTermsAndConditionsComponent,
    ConfirmationPopupComponent,
    PrivacyPolicy,
    SanitizeHtmlPipe,
    FloatingSidebarComponent,
    HomeTitleComponent,
    BeeBtnComponent,
    CategoryItemComponent
  ],
  exports: [
    ContainerComponent,
    ViewModalComponent,
    PopupLayoutComponent,
    TermsConditionComponent,
    ItemCardComponent,
    HeaderComponent,
    HeaderFooterLayoutComponent,
    ItemListComponent,
    FooterComponent,
    SideMenuComponent,
    TimePipe,
    WhiteBoxWithLogoLayoutComponent,
    AccordionComponent,
    AccordionGroupComponent,
    CreateItemComponent,
    TermsRulesCondition,
    SipTermsAndConditionsComponent,
    ConfirmationPopupComponent,
    PrivacyPolicy,
    SanitizeHtmlPipe,
    HomeTitleComponent,
    BeeBtnComponent,
    FloatingSidebarComponent,
    CategoryItemComponent
  ],
  providers: [
    ReactiveFormsModule,
    FormsModule,
    ValidatorService,
    PopupService,
    GlobalEventsManager,
    AuthGuard,
    EndpointsService,
    ImagePathService
  ],
  entryComponents: []
})
export class SharedModule {
}
