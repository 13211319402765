import {FormattedUserLocation} from "../../helpers/UserDataHelper";
import {ApiService} from "../../../app/user-auth/shared/api.service";
import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {ItemAvailability, ItemObject, ItemSteps} from "../../shared/models/item.model";
import {ItemApiService} from "../item-api.service";
import {ToastrService} from "ngx-toastr";
import {EndpointsService} from "../../shared/constants/endpoints.service";
import {TranslateService} from "@ngx-translate/core";
import {NgxSpinnerService} from "ngx-spinner";
import {ImagePathService} from "../../shared/constants/image-path.service";
import {AppRoutePaths} from "@core/constants/route.path";
import {Router} from "@angular/router";
import { checkUserLoggedIn } from "src/app/utils/backend-jwt";

type ValidationType = {
  value?: string,
  title: string,
  rent: string,
  description: string,
  maintenance: string,
  serviceDate: string,
  toc_accepted: string,
  category: string
  location: string
}

@Component({
  selector: "app-create-item",
  templateUrl: "./create-item.component.html",
  styleUrls: ["./create-item.component.scss"]
})
export class CreateItemComponent implements OnInit {
  @Output() createItemFlag = new EventEmitter<boolean>();
  @Output() handleSuccess = new EventEmitter<any>();
  @Input() itemObject: ItemObject = {} as ItemObject;
  @Input() toc_accepted = false;
  @Input() edit_item: string;
  @Input() request_item_id: string;
  @Input() isPrivate = false;
  visibility = true;

  itemEdited: boolean;
  currentState: ItemSteps = ItemSteps.itemTitle;
  ItemSteps: typeof ItemSteps = ItemSteps;
  previous = "cancel";
  next = "next";
  lendFreeInfo: boolean;
  tcInfo: boolean;
  itemAdded: boolean;
  focusedElement = "";

  accept_TC = true;
  validateItem: ValidationType | { value?: string };
  isFileUploaded?: boolean;
  lend_free = false;
  uploadImage = ""; // 'http://localhost:8080/items/image/upload';
  displayTermsCondition: boolean;
  categories: string[] = ["household", "tools", "transport", "space", "technology", "clothing", "children's toys", "sports & recreation"];
  isUserLoggedIn = false;
  loggInPopUP: boolean;

  get can_go_next() {
    return this.currentState === ItemSteps.itemTitle
      || this.currentState === ItemSteps.itemDescription
      || (this.currentState === ItemSteps.itemValue && (this.toc_accepted)
        && this.itemObject.item_cost !== undefined);
  }

  constructor(public itemApiService: ItemApiService,
              public apiService: ApiService,
              private toastrService: ToastrService,
              public endpoints: EndpointsService,
              private spinner: NgxSpinnerService,
              public translate: TranslateService,
              private _router: Router,
              public imageService: ImagePathService) {
    // initialize itemObject.images to blank array
    this.itemObject.images = []; /*['assets/img/no_image_available.jpeg',
      'assets/img/no_image_available.jpeg',
      'assets/img/no_image_available.jpeg'];*/
    this.uploadImage = this.endpoints.IMAGE_UPLOAD;
  }

  get itemRentIsActive(): boolean {
    return ["day_rent", "week_rent", "month_rent"].indexOf(this.focusedElement) > -1;
  }

  imageUploading(obj: any) {
    this.spinner.show();
  }

  ngOnInit(): void {
    if (checkUserLoggedIn()) {
      this.isUserLoggedIn = true;
      this.setLocation();
    }
    // @todo uncomment
    // this.getCategory();
    /* console.warn(this.itemObject);
     let serviceDate = new DatePipe('en-US').transform(this.itemObject.service_date, 'dd/MM/yyyy');
     // console.warn(new DatePipe('en-US').transform(serviceDate, 'dd/MM/yyyy'));
     // console.warn(new DatePipe('en-US').transform(this.itemObject.service_date, 'dd/MM/yyyy'));
     console.warn(serviceDate);
     console.warn(new Date(this.itemObject.service_date));
     this.itemObject.service_date = new Date(this.itemObject.service_date);*/
  }

  setLocation() {
    console.warn(this.itemObject["availability"]);
    console.warn(this.itemObject);

    if (this.itemObject && this.itemObject.availability === undefined && !this.isPrivate) {
      this.visibility = false;
    } else if (this.itemObject && this.itemObject.availability) {
      this.visibility = this.itemObject.availability !== ItemAvailability.public;
    }

    // In theory the this.itemObject.location check is not needed but this will cover cases
    // before this was implemented where location can be null
    if (this.edit_item && this.itemObject.location) {
      return;
    }

    this.apiService.getProfileDetails()
      .toPromise()
      .then(({data}) => {
        this.itemObject.location = FormattedUserLocation(data);
      });
  }

  getCategory() {
    this.itemApiService.getCategory()
      .subscribe(
        (result: any) => {
          this.categories = result.category;
        },
        ({error}) => {
          this.toastrService.error(error, "Error");
        }
      );
  }

  /**
   * Once file is uploaded save the path of image to itemObject array
   * @param1 model
   * @param2 pos
   */
  onFileUploadDone(model, pos) {
    this.spinner.hide();
    model.value = model.data.file.file.path;
    this.itemObject.images[pos] = model.data.file.file.path;
  }

  safeCall(fn: any, event: any): void {
    this.spinner.hide();
    this.toastrService.error(arguments[0].message, "Error");
  }

  // Handle back button
  back() {
    if (this.currentState === ItemSteps.itemDescription) {
      this.previous = "cancel";
      this.currentState = ItemSteps.itemTitle;
    } else if (this.currentState === ItemSteps.itemValue) {
      this.currentState = ItemSteps.itemDescription;
      this.next = "next";
    } else {
      this.createItemFlag.emit(false);
    }

  }

  // Validate the steps & call api
  createItem() {
    if (this.isUserLoggedIn) {
      this.validateItem = { value: ""};
      if (this.currentState === ItemSteps.itemTitle) {
        if ((this.itemObject.location === "" || this.itemObject.location === undefined)) {
          this.validateItem["location"] = "Please enter the item's location";
        } else if (this.itemObject.title === "" || this.itemObject.title === undefined) {
          this.validateItem["title"] = "title should not be empty";
        } else {
          this.previous = "back";
          this.currentState = ItemSteps.itemDescription;
        }
      } else if (this.currentState === ItemSteps.itemDescription) {
        this.currentState = ItemSteps.itemValue;
        this.next = "submit";
      } else {
        if (this.itemObject.item_cost === 0 || this.itemObject.item_cost === undefined || this.itemObject.item_cost === null) {
          this.validateItem.value = "value cannot be empty";
        } else {
          this.itemObject.insured = true;
          for (let index = 0; index <= 2; index++) {
            if (this.itemObject.images[index] === undefined) {
              this.itemObject.images[index] = "assets/img/no_image_available.jpeg";
            }
          }

          this.itemObject.availability = this.visibility ? ItemAvailability.private : ItemAvailability.public;

          let operation;
          if (this.edit_item) {
            operation = this.itemApiService.editItem(this.edit_item, this.itemObject);
          } else {
            operation = this.itemApiService.createItem(this.itemObject, this.request_item_id);
          }

          operation.subscribe(
            (result: any) => {
              if (!this.edit_item) {
                this.itemAdded = true;
              } else {
                this.itemEdited = true;
              }
              console.warn("result", result);
              this.handleSuccess.emit(result.data.item);
            },
            ({error}) => {
              this.toastrService.error(error, "Error");
            }
          );
        }
      }
    } else {
      this.loggInPopUP = true;
      // this.createItemFlag.emit(false);
    }
  }



  acceptTC(event: any) {
    if (event.target.checked) {
      this.accept_TC = !this.accept_TC;
    } else {
      this.accept_TC = !this.accept_TC;
    }
  }

  lendFree(event: any) {
    if (event.target.checked) {
      this.lend_free = event.target.checked;
      this.itemObject.rent_rate = 0;
      this.itemObject.rent_rate_per_week = 0;
      this.itemObject.rent_rate_per_month = 0;
    } else {
      this.lend_free = !this.lend_free;
    }
  }


  accept() {
    this.toc_accepted = true;
    this.validateItem = {};
  }

  close() {
    this.loggInPopUP = false;
    this.createItemFlag.emit(false);
  }

  goToFAQ() {
    this._router.navigate([AppRoutePaths.FAQ]);
  }

  loginUser() {
    this._router.navigate([AppRoutePaths.LOGIN_USER]);
  }

  signUpUser() {
    this._router.navigate([AppRoutePaths.HOME_GUEST_REGISTER]);
  }
}

