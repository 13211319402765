type EndpointsProps = {
  baseUrl: string;
  apiHost: string;
}

export class EndpointsConstant {

  protected BASE_URL: string;
  protected API_HOST: string;

  constructor(config?: EndpointsProps) {
    this.BASE_URL = process.env.URL || config?.baseUrl;
    this.API_HOST = process.env.API_HOST || config?.apiHost;
  }
}
