import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {AuthEndpoints} from "./constants/endpoints.service";
import { AppHttpService } from "@core/http.service";
import { map } from "rxjs/operators";
import {SignInRequest, SignupRequest } from "./requests";

type UpdateProfileRequest = {

}

@Injectable()
export class ApiService extends AppHttpService {
  constructor(protected http: HttpClient, private endpoints: AuthEndpoints) {
    super(http);
  }


  /**
   * Register a new user
   * param new_user<string>
   * return Observable<any>
   */
  public signup(data: SignupRequest): Observable<any> {
    return this.put(this.endpoints.REGISTER, data);
  }

  /**
   * SignIn a new user
   *
   * param userObj<Object>
   * return Observable<any>
   */
  public signIn(request: SignInRequest): Observable<any> {
    return this.post(this.endpoints.LOGIN, request, {withCredentials: true});
  }

  /**
   * Logout user
   * param userObj<Object>
   * return Observable<any>
   */
  public logout(): Observable<any> {
    return this.get(this.endpoints.LOGOUT, {}, {withCredentials: true});
  }

  public downloadUserCSV(): Observable<any> {
    return this.get(this.endpoints.USERS_CSV);
  }

  getKPI(kpi): any {
    const url = kpi === "" ? this.endpoints.GET_KPI : this.endpoints.GET_KPI + "?groupType=" + kpi;
    return this.http.get<any>(url)
      .pipe(map((result) => {
        return result.data;
      }));
  }

  public downloadItemsCSV(): Observable<any> {
    return this.get(this.endpoints.ITEMS_CSV);
  }

  public downloadProfileCSV(): Observable<any> {
    return this.get(this.endpoints.PROFILES_CSV);
  }

  public downloadRequestCSV(): Observable<any> {
    return this.get(this.endpoints.REQUEST_CSV);
  }

  public downloadOrdersCSV(): Observable<any> {
    return this.get(this.endpoints.ORDERS_CSV);
  }

  public downloadHiveCSV(): Observable<any> {
    return this.get(this.endpoints.HIVES_CSV);
  }

  /**
   * SignIn a new user
   *
   * param userObj<Object>
   * return Observable<any>
   */
  public invite(userObj: { email: string }): Observable<any> {
    return this.post(this.endpoints.INVITE, { data: userObj });
  }

  /**
   * Verify a user using the verification code
   *
   * param new_user<string>
   * return Observable<any>
   */
  public verify(verify: { userid: string, code: string }): Observable<any> {
    return this.post(this.endpoints.VERIFY, verify);
  }

  /**
   * Calls reset password API
   * param userObj - Contains emailId
   * returns Observable<any> - Sends link
   */
  public sendResetPasswordLink(userObj: { email: string }): Observable<any> {
    return this.post(this.endpoints.RESET_PASSWORD_LINK, userObj);
  }

  public resetPassword(userObj: {
    token: string, userId: string,
    params: { newPassword: string, verifyPassword: string, activation: string }
  }): Observable<any> {
    const url: string = this.endpoints.RESET_PASSWORD + "/" + userObj.token + "/user-id/" + userObj.userId;
    return this.post(url, userObj.params);
  }

  /**
   * Register a new user
   * return Observable<any>
   */
  public deleteMyAccount(): Observable<any> {
    return this.get(this.endpoints.DELETE_MY_ACCOUNT, {});
  }

  /**
   * calls API to get profile configuration
   * returns {Observable<any>}
   */
  public getProfileConfig(): Observable<any> {
    const url = this.endpoints.GET_PROFILE_CONFIG;
    return this.get(url, {});
  }

  /**
   * calls API to fetch profile details of a user
   * returns {Observable<any>}
   */
  public getProfileDetails(userObj?: {
    userId: string
  }): Observable<any> {
    const url = this.endpoints.GET_PROFILE_DETAILS;
    return this.get(url, { data: ((userObj || {}) as any).userId });
  }

  /**
   * calls API to update profile object
   *
   * param {{}} profileObj
   * returns {Observable<any>}
   */
  public updateProfile(profileObj: UpdateProfileRequest): Observable<any> {
    const url: string = this.endpoints.UPDATE_PROFILE_DETAILS;
    return this.post(url, profileObj);
  }


  public emailSubscription(data): Observable<any> {
    const url: string = this.endpoints.EMAIL_SUBSCRIPTION;
    return this.post(url, {data: data} );
  }

  public invitedUser = (data): Observable<any> => {
    const url = this.endpoints.INVITED_USER;
    return this.post(url, {data});
  };


  public signUp = (data: SignupRequest): Observable<any> => {
    const url = this.endpoints.REGISTER;
    return this.post(url, {data});
  };

  // invite user, note there is a invite as well but does not seem to have a end point in the backend
  public sendInvite = (data): Observable<any> => {
    const url = this.endpoints.INVITE_USER;
    return this.post(url, {data});
  };

  public addInviteCode = (code: string):  Observable<any> => {
    const url = this.endpoints.ADD_INVITE_CODE;
    return this.post(url, {code});
  };

  public listInviteCodes(): Observable<any[]> {
    const url = this.endpoints.LIST_INVITE_CODE;
    return this.get(url, {}).
      pipe(
        map((res) => {
          return res.data.resp;
        })
      );
  }

  public deleteInviteCode(inviteCode): Observable<any> {
    const url = this.endpoints.DELETE_INVITE_CODE;
    // why does AppHttpService not have delete :( :( :(
    return this.get(url, {
      id : inviteCode._id
    });
  }
}
