import {Component, OnInit} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {DomainResource} from "../../user-auth/configs/config.helper";
import {Role} from "../../user-auth/shared/user.services";
import {ActivatedRoute, Router} from "@angular/router";
import {ItemApiService} from "../../items/item-api.service";
import {HiveApiService} from "../hive-api.service";
import {flatMap, map, switchMap} from "rxjs/operators";
import { checkUserLoggedIn, parseJWTCookie } from "src/app/utils/backend-jwt";

@DomainResource({
  name: "HIVE_MY_ITEMS",
  access: [Role.ADMIN, Role.USER]
})
@Component({
  selector: "app-hive-item-list",
  templateUrl: "./hive-my-items.component.html",
  styleUrls: ["./hive-my-items.component.scss"]
})
export class HiveMyItemsComponent implements OnInit {
  myItems = [];
  hiveId = "";
  hiveDetails: any;
  loggedInUser: any;
  isUserLoggedIn = false;
  isApproved = false;
  activeItem;
  buttonText = ["remove", "add", "update"];

  constructor(public translate: TranslateService, protected router: Router,
              private itemApi: ItemApiService, private api: HiveApiService, private route: ActivatedRoute) {
    this.route.paramMap.subscribe(params => {
      this.hiveId = params.get("id");
    });
  }

  create() {
    this.router.navigate(["/hive/create"]);
  }

  ratePressed(item) {
    if (item.isAdded) {
      this.activeItem = item.title;
    }
  }

  addAndRemove(item) {
    if (this.hiveDetails.isOwn) {
      this.updateData(item);
    } else {
      const userId = this.loggedInUser.userid;
      const emailId = this.loggedInUser.email;
      const existing: any = this.hiveDetails.bees.find((i: { _id?: any, email?: any​, status?: any, user_id?: any }) => {
        return i.user_id === userId || i.email === emailId;
      });
      if (existing.status === "accepted") {
        this.updateData(item);
      } else {
        this.isApproved = !this.isApproved;
      }
    }
  }

  updateData(item) {
    if (!item.prices["rate_per_day"]) {
      item.prices.rate_per_day = 0;
    }
    if (!item.prices["rate_per_week"]) {
      item.prices.rate_per_week = 0;
    }
    if (!item.prices["rate_per_month"]) {
      item.prices.rate_per_month = 0;
    }
    if (item.isAdded && item.title === this.activeItem) {
      this.api.addItemsIntoHive(this.hiveId, {
        item_id: item._id,
        prices: item.prices
      }).subscribe((data) => {
        this.activeItem = "";
        this.loadData();
      });
    } else if (item.isAdded) {
      this.api.removeItemsFromHive(this.hiveId, item._id).subscribe(
        (data) => {
          this.loadData();
        });
    } else {
      this.api.addItemsIntoHive(this.hiveId, {
        item_id: item._id,
        prices: item.prices
      }).subscribe((data) => {
        this.loadData();
      });
    }
    /*this.route.paramMap
      .pipe(
        switchMap((params) => !item.isAdded
          ? this.api.addItemsIntoHive(params.get('id'), {
            item_id: item._id,
            prices: item.prices
          })
          : this.api.removeItemsFromHive(params.get('id'), item._id)
        )
      ).subscribe(() => this.loadData());*/
  }

  ngOnInit(): void {
    if (checkUserLoggedIn()) {
      this.loggedInUser = parseJWTCookie();
      this.isUserLoggedIn = true;
    }
    this.loadData();
  }

  loadData(): void {
    this.api.getHive(this.hiveId).subscribe((data) => {
      this.hiveDetails = data.data;
    });

    this.itemApi.getItemsUploadedByMe(0, 0)
      .pipe(flatMap((({data: {items}}) => {
        return this.route.paramMap.pipe(
          switchMap((params) => this.api.getHiveItems(params.get("id"))),
          map(({data: list}) => {
            return items.map((item) => {
              const exist = list.find((i) => {
                return i._id === item._id;
              });
              item.prices = {};
              if (exist) {
                Object.assign(item, {
                  ...exist,
                  isAdded: true
                });
              }

              return item;
            }).sort((item) => item.isAdded ? -1 : 1);
          })
        );
      })))
      .subscribe((items) => {
        this.myItems = items;
      });
  }

  cancelIsApproved() {
    this.isApproved = !this.isApproved;
  }
}
