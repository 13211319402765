import { Component, Input } from "@angular/core";
import {ImagePathService} from "../../constants/image-path.service";

@Component({
  selector: "app-white-box-with-logo-layout",
  templateUrl: "./white-box-with-logo-layout.component.html",
  styleUrls: ["./white-box-with-logo-layout.component.scss"]
})

export class WhiteBoxWithLogoLayoutComponent {
  @Input() boxTitle: string;

  constructor(public imageService: ImagePathService) {

  }
}
