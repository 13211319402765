
<footer class="footer-component text-center">
  <div class="container">
    <div class="footer-content">
      <a href="/">
        <img alt="Sharebee" class="footer-logo" [src]="imageService.HEADER_LOGO">
      </a>
      <div class="align_item_center">
        <span class="footer-text show--cursor" (click)="displayTermsCondition = !displayTermsCondition">Terms rules & conditions </span>
        <span class="footer-text show--cursor" (click)="displayPrivacyPolicy = !displayPrivacyPolicy">| Privacy | </span>
        <a class="footer-text show--cursor" href="/faq">FAQ's</a><span> | </span>
        <a class="footer-text show--cursor" href="/contact-us">Contact us</a><span> | </span>
        <a class="footer-text show--cursor" href="/about-sharebee">About Sharebee</a>
      </div>
      <div class="social-media">
        <a href="https://www.facebook.com/sharebeeUK/" target="_blank">
          <img class="social-media-icons" [src]="imageService.FACEBOOK"></a>
        <a href="https://twitter.com/@sharebeeUK" target="_blank"><img class="social-media-icons" [src]="imageService.TWITTER"></a>
        <a href="https://www.youtube.com/watch?v=S5mYvixDARI" target="_blank"><img class="social-media-icons" [src]="imageService.YOUTUBE"></a>
        <a href="https://www.instagram.com/sharebeeuk/?hl=en" target="_blank"><img class="social-media-icons" [src]="imageService.INSTAGRAM"></a>
      </div>
    </div>
    <script id="CookieDeclaration" src="https://consent.cookiebot.com/15ddec7f-4b76-4466-bfda-0b13b264f77a/cd.js" type="text/javascript" async></script>
    <div>
      <span class="footer-text">2022 Sharebee, Inc. All rights reserved</span>
    </div>
  </div>
</footer>


<popup-layout *ngIf="displayTermsCondition">
  <app-terms-rules-condition #app_terms_condition>
    <div class="button-container align_center margin--bottom">
<!--            <button class="button" (click)="displayTermsCondition = false">{{'CANCEL' | translate}}</button>-->
      <bee-btn type="primary" (click)="displayTermsCondition = false">Cancel</bee-btn>
      <!--<button class="button custom-button active" (click)="this.displayTermsCondition = false">{{'ACCEPT' | translate}}</button>-->
    </div>
  </app-terms-rules-condition>
</popup-layout>


<popup-layout *ngIf="displayPrivacyPolicy">
  <app-privacy-policy #app_privacy_policy>
    <div class="button-container align_center margin--bottom">
<!--            <button class="button" (click)="displayPrivacyPolicy = false">{{'CANCEL' | translate}}</button>-->
      <bee-btn type="primary" (click)="displayPrivacyPolicy = false">Cancel</bee-btn>
      <!--<button class="button custom-button active" (click)="this.displayTermsCondition = false">{{'ACCEPT' | translate}}</button>-->
    </div>
  </app-privacy-policy>
</popup-layout>
