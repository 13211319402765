/**
 * @author Atikur Rahman
 */

import {Component, Input, OnChanges, OnInit, ViewEncapsulation} from "@angular/core";

@Component({
  selector: "lib-file-viewer",
  templateUrl: "./file-viewer.component.html",
  styleUrls: [
    "file-viewer.component.scss"
  ],
  encapsulation: ViewEncapsulation.None
})
export class FileViewerComponent implements OnInit, OnChanges {
  @Input() file: File;

  file_type;
  file_url;

  constructor() {}
  ngOnChanges(){
    this.file_url = window.URL.createObjectURL(this.file);
    this.file_type = this.file.type.split("/").shift();
  }

  ngOnInit() {}
}
