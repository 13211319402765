import {Component, ViewEncapsulation} from "@angular/core";
import {DomainResource} from "../../user-auth/configs/config.helper";
import {Role, UserServices} from "../../user-auth/shared/user.services";
import {ActivatedRoute, Router} from "@angular/router";
import {StateService} from "../../service/state.service";
import { AppRoutePaths } from "@core/constants/route.path";

@DomainResource({
  name: "MY_PROFILE",
  access: [Role.ADMIN, Role.USER]
})

@Component({
  selector: "app-my-profile",
  templateUrl: "./my-profile.component.html",
  styleUrls: ["my-profile.component.scss"],
  encapsulation: ViewEncapsulation.None
})

export class MyProfileComponent {
  profile_updated = false;
  navigateToMenu: boolean;
  button_text = "close";
  text_content = "your profile has been updated";
  hiveId: string;

  constructor(private user: UserServices,
              private route: ActivatedRoute,
              private stateService: StateService,
              public router: Router) {
    this.hiveId = this.stateService.getHiveId();
    console.warn({hiveId: this.hiveId});
  }

  close() {
    this.profile_updated = false;
    if (this.navigateToMenu) {
      if (this.hiveId !== undefined) {
        this.router.navigate([`/users/my-hive/${this.hiveId}/items`]);
      } else {
        this.router.navigate([AppRoutePaths.HOME]);
      }
    }
  }

  profileUpdated(data: any) {
    if (data.name && data.city && data.mobile && data.date_of_birth) {
      this.user.getUser()
        .then((user) => {
          if (!user["profileUpdated"]) {
            this.button_text = this.hiveId !== undefined ? "hive menu" : "go to home page";
            this.navigateToMenu = true;
          }
          user["profileUpdated"] = true;
          this.text_content = "your profile has been updated";
          this.user.setUser(user);
        });
    } else {
      if (!data.name) {
        this.text_content = "please update your name";
      } else if (!data.mobile) {
        this.text_content = "please update your mobile number";
      } else {
        this.text_content = "please update your date of birth";
      }
    }
    this.profile_updated = true;
  }
}
