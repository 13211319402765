import {ACCESS_INFO} from "../../user-auth/configs/rbac.config";
import {Role} from "../../user-auth/shared/user.services";
import {_RESOURCES_} from "../../user-auth/configs/resources.config";


function setResourceAccess(role: string, resource: string) {
  ACCESS_INFO[role][resource] = { "read:any": [ "*" ] };
}

export function DomainResource(details: { name: string, access: Role[] }) {
  return (resource) => {
    _RESOURCES_[details.name] = resource;
    (details).access.forEach(role => setResourceAccess(role, details.name));
  };
}
