import {AfterViewInit, Component} from "@angular/core";
import {AppRoutePaths} from "@core/constants/route.path";
import {ActivatedRoute, Router, UrlCreationOptions} from "@angular/router";
import {ApiService} from "../../user-auth/shared/api.service";
import {propEq} from "ramda";
import {ToastrService} from "ngx-toastr";
import { Title } from "@angular/platform-browser";
import { SignupRequest } from "../../user-auth/shared/requests";
import { config } from "src/config";
import * as bootstrap from "bootstrap";

declare const fbq: any;

@Component({
  selector: "app-auth-signup",
  templateUrl: "./sign-up.component.html",
  styleUrls: ["./../auth-common.component.scss"]
})
export class AuthSignUpComponent implements AfterViewInit {
  email: string;
  postCode: string;
  password: string;
  confirmPassword: string;
  newsletter: boolean;
  tnc: boolean; // unused on the back-end
  displayTermsCondition: boolean;
  hiveId: string;
  name: string;
  lastName: string;
  emailRegex: string;


  public error = false;
  public errorMsg: string;
  public invited = false;
  public inviteRequested = false;
  public windowHeight: number;
  public submitEnabled = true;

  constructor(public router: Router, private apiService: ApiService,
              private toastrService: ToastrService,
              private title: Title,
              public activatedRoute: ActivatedRoute) {
    this.emailRegex = String(config.emailRegex);
    this.title.setTitle("Signup | Sharebee");
  }

  ngOnInit() {
    const tooltipTriggerList = [].slice.call(document.querySelectorAll("[data-bs-toggle=\"tooltip\"]"));
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl);
    });
  }

  ngAfterViewInit() {
    this.activatedRoute.queryParams.subscribe((query) => {
      this.hiveId = query["hiveId"];
    });
  }

  onFormChange = () => {
    const passwordsFilled = this.password && this.confirmPassword;
    const passwordsMatching = this.password === this.confirmPassword;

    this.submitEnabled = passwordsFilled &&
      passwordsMatching &&
      this.tnc &&
      this.email !== "" &&
      this.name !== "" &&
      this.lastName !== "";
  };

  // todo, type
  submitForm() {
    const passwordsFilled = this.password && this.confirmPassword;
    const passwordsMatching = this.password === this.confirmPassword;
    const termsCondition = propEq("tnc", true);
    if (passwordsFilled && !passwordsMatching) {
      this.toastrService.info("Password & Confirm password should be matching");
      return;
    }
    if(!termsCondition) {
      this.toastrService.info("Please accept our terms and condition");
      return;
    }

    this.error = false;
    const isNewsletter = propEq("newsletter", true);

    const requestData: SignupRequest  = {
      email: this.email,
      password: this.password,
      newsletter: this.newsletter,
      name: this.name,
      lastName: this.lastName,
      hiveId: this.hiveId
    };

    if (isNewsletter(requestData)) {
      this.apiService.emailSubscription(requestData)
        .subscribe(
          (resp) => {
            console.warn("subscribed", resp);
          },
          (error) => {
            console.error(error, "error");
            this.error = true;
            this.errorMsg = "There was an issue subscribing you to the newsletter, please try again";
          }
        );
    }

    if (this.hiveId !== undefined) {
      requestData.hiveId = this.hiveId;
    }

    this.apiService.signup(requestData)
      .subscribe(
        (result: any) => {
          this.invited = true;
          if (this.hiveId !== undefined) {
            localStorage.setItem("hiveId", this.hiveId);
          }
          fbq("track", "CompleteRegistration");
          this.navigateToLogin({ queryParams: { signup: true, email: this.email} });
        },
        ({error}) => {
          this.toastrService.error(error);
        }
      );
  }



  navigateToLogin(extra: UrlCreationOptions = {}) {
    this.router.navigate([AppRoutePaths.LOGIN_USER], extra);
  }

  termsCondition() {
    this.displayTermsCondition = !this.displayTermsCondition;
  }

  homePage() {
    this.router.navigate(["/"]);
  }

}
