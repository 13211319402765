import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "accordion",
  templateUrl: "./accordion.component.html",
  styles: [
    `
    .accordion-head {
      cursor: pointer;
      font-family: Nunito, sans-serif;
      font-weight: 500;
    }
    .accordion-body {
      display: none;
      color: #666666;
      padding-left: 1rem;
    }
    .accordion-body.active {
      display: block;
    }
    `
  ],
})
export class AccordionComponent {

  @Input() title: string;

  @Input() active = false;

  @Output() toggleAccordion: EventEmitter<boolean> = new EventEmitter();

  constructor() {}

  onClick(event) {
    event.preventDefault();
    this.toggleAccordion.emit(this.active);
  }

}
