<!--<app-header-footer-layout>-->
<popup-layout class="create--item">
  <section class="app-create-item">
    <form>
      <!-- TODO Delete this section -->
      <div *ngIf="currentState === ItemSteps.itemTitle">
        <div class="item-list">
          <div class="item-list__image">

            <file-uploader
              class="item-file-uploader"
              [upload_url]="uploadImage"
              [file_formats]="['jpg', 'jpeg','JPEG','JPG','png', 'PNG']"
              [file_type]="['image']"
              [allow_retry]="true"
              [max_file_size]="1e+9"
              [disabled]=""
              [img_path]="itemObject.images[0]"
              [crop_disable]=true
              [(isFileUploaded)]="isFileUploaded"
              (fileUploadTrigger)="imageUploading($event)"
              (onUploadDone)="onFileUploadDone($event,0)"
              (onValidationError)="safeCall($event)">
              <div class="uploader-title">
                <div class="image--placeholder"></div>
                <!--<img src="../../../assets/svg/plus.svg">-->
                <!--Drag and drop or select-->
              </div>
            </file-uploader>
          </div>
        </div>
        <div class="item-info align_item_center">
          <label class="label small primary"> {{'CREATE_ITEM.DRAG_DROP' | translate}} <a href="/faq" class="content--selector">FAQ's</a> {{'CREATE_ITEM.DRAG_DROP_1' | translate}}
          </label>
        </div>
        <div class="item-tile">
          <div class="provide-padding">
            <label for="title" [ngClass]="{'set--active': focusedElement === 'title'}"
                   class="label sm-1 primary">{{'CREATE_ITEM.TITLE' | translate}}</label>
          </div>
          <div>
            <input id="title" (focus)="focusedElement = 'title'" (blur)="focusedElement = ''" class="input-field sm1"
                   name="title" required [(ngModel)]="itemObject.title"
                   required minlength="4">
            <p class="validation-alert">{{validateItem.title}}</p>
          </div>
        </div>
        <div class="item-tile">
          <div class="provide-padding">
            <label for="title" [ngClass]="{'set--active': focusedElement === 'location'}"
                   class="label sm-1 primary">{{"CREATE_ITEM.LOCATION" | translate}}</label>
          </div>
          <div>
            <input id="location" (focus)="focusedElement = 'location'" (blur)="focusedElement = ''"
                   class="input-field sm1" name="location" [(ngModel)]="itemObject.location" required minlength="2">
            <p class="validation-alert">{{validateItem.location}}</p>
          </div>
        </div>
      </div>

      <div *ngIf="currentState === ItemSteps.itemDescription">
        <div class="item-share">
          <label class="label md mg5 primary"
                 [ngClass]="{'set--active': itemRentIsActive}">{{'CREATE_ITEM.SHARE_RATE' | translate}}</label>
          <div class="item-share__lend">
            <input type="checkbox" class="regular-checkbox" [checked]="lend_free" (change)="lendFree($event)">
            <span class="lend_text">{{'CREATE_ITEM.LEND_FREE' | translate}}</span>
            <img class="lend--free__info show--cursor" [src]="imageService.KNOW_MORE"
                 (click)="lendFreeInfo = !lendFreeInfo"/>
          </div>
        </div>
        <div class="item-share">
          <div class="item-share__rate">
            <label class="label sm-1 primary"
                   [ngClass]="{'set--active': focusedElement === 'day_rent'}">{{'CREATE_ITEM.DAY_RATE' | translate}}</label>
            <input (focus)="focusedElement = 'day_rent'" (blur)="focusedElement = ''" [disabled]="lend_free"
                   type="number"
                   class="input-field sm1" [(ngModel)]="itemObject.rent_rate" name="itemObject.rent">
            <p class="validation-alert">{{validateItem.rent}}</p>
          </div>
          <div class="item-share__rate">
            <label class="label sm-1 primary"
                   [ngClass]="{'set--active': focusedElement === 'week_rent'}">{{'CREATE_ITEM.WEEK_RATE' | translate}}</label>
            <input (focus)="focusedElement = 'week_rent'" (blur)="focusedElement = ''" [disabled]="lend_free"
                   type="number"
                   class="input-field sm1" [(ngModel)]="itemObject.rent_rate_per_week" name="itemObject.week_rent">
            <p class="validation-alert">{{validateItem.rent}}</p>
          </div>
          <div class="item-share__rate">
            <label class="label sm-1 primary"
                   [ngClass]="{'set--active': focusedElement === 'month_rent'}">{{'CREATE_ITEM.MONTH_RATE' | translate}}</label>
            <input (focus)="focusedElement = 'month_rent'" (blur)="focusedElement = ''" [disabled]="lend_free"
                   type="number"
                   class="input-field sm1" [(ngModel)]="itemObject.rent_rate_per_month" name="itemObject.month_rent">
            <p class="validation-alert">{{validateItem.rent}}</p>
          </div>
        </div>

        <div class="item-tile">
          <div class="provide-padding">
            <label for="title" [ngClass]="{'set--active': focusedElement === 'visibility'}"
                   class="label sm-1 primary">Availability</label>
          </div>
          <div class="visibility-container">
            <span class="label sm-1 primary label_public">Public</span>
            <div class="onoffswitch">
              <input type="checkbox" (focus)="focusedElement = 'visibility'" name="onoffswitch"
                     class="onoffswitch-checkbox"
                     id="myonoffswitch" checked
                     [(ngModel)]="visibility">
              <label class="onoffswitch-label" for="myonoffswitch"></label>
            </div>
            <span class="label sm-1 primary label_private">Private</span>
          </div>
        </div>

        <div class="category-list-container mt-2">
          <label class="label sm-1 primary provide-padding-right">{{'CREATE_ITEM.CATEGORY' | translate}}</label>
          <select class="category-list from-country" [(ngModel)]="itemObject.category" name="itemObject.category">
            <option *ngFor="let category of categories" [ngValue]="category">{{category}}</option>
          </select>
          <p class="validation-alert">{{validateItem.category}}</p>
        </div>
        <div class="item-share__description">
          <label class="label sm-1 primary"
                 [ngClass]="{'set--active': focusedElement === 'description'}">{{'CREATE_ITEM.ITEM_DESCRIPTION' | translate}}</label>
          <textarea class="text_area sm1" (focus)="focusedElement = 'description'" (blur)="focusedElement = ''"
                    [(ngModel)]="itemObject.description" name="itemObject.description"></textarea>
          <p class="validation-alert">{{validateItem.description}}</p>
        </div>
      </div>
      <div *ngIf="currentState === ItemSteps.itemValue">
        <div class="item-value__description">
          <label class="label md1 text_orange font_abs">{{'CREATE_ITEM.PRIVATE' | translate}}</label>
          <label class="label sm-1 primary mt-1">{{'CREATE_ITEM.VISIBLE_OTHERS' | translate}}</label>
        </div>
        <div class="item-value provide-padding-bottom mt-1">
          <label class="label sm-1 primary"
                 [ngClass]="{'set--active': focusedElement === 'value'}">{{'CREATE_ITEM.VALUE' | translate}}*</label>
          <input type="number" class="input-field" (focus)="focusedElement = 'value'; validateItem.value = ''"
                 (blur)="focusedElement = ''" [(ngModel)]="itemObject.item_cost" name="itemObject.item_cost">
          <p class="validation-alert">{{validateItem.value}}</p>
        </div>

        <div class="item-maintenance provide-padding-bottom">
          <label class="label sm-1 primary"
                 [ngClass]="{'set--active': focusedElement === 'maintenance'}">{{'CREATE_ITEM.INFORMATION' | translate}}</label>
          <textarea class="text_area sm1" (focus)="focusedElement = 'maintenance'" (blur)="focusedElement = ''"
                    [(ngModel)]="itemObject.other_information" name="itemObject.maintenance"></textarea>
          <p class="validation-alert">{{validateItem.maintenance}}</p>
        </div>

        <div class="item-service provide-padding-bottom">
          <label class="label sm-1 primary"
                 [ngClass]="{'set--active': focusedElement === 'serviceDate'}">{{'CREATE_ITEM.SERVICE_DATE' | translate}}</label>
          <input *ngIf="!edit_item" type="date" class="input-field sm1" (focus)="focusedElement = 'serviceDate'"
                 (blur)="focusedElement = ''"
                 [(ngModel)]="itemObject.service_date" name="itemObject.serviceDate">
          <label *ngIf="edit_item" class="input-field sm1">{{itemObject.service_date | date:'dd/MM/yyyy'}}</label>
          <p class="validation-alert">{{validateItem.serviceDate}}</p>
        </div>

        <div class="item-insurance provide-padding-bottom">
          <label class="label sm quad" *ngIf="!toc_accepted">{{'CREATE_ITEM.INSURANCE' | translate}}
            <img class="tc_info show--cursor" [src]="imageService.KNOW_MORE" (click)="tcInfo = !tcInfo"/>
          </label>
          <label class="label sm validated" *ngIf="toc_accepted">{{'CREATE_ITEM.INSURED_1' | translate}}</label>
          <label class="validation-alert">{{validateItem.toc_accepted}}</label>
        </div>
        <div class="terms-condition provide-padding-bottom">
          <label class="label sm-1 ternary"
                 (click)="displayTermsCondition = !displayTermsCondition">{{'CREATE_ITEM.TC' | translate}}</label>
        </div>

      </div>
      <div class="item-stage__navigation">
        <div class="circle" [ngClass]="{'ternary':currentState === ItemSteps.itemTitle}"></div>
        <div class="circle" [ngClass]="{'ternary':currentState === ItemSteps.itemDescription}"></div>
        <div class="circle" [ngClass]="{'ternary':currentState === ItemSteps.itemValue}"></div>
      </div>
      <div class="button-container">
<!--        <button class="button provide-margin__button" (click)="back()">{{previous}}</button>-->
        <bee-btn type="secondary" (click)="back()">{{previous}}</bee-btn>
        <!--<button type="submit" class="provide-margin__button button" [class.active]="can_go_next"
                [disabled]="!can_go_next" [class.disabled]="!can_go_next" (click)="createItem()">{{next}}</button>-->
        <bee-btn type="primary" (click)="createItem()" [class.active]="can_go_next"
                 [disabled]="!can_go_next" [class.disabled]="!can_go_next">{{next}}</bee-btn>
      </div>
    </form>
  </section>
</popup-layout>

<popup-layout *ngIf="displayTermsCondition">
  <app-terms-condition #app_terms_condition>
    <div class="mt-2">
<!--      <input class="regular-checkbox" type="checkbox" (click)="acceptTC($event)">-->
      <label class="t_r_c">I agree terms & conditions</label>
    </div>
    <div class="button-container align_center margin--bottom">
<!--      <button class="button" (click)="displayTermsCondition = false">{{'CANCEL' | translate}}</button>-->
<!--      <button class="button custom-button" [disabled]="accept_TC"-->
      <!--<button class="button custom-button"
              (click)="accept(); this.displayTermsCondition = false">{{'ACCEPT' | translate}}</button>-->
      <bee-btn type="secondary" (click)="displayTermsCondition = false">Cancel</bee-btn>
      <bee-btn type="primary" (click)="accept(); this.displayTermsCondition = false">Accept</bee-btn>
    </div>
  </app-terms-condition>
</popup-layout>

<popup-layout *ngIf="itemAdded">
  <div class="item-creation__popup">
    <label class="label md1 bold-font popup-title">listing uploaded</label>
    <label
      class="label sm-1 quad provide-padding-bottom_created popup-contents">{{'CREATE_ITEM.CONGRATULATIONS' | translate}}</label>
<!--    <button class="button active" (click)="close()">{{'CLOSE' | translate}}</button>-->
    <bee-btn type="primary" (click)="close()">Close</bee-btn>
  </div>
</popup-layout>

<popup-layout *ngIf="itemEdited">
  <div class="item-creation__popup">
    <label class="label md1 bold-font popup-title">Item edited successfully</label>
    <!--<label class="label sm-1 quad provide-padding-bottom_created popup-contents">Congratulations! Your listing has been uploaded</label>-->
<!--    <button class="button active" (click)="close()">close</button>-->
    <bee-btn type="primary" (click)="close()">Close</bee-btn>
  </div>
</popup-layout>

<popup-layout *ngIf="lendFreeInfo">
  <div class="item-creation__popup">
    <div class="item-tc-pop-up">
      <p class="t_r_c_1 align_center">

        {{'CREATE_ITEM.LEND_HEAD' | translate}}
      </p>
      <p class="t_r_c">
        {{'CREATE_ITEM.LEND_LINE_1' | translate}}
      </p>
      <ul>
        <li class="t_r_c">
          {{'CREATE_ITEM.LEND_LI_1' | translate}}
        </li>
        <li class="t_r_c">
          {{'CREATE_ITEM.LEND_LI_2' | translate}}
        </li>
        <li class="t_r_c">
          {{'CREATE_ITEM.LEND_LI_3' | translate}}
        </li>
      </ul>
      <p class="t_r_c">
        If you want any more advice, get in touch at <a href="hello@sharebee.co.uk"> hello@sharebee.co.uk</a>
      </p>
    </div>
    <div class="mt-2">
<!--      <button class="button" (click)="lendFreeInfo = !lendFreeInfo">{{'CLOSE' | translate}}</button>-->
      <bee-btn type="primary" (click)="lendFreeInfo = !lendFreeInfo">Close</bee-btn>
    </div>
  </div>
</popup-layout>

<popup-layout *ngIf="tcInfo">
  <app-sip-terms-and-conditions
    (close)="tcInfo = !tcInfo"
  ></app-sip-terms-and-conditions>
</popup-layout>

<popup-layout *ngIf="loggInPopUP">
  <section class="padding-box">
    <div class="mt-2">
      <label>You must be signed into your Sharebee account to create a listing</label>
    </div>
    <div class="button-container ms-fix-container align_center margin--bottom">
      <bee-btn type="secondary" (click)="loginUser()">Login</bee-btn>
      <bee-btn type="primary" (click)="signUpUser()">Sign up</bee-btn>
    </div>
    <p class="close-button" (click)="close()">close</p>
  </section>
</popup-layout>
<!--</app-header-footer-layout>-->
