import {Component} from "@angular/core";
import {ApiService} from "../../user-auth/shared/api.service";
import {ToastrService} from "ngx-toastr";
import {NgxSpinnerService} from "ngx-spinner";
import {Router} from "@angular/router";
import {AppRoutePaths} from "@core/constants/route.path";

@Component({
  selector: "app-auth-user-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./../auth-common.component.scss"]
})
export class AuthForgotPasswordComponent {
  email: string;

  constructor(private apiService: ApiService,
                private toastrService: ToastrService,
                private router: Router,
                private spinner: NgxSpinnerService) {
  }

  submitForm() {
    this.spinner.show();
    const params: { email: string } = {email: this.email};
    this.apiService.sendResetPasswordLink(params)
      .subscribe(
        () => this._sendLinkSuccess(),
        (error) => this._sendLinkError(error)
      );
  }

  private _sendLinkSuccess() {
    this.spinner.hide();
    this.toastrService.success("We have sent you a reset link, please check your inbox", "");
    this.router.navigate(["users/auth/password-recovery-notification"]);
  }

  private _sendLinkError(error: any) {
    this.spinner.hide();
    if (error.error === "USER_NOT_FOUND") {
      this.toastrService.error("Error", "Sorry your email address cannot be found");
    } else {
      this.toastrService.error("Error", "Something went wrong");
    }
  }

  loginPage() {
    this.router.navigate([AppRoutePaths.LOGIN_USER]);
  }
}
