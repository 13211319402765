<app-hive-container>
  <app-hive-header
    title="Add items to hive"
    [border]="false"
  ></app-hive-header>
  <div class="item-table-container">
    <table class="item-list-table">
      <thead>
      <tr>
        <td>Item</td>
        <td>Rate/1 Day</td>
        <td>Rate/1 Week</td>
        <td>Rate/1 Month</td>
        <td>Action</td>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of myItems">
        <td>
          <div class="item-name">
            <img [src]="item.images[0]"/>
            <div class="item-label">{{item.title}}</div>
          </div>

        </td>
        <td><input type="number" [(ngModel)]="item.prices.rate_per_day" (focus)="ratePressed(item)" class="input-field rounded-lg number_right"></td>
        <td><input type="number" [(ngModel)]="item.prices.rate_per_week" (focus)="ratePressed(item)" class="input-field rounded-lg number_right"></td>
        <td><input type="number" [(ngModel)]="item.prices.rate_per_month" (focus)="ratePressed(item)" class="input-field rounded-lg number_right"></td>
        <td>
          <bee-btn type="primary" *ngIf="item.isAdded"
                    (click)="addAndRemove(item)">
            {{ activeItem ===  item.title ? buttonText[2] : buttonText[0] }}
          </bee-btn>

          <bee-btn type="secondary" *ngIf="!item.isAdded"
                    (click)="addAndRemove(item)">
            {{ buttonText[1] }}
          </bee-btn>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</app-hive-container>

<popup-layout *ngIf="isApproved">
  <div class="hive_pending__popup align_center">
    <div class="label sm-1 make-it-black mt-1 align_center">
      Please give us a moment, we’re just waiting for <span class="primary_text"> {{hiveDetails?.owner_details?.name}} </span> to accept your membership
    </div>
    <div class="my-stuff__actions mt-1">
      <button (click)="cancelIsApproved()" class="button active">Okay</button>
    </div>
  </div>
</popup-layout>
