<section class="app-choose-duration">
  <div class="duration--title">
    <label class="label md1 text_orange align_center">Choose Duration</label>
  </div>
  <div class="datepicker">
    <owl-date-time-inline selectMode="range"
                          pickerType="calendar"
                          [(ngModel)]="selectedMoment"
                          startAt="new Date()"
    ></owl-date-time-inline>
  </div>
  <p class="calendar-note">* Please click twice to select a single date</p>
  <div class="button-container button-container--centre remove_margin">
    <bee-btn type="secondary" (click)="cancel()">Cancel</bee-btn>
    <bee-btn type="primary" (click)="rangeSelected()">Next</bee-btn>
  </div>
</section>
