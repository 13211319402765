<app-popup-lib #popup>
  <div model-content>
    <div class="app-pop-up-layout-container" (click)="close()">
      <div class="box">
        <div class="logo">
          <img alt="alt" src="../../../../../assets/svg/logo_for_popup.svg"/>
        </div>
        <div>
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </div>
</app-popup-lib>
