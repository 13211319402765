/**
 * @author Atikur Rahman
 */

import {ModuleWithProviders, NgModule} from "@angular/core";
import { UploaderComponent } from "./uploader.component";
import {HttpClientModule} from "@angular/common/http";
import {FileViewerComponent} from "./file-viewer/file-viewer.component";
import {CommonModule} from "@angular/common";
import {SafeUrlPipe} from "./file-viewer/safe-url.pipe";
import {ImageCropperModule} from "ngx-image-cropper";
import {FileCropperComponent} from "./file-cropper/file-cropper.component";

export { UploaderComponent, FileViewerComponent, FileCropperComponent, SafeUrlPipe };

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    ImageCropperModule
  ],
  declarations: [
    UploaderComponent,
    FileViewerComponent,
    FileCropperComponent,
    SafeUrlPipe
  ],
  exports: [UploaderComponent,
    FileCropperComponent]
})
export class UploaderModule {
  public static forRoot(): ModuleWithProviders<UploaderModule> {
    return {
      ngModule: UploaderModule
      , providers: []
    };
  }
}
