<app-header-footer-layout>
  <div class="app-contact-us">

    <section class="contact-us_left-container">
      <app-white-box-with-logo-layout boxTitle="{{ 'CONTACT_US.SUBMIT_QUERY_TITLE' | translate}}">

        <app-dynamic-form [dataObject]="loginFormBuilder"
                          (formSubmit)="submitForm($event)"
                          id="ngForm" #dynamic_form></app-dynamic-form>

        <form id="ngForm">
          <input type="text" name="name" placeholder="Your name" />
          <input type="email" name="name" placeholder="Your name" />
          <textarea></textarea>
        </form>
        <div class="button-wrapper">
          <bee-btn type="primary" (click)="dynamic_form.onSubmit()">Send</bee-btn>
        </div>

      </app-white-box-with-logo-layout>
    </section>

    <section class="contact-us_right-container">
      <div class="content-box">

        <p class="content-box_title title">
          {{ 'CONTACT_US.CONTACT_US_TITLE' | translate}}
        </p>

        <div class="content-box_details" *ngFor="let item of contactInfo">
          <label class="label">{{item.label}}</label>
          <a class="info" [href]="mailToLink(item.key)">{{item.key}}</a>
        </div>

      </div>
    </section>
  </div>
</app-header-footer-layout>

<popup-layout *ngIf="showSuccessPopUp">
  <div class="item-booking__popup">
    <label class="label md1 bold-font popup-title">Submit Query</label>
    <div class="popup-contents">Your query has been submitted</div>
    <div class="popup-actions">
      <bee-btn type="primary" (click)="closePopUp()">close</bee-btn>
    </div>
  </div>
</popup-layout>
