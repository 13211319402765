<div class="file-cropper">
  <image-cropper class="image-viewer"
                  [imageChangedEvent]="configs.event"
                  [maintainAspectRatio]="true"
                  [aspectRatio]="1"
                  [resizeToWidth]="420"
                  format="png"
                  (imageCropped)="imageCropped($event)"
                  (imageLoaded)="imageLoaded()"
                  (loadImageFailed)="loadImageFailed()">
  </image-cropper>
  <button class="done-btn" (click)="done()">Done</button>
</div>
