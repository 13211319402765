import {Component, EventEmitter, Input, Output, ViewEncapsulation} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: "app-hive-header",
  templateUrl: "./hive-header.component.html",
  styleUrls: [ "./hive-header.component.scss" ]
})
export class HiveHeaderComponent {
  @Input() title = "";
  @Input() border = true;

  @Input() primaryActionLabel: string;
  @Input() secondaryActionLabel: string;
  @Output() primaryAction = new EventEmitter();
  @Output() secondaryAction = new EventEmitter();

  constructor(public translate: TranslateService) {}
}
