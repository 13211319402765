<app-popup #popup>
  <div model-content>
    <div class="app-pop-up-layout-container" (click)="close()" [ngClass]="{'app-pop-up-layout-container--tall': tooTall}">
      <div class="box" #content>
        <!--<div class="logo"></div>-->
        <div *ngIf="showLogo" class="logo">
          <img [src]="imageService.SHAREBEE_LOGO"/>
        </div>
        <div>
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </div>
</app-popup>
