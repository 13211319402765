import {Component} from "@angular/core";
import {Router} from "@angular/router";

@Component({
  selector: "app-search-area",
  templateUrl: "./search-area.html",
  styleUrls: ["./search-area.scss"]
})
export class SearchAreaComponent {

  constructor(public router: Router) {
  }

  /** Get search query from app search item component
   * @param1 query
   */
  getSearchQuery(query) {
    this.router.navigate(["find-something"], {queryParams: {query: query}});
  }
}
