export class HiveUtil {
  static hiveItemToListItem(hive) {
    return {
      title: hive.name,
      subtitle: `${hive.bees.length} bees in the hive`,
      ...hive
    };
  }

  static userToListItem(hive) {
    return {
      title: hive.name,
      // subtitle: `${hive.bees.length} bees in the hive`,
      ...hive
    };
  }
}
