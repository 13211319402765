import {Component, EventEmitter, Input, OnChanges, Output, ViewEncapsulation} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {EndpointsService} from "../../shared/constants/endpoints.service";
import {NgxSpinnerService} from "ngx-spinner";
import {ToastrService} from "ngx-toastr";
import { checkUserLoggedIn } from "src/app/utils/backend-jwt";

@Component({
  selector: "app-create-hive-input",
  templateUrl: "./create-hive-input.component.html",
  styleUrls: ["./create-hive-input.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class CreateHiveInputComponent implements OnChanges {

  hiveList = [];
  hiveMembers = [];
  @Input() buttonTitle = "Create";
  @Input() isReadOnly = true;
  @Input() hive: { name: string, hiveImagePath: string, description };
  @Output() hiveContent = new EventEmitter();
  imagePath;
  name;
  description;
  /*@Input() name;
  @Input() hiveDescription;
  @Output() nameChange = new EventEmitter();
  @Output() uploadedHivePath = new EventEmitter();*/
  hiveImagePath = "";
  isFileUploaded?: boolean;
  uploadImage = "";
  uploadedImagePath = "";
  isUserLoggedIn: boolean;

  // constructor() {
  //   this.hive.name ? name = this.hive.name;
  // }

  get hiveListTitle() {
    return `My Hives (${this.hiveList.length})`;
  }

  get memberListTitle() {
    return `my memberships (${this.hiveMembers.length})`;
  }

  constructor(public translate: TranslateService,
              private toastrService: ToastrService,
              private spinner: NgxSpinnerService,
              public endpoints: EndpointsService) {
    this.isUserLoggedIn = checkUserLoggedIn();
    this.uploadImage = this.endpoints.IMAGE_UPLOAD;
    console.warn(this.hive);
  }

  ngOnChanges() {
    if (this.hive !== undefined) {
      this.name = this.hive.name;
      this.imagePath = this.hive.hiveImagePath;
      this.description = this.hive.description;
    } else {
      this.hive = {name: "", hiveImagePath: "", description: ""};
    }
  }

  imageUploading(obj: any) {
    this.spinner.show();
  }

  /**
   * Once file is uploaded save the path of image to itemObject array
   * @param1 model
   * @param2 pos
   */
  onFileUploadDone(model, pos) {
    this.spinner.hide();
    model.value = model.data.file.file.path;
    this.uploadedImagePath = model.data.file.file.path;
    this.imagePath = this.uploadedImagePath;
    // this.hive.hiveImagePath = this.uploadedImagePath;
    // this.uploadedHivePath.emit(this.uploadedImagePath);
  }

  uploadedHivePath() {
    this.hiveContent.emit(this.hive);
  }

  /*nameChanged(name) {
    // console.warn(name);
    this.nameChange.emit(name);
  }*/

  safeCall(event: any): void {
    this.spinner.hide();
    this.toastrService.error("Error");
  }

  updateHive() {
    console.warn(this.name);
    console.warn(this.imagePath);
    console.warn(this.description);
    if (this.name !== undefined || this.description !== undefined || this.imagePath !== undefined) {
      this.hive["name"] = this.name;
      this.hive["hiveImagePath"] = this.imagePath;
      this.hive["description"] = this.description;
      this.hiveContent.emit(this.hive);
    } else {
      this.isUserLoggedIn ? this.toastrService.error("Please update all fields") : this.hiveContent.emit(this.hive);
    }
  }
}
