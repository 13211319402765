import {Component, Inject, OnInit} from "@angular/core";
import {DomainResource} from "../../user-auth/configs/config.helper";
import {Role} from "../../user-auth/shared/user.services";
import {ItemObject} from "../../shared/models/item.model";
import {EndpointsService} from "../../shared/constants/endpoints.service";
import {DOCUMENT} from "@angular/common";
import {ActivatedRoute, Router} from "@angular/router";
import {ItemApiService} from "../../items/item-api.service";
import {ImagePathService} from "../../shared/constants/image-path.service";
import {PaymentService} from "../../service/payment.service";
import {NgxSpinnerService} from "ngx-spinner";
import {ToastrService} from "ngx-toastr";

@DomainResource({
  name: "ACCOUNT_VERIFICATION",
  access: [Role.USER, Role.ADMIN]
})
@Component({
  selector: "app-account-verification",
  templateUrl: "./account-verification.component.html",
  styleUrls: ["account-verification.component.scss"]
})

export class AccountVerificationComponent implements OnInit {
  uploadImage = ""; // 'http://localhost:8080/items/image/upload';
  itemObject: any;
  isFileUploaded?: boolean;
  stripe_link: string;
  client_code = "";
  stripeSetUpOrNot: boolean;
  accountDetails: any = {};

  accountVerified: boolean;
  howToSetUpStripeFlag: boolean;
  private code: any;
  private paymentStatus: any;
  private state: any;

  constructor(public endPoint: EndpointsService,
              public imageService: ImagePathService,
              @Inject(DOCUMENT) private document: any,
              private paymentService: PaymentService,
              private activatedRoute: ActivatedRoute,
              private _router: Router,
              private toast: ToastrService,
              private spinner: NgxSpinnerService,
              public apiService: ItemApiService,
              private activated_router: ActivatedRoute) {
    this.stripe_link = this.endPoint.STRIPE_API + "?response_type=code&client_id=" + this.endPoint.STRIPE_CLIENT_ID + "&scope=read_write";
    this.activatedRoute.queryParams.subscribe(params => {
      const stripeCode = params["code"];
      this.code = params.code;
      this.paymentStatus = params.payment;
      this.state = params.state;
      if (this.code !== undefined && this.state !== undefined) {
        // this.updateStripeDetails(stripeCode);
        this.verifyAccount();
      }
    });

    this.uploadImage = this.endPoint.IMAGE_UPLOAD;
    // this.getItemsUploadedByMe();
    this.getAccountDetails();
  }

  ngOnInit() {
    this.activated_router.queryParams.subscribe((query) => {
      if (query) {
        this.howToSetUpStripeFlag = query.howToSetUpStripeFlag;
      }
    });
  }

  // Once file is uploaded save the path of image to itemObject array
  onFileUploadDone(model) {
    model.value = model.data.file.file.path;
    this.itemObject = model.data.file.file.path;

    // calling account verification image update api

    this.apiService.imageUpdateAccountVerification(this.itemObject)
      .subscribe(resp => {
        return undefined;
      }, err => {
        return undefined;
      }
      );

  }

  /* get accoount details */
  getAccountDetails() {
    this.apiService.getAccountDetails()
      .subscribe(resp => {
        if (resp) {
          this.accountDetails = resp.data[0];
          this.itemObject = this.accountDetails ? this.accountDetails.image_id : "";
        }
      },
      error => {
        console.error("error is", error);
      });
  }

  safeCall(event: any): void {
    return undefined;
  }

  updateStripeDetails(stripeCode: string) {
    const packageBody = {
      data: {
        code: stripeCode
      }
    };

    this.apiService.setupStripe(packageBody)
      .subscribe(
        (result: any) => {
          this._router.navigate(["/items"]);
        },
        ({error}) => {
          console.error(error, "Error");
        }
      );
  }

  setupStripe() {
    // this._router.navigate(['/items']);
    this.stripe_link = this.endPoint.STRIPE_API + "?response_type=code&client_id=" + this.endPoint.STRIPE_CLIENT_ID + "&scope=read_write";
    // window.location.replace(this.stripe_link);
    window.open(this.stripe_link);
    // window.location = this.stripe_link;
    // console.warn(this.endPoint.STRIPE_API+'?response_type=code&client_id='+this.endPoint.STRIPE_CLIENT_ID+'&scope=read_write')
  }

  /**
   * Check if stripe account is set up or not
   */
  public getItemsUploadedByMe() {
    this.apiService.getItemsUploadedByMe(0, 0)
      .subscribe(() => {
        this.stripeSetUpOrNot = true;
      },
      () => {
        this.stripeSetUpOrNot = false;
      });
  }

  public verified() {
    return undefined;
  }

  public howToSetUpStripe() {
    this.howToSetUpStripeFlag = true;

  }

  setupStripeV3() {
    this.paymentService.setupAccount().subscribe((data) => {
      window.open(data.data, "_blank");
    });
  }

  verifyAccount(): void {
    console.warn("verify account");
    this.spinner.show();
    this.paymentService.verifyAccount(this.code, this.state).subscribe((data) => {
      this.spinner.hide();
      // this.merchantAccountMessage = 'Merchant account created Successful';
      this.toast.success("Merchant account created Successful");
      // this.userObj.hasStripeAccount = true;
      this._router.navigate(["/items"]);
    }, error => {
      console.error("error", error);
      // this.openModal(this.stripeSetupNotifyModal);
      this.toast.error("Failed to create Merchant account");
      // this.merchantAccountMessage = 'Failed to create Merchant account';
    });
  }
}
