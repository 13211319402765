import {Injectable} from "@angular/core";
//TODO
@Injectable()
export class ValidatorService {
  public minimumLength = 8;
  public EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  public commonPasswordPatterns = /passw.*|12345.*|09876.*|qwert.*|asdfg.*|zxcvb.*|footb.*|baseb.*|drago.*/;
  public validatorEmailResponse = {};
  public validatorPasswordResponse = {};
  public validatorNameResponse = {};

  constructor() {

  }

  public validateName(name) {
    if (name === "") {
      this.validatorNameResponse["message"] = "Name Cannot be empty";
      this.validatorNameResponse["flag"] = false;
      return this.validatorNameResponse;
    } else {
      this.validatorNameResponse["message"] = "Success";
      this.validatorNameResponse["flag"] = true;
      return this.validatorNameResponse;
    }
  }

  public validateEmail(email) {
    if (email === "") {
      this.validatorEmailResponse["message"] = "Email Id cannot be empty";
      this.validatorEmailResponse["flag"] = false;
      return this.validatorEmailResponse;
    } else if (!this.EMAIL_REGEXP.test(email)) {
      this.validatorEmailResponse["message"] = "Invalid Email Id";
      this.validatorEmailResponse["flag"] = false;
      return this.validatorEmailResponse;
    } else {
      this.validatorEmailResponse["message"] = "Success";
      this.validatorEmailResponse["flag"] = true;
      return this.validatorEmailResponse;
    }
  }

  public validatePassword(password) {
    let numberOfElements = 0;
    numberOfElements = /.*[a-z].*/.test(password) ? ++numberOfElements : numberOfElements;      // Lowercase letters
    numberOfElements = /.*[A-Z].*/.test(password) ? ++numberOfElements : numberOfElements;      // Uppercase letters
    numberOfElements = /.*[0-9].*/.test(password) ? ++numberOfElements : numberOfElements;      // Numbers
    numberOfElements = /[^a-zA-Z0-9]/.test(password) ? ++numberOfElements : numberOfElements;   // Special characters (inc. space)
    // Assume we have a poor password already
    let currentPasswordStrength = "Short";

    // Check then strenth of this password using some simple rules
    if (password === "" || password.length < this.minimumLength) {
      this.validatorPasswordResponse["message"] = "Password is too short";
      this.validatorPasswordResponse["flag"] = false;
    } else if (this.isPasswordCommon(password) === true) {
      this.validatorPasswordResponse["message"] = "Don't use common password";
      this.validatorPasswordResponse["flag"] = false;
    } else if (numberOfElements === 0 || numberOfElements === 1 || numberOfElements === 2) {
      this.validatorPasswordResponse["message"] = "Password is weak";
      this.validatorPasswordResponse["flag"] = false;
    } else if (numberOfElements === 3) {
      currentPasswordStrength = "Ok";
      this.validatorPasswordResponse["message"] = "Password is Ok";
      this.validatorPasswordResponse["flag"] = true;
    } else {
      currentPasswordStrength = "Strong";
      this.validatorPasswordResponse["message"] = "Password is Strong";
      this.validatorPasswordResponse["flag"] = true;
    }

    // Return the strength of this password
    return this.validatorPasswordResponse;
  }

  //
  // Checks if the given password matches a set of common password
  //
  public isPasswordCommon(password) {
    return this.commonPasswordPatterns.test(password);
  }
}
