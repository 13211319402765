import {Component} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: "app-how-it-works",
  templateUrl: "./how-it-works.component.html",
  styleUrls: ["how-it-works.components.scss"]
})

export class HowItWorksComonent {
  showHideLendBorrow = false;
  showHideLendBorrowText = false;

  constructor(public translate: TranslateService) {

  }

  switchView() {
    this.showHideLendBorrow = !this.showHideLendBorrow;
  }

}
