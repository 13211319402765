<app-hive-container [sidebar]="true" [myHiveMenu]="true">
  <section class="app-my-hive">
    <app-hive-list
      [title]="hiveListTitle"
      [items]="myHives"
      emptyMessage="You are not managing a hive. Feel free to start a new one"
      [primaryActionLabel]="'Start a hive'"
      (primaryAction)="create()"
      (secondaryAction)="manage()"
      isOwner="true"
      itemPrimaryActionLabel="View"
      (itemPrimaryAction)="view($event)"
      itemSecondaryActionLabel="edit"
      (itemSecondaryAction)="members($event)"
    ></app-hive-list>
    <app-hive-list
      [title]="memberListTitle"
      [items]="joinedHives"
      itemPrimaryActionLabel="View"
      (itemPrimaryAction)="view($event)"
      itemSecondaryActionLabel="Leave"
      (itemSecondaryAction)="leave($event)"
      emptyMessage="You are not yet member of a hive"
    ></app-hive-list>
  </section>
</app-hive-container>
