<section class="app-white-box-with-logo-layout">
  <div class="logo_box">
    <a href="/">
      <img alt="Sharebee logo" class="logo_image" [src]="imageService.SHAREBEE_LOGO"/>
    </a>
  </div>
  <div class="align_center">
    <label class="white-box-with-logo-layout_title">
      {{boxTitle}}
    </label>
  </div>
  <div class="box-content">
    <ng-content></ng-content>
  </div>
</section>
