import { library } from "@fortawesome/fontawesome-svg-core";
import { faFacebookF, faTwitter, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { faMinus, faEllipsisH, faLink, faPrint, faEnvelope } from "@fortawesome/free-solid-svg-icons";


const icons = [
  faFacebookF, faTwitter, faLinkedinIn,
  faEnvelope, faPrint, faLink, faEllipsisH, faMinus
];

library.add(...icons);
