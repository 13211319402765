import {Component, OnInit, Input, ViewEncapsulation} from "@angular/core";
import {FormGroup, FormControl} from "@angular/forms";
import {DynamicFormInputInterface} from "./dynamic-form-interface";
import {NgxSpinnerService} from "ngx-spinner";

@Component({
  selector: "app-dynamic-input",
  templateUrl: "./dynamic-input.component.html",
  styleUrls: ["./dynamic-form.scss"],
  encapsulation: ViewEncapsulation.None
})
export class DynamicInputComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() prop: DynamicFormInputInterface;
  focus: boolean;

  constructor(private spinner: NgxSpinnerService) {

  }

  ngOnInit() {
    return undefined;
  }

  /**
   * Function to check if form is valid
   * returns {boolean}
   */
  get isValid(): boolean {
    return this.form.controls[this.prop.key].valid;
  }

  /**
   * Function to specify validation error message
   * returns {string}
   */
  get errorMessage(): string {
    if (this.prop.disabled) { return ""; }

    if (this.form.get(this.prop.key)
      .hasError("required")) {
      return this.prop.validators.required.err_msg;
    }

    if (this.form.get(this.prop.key)
      .hasError("email")) {
      return this.prop.validators.email.err_msg;
    }

    if (this.form.get(this.prop.key)
      .hasError("minlength")) {
      return this.prop.validators.minLength.err_msg;
    }

    if (this.form.get(this.prop.key)
      .hasError("maxlength")) {
      return this.prop.validators.maxLength.err_msg;
    }

    if (this.form.get(this.prop.key)
      .hasError("pattern")) {
      return this.prop.pattern.err_msg;
    }

    return "Custom validation error occurred";
  }


  imageUploading(obj: any) {
    this.spinner.show();
  }

  safeCall(fn: any, event: any): void {
    fn && fn(event);
  }

  constructClasses() {
    return {
      [this.prop.key + "--" + this.prop.type + "--dynamic-input"]: 1
    };
  }

  onFileUploadDone(model, res) {
    this.spinner.hide();
    model.value = res.data.file.file.path;
    (this.form.controls[this.prop.key] as FormControl).setValue(model.value);
  }

  onFocus() {
    this.focus = true;

  }

  outOfFocus() {
    this.focus = false;
  }
}
