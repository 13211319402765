import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";

@Pipe({
  name: "TimePipe"
})
export class TimePipe implements PipeTransform {

  transform(v) {
    return moment(v).format("Do MMMM, YY");
  }
}
