import {Component, ViewEncapsulation} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {DomainResource} from "../../user-auth/configs/config.helper";
import {Role} from "../../user-auth/shared/user.services";
import {HiveApiService} from "../hive-api.service";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {AppRoutePaths} from "@core/constants/route.path";
import { checkUserLoggedIn } from "src/app/utils/backend-jwt";

@DomainResource({
  name: "CREATE_HIVE",
  access: [Role.ADMIN, Role.USER]
})
@Component({
  selector: "app-create-hive",
  templateUrl: "./create-hive.component.html",
  styleUrls: ["./create-hive.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class CreateHiveComponent {

  name;
  hiveName = "";
  imagePath;
  hive: any;
  isUserLoggedIn = false;
  showHiveLogin = false;

  constructor(public translate: TranslateService,
              private api: HiveApiService,
              private router: Router,
              private toast: ToastrService) {
    this.isUserLoggedIn = checkUserLoggedIn();
  }

  create(hiveObj) {
    if (this.isUserLoggedIn) {
      if (hiveObj.name && hiveObj.description) {
        this.api.createHive({
          name: hiveObj.name,
          hiveImagePath: hiveObj.hiveImagePath,
          description: hiveObj.description
        })
          .subscribe(({data}) => {
            this.toast.success("Hive created");
            this.hive = data;
            this.router.navigate([`/users/my-hive/${this.hive._id}/members`]);
          }, (error) => {
            this.toast.error("Unable to created hive");
          });
      } else {
        this.toast.warning("Hive name & description are required");
      }
    } else {
      this.showHiveLogin = true;
    }
  }

  back() {
    this.router.navigate(["/users/my-hive"]);
  }

  loginUser() {
    this.router.navigate([AppRoutePaths.LOGIN_USER]);
  }

  signUpUser() {
    this.router.navigate([AppRoutePaths.HOME_GUEST_REGISTER]);
  }
}
