import {HttpClient} from "@angular/common/http";
import {EndpointsService} from "../shared/constants/endpoints.service";
import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {map} from "rxjs/operators";
import {AppHttpService} from "@core/http.service";

@Injectable({
  providedIn: "root"
})
export class PaymentService extends AppHttpService {
  constructor(public http: HttpClient,
              private endpointsService: EndpointsService) {
    super(http);
  }

  setupAccount(): Observable<any> {
    return this.get(this.endpointsService.STRIPE_ACCOUNT_SETUP);
    /*return this.http.get<any>(this.endpointsService.STRIPE_ACCOUNT_SETUP)
      .pipe(map((result) => {
        // const profileObject = result.data;
        return result.data;
      }));*/
  }

  verifyAccount(code: string, state: string): Observable<any> {
    return this.get(this.endpointsService.VERIFY_ACCOUNT + "?code=" + code + "&state=" + state);
    /*return this.http.get<any>(this.endpointsService.VERIFY_ACCOUNT + '?code=' + code + '&state=' + state)
      .pipe(map((result) => {
        // const profileObject = result.data;
        return result.data;
      }));*/
  }
}
