<section class="reset-password-page full-page" *ngIf="contents">
  <image-form
    #reset_form
    [imagePath]="'assets/img/logo.png'"
    [title]="contents?.title" [formFields]="resetPasswordFormBuilder"
    [buttonLabel]="contents?.button_submit"
    [back_button_label]="contents?.button_cancel"
    (click)="navigateToTC($event)"
    (formSubmitData)="resetPassword($event)">
    <ng-content></ng-content>
  </image-form>
</section>

<app-popup-layout *ngIf="displayTermsCondition">
  <my-app-terms-rules-condition #app_terms_condition>
    <div class="button-container align_center margin--bottom">
      <button class="button" (click)="displayTermsCondition = false">{{'CLOSE' | translate}}</button>
    </div>
  </my-app-terms-rules-condition>
</app-popup-layout>
