
    <ngx-spinner
      bdColor="rgba(51, 51, 51, 0.8)"
      size="medium"
      color="#fff"
      type="ball-pulse">
    </ngx-spinner>

    <section class="activation-success">
      <h1>Your account activated successfully</h1>
    </section>
  