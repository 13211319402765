import {Component, Input, OnInit} from "@angular/core";
import {FormGroup} from "@angular/forms";
import {DynamicFormInputInterface} from "./dynamic-form-interface";

@Component({
  selector: "app-dynamic-custom-input",
  templateUrl: "./dynamic-custom-input.component.html",
  styleUrls: [
    "dynamic-custom-input.component.scss"
  ]
})

export class DynamicCustomInputComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() start: "row" | "column";
  @Input() props: DynamicFormInputInterface[];
  formConfig: any[];

  ngOnInit(): void {
    this.formConfig = this.getProperty(this.props);
  }

  getProperty(props: any[]): any {
    if (Array.isArray(props)) {
      for (let i = 0; i < props.length; i++) {
        if (Array.isArray(props[i])) {
          props[i] = this.getProperty(props[i]);
        } else {
          props[i] = this.getProperty(props[i]);
        }
      }
    }
    return props;
  }

  isArray(props: any): boolean {
    return Array.isArray(props);
  }
}
