import {Component, EventEmitter, Input, Output, ViewEncapsulation} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: "app-hive-list",
  templateUrl: "./hive-list.component.html",
  styleUrls: [ "./hive-list.component.scss" ],
  encapsulation: ViewEncapsulation.None
})
export class HiveListComponent {

  @Input() isOwner = false;
  @Input() emptyMessage = "";
  @Input() title = "";
  @Input() items: {
    title: string,
    subtitle: string,
    image: string,
    hiveImagePath: string
  }[];

  @Input() primaryActionLabel: string;
  @Input() secondaryActionLabel: string;
  @Output() primaryAction = new EventEmitter();
  @Output() secondaryAction = new EventEmitter();

  @Input() itemPrimaryActionLabel: string;
  @Input() itemSecondaryActionLabel: string;
  @Output() itemPrimaryAction = new EventEmitter();
  @Output() itemSecondaryAction = new EventEmitter();

  constructor(public translate: TranslateService) {}
}
