import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DomainResource } from "../../../app/user-auth/configs/config.helper";
import { Role } from "../../../app/user-auth/shared/user.services";
import { EndpointsService } from "../../shared/constants/endpoints.service";
import { ApiService } from "../../../app/user-auth/shared/api.service";
import { Router } from "@angular/router";
import { Meta } from "@angular/platform-browser";
import { checkUserLoggedIn } from "src/app/utils/backend-jwt";

@DomainResource({
  name: "USER_HOME_PAGE",
  access: [Role.ADMIN, Role.USER],
})
@Component({
  selector: "app-home-page",
  templateUrl: "./home-page.component.html",
  styleUrls: ["home-page.component.scss"],
})
export class HomePageComponent implements OnInit {
  @Input() displayHeader = true;
  @Output() redirectToSignUp = new EventEmitter<Object>();
  findURL: string;
  sharedItems: any = [];
  isUserLoggedIn = false;
  sipInfo = false;
  organization = "join these organisations and build a hive today";
  membersSay = "what our members say";
  memberDesc = [
    {
      desc: "Once I got over the initial awkwardness of asking it became obvious that everyone in my hive is joined by the same intentions, sharing and being kind. The whole process was slick from viewing, paying and right through to returning all the mountaineering equipment I enjoyed that weekend.",
      user: "Mr Hyde-Hart",
      role: "Technical Manager",
    },
    {
      desc: "I try to buy 2nd hand because I know manufacturing equals pollution. But I don’t have the space in my small flat to store things I only use a handful of times in a year. Sharebee is brilliant because I can borrow for a fraction of the price and then send the item back when I’m done so it’s not cluttering up my space!.",
      user: "Emma Bayfield",
      role: "Designer",
    },
    {
      desc: "I’ve offered a lot of my tools and gardening equipment on and lent out several of them – including my cordless lawn mower and my jet wash.The same person has borrowed my lawn mower 3 times. Everything has been returned in good condition and the payments will contribute to when I need to replace something. It's easy to use and free to join.",
      user: "Alan",
      role: "Retired Teacher",
    },
  ];

  constructor(
    public endPoints: EndpointsService,
    private http: ApiService,
    public meta: Meta,
    private router: Router
  ) {
    this.meta.addTag({
      name: "description",
      content:
        "Sharebee makes sharing easy - connecting people who want to lend with people who want to borrow.",
    });
    this.meta.addTag({ name: "author", content: "Sharebee" });
    this.meta.addTag({ name: "keywords", content: "Sharing, Sharebee" });
    this.findURL = this.endPoints.SEARCH_ITEM;
    this.findURL = `${this.findURL}/1/8?undefined`;

    this.isUserLoggedIn = checkUserLoggedIn();
  }

  navigateToSignUp() {
    console.warn("navigateToSignUp");
    this.redirectToSignUp.emit("");
  }

  ngOnInit() {
    this.onPageLoad();
  }

  /**
   * Functions to call on page load
   */
  onPageLoad() {
    this.getSharedItems();
  }

  /**
   * Get shared items
   */
  getSharedItems() {
    return Promise.resolve()
      .then(() => {
        return this.http.get(this.endPoints.LIST_CATEGORIES).toPromise();
      })
      .then((res: any): any => {
        if (res) {
          this.sharedItems = res;
          return res;
        } else {
          // throw res.error;
        }
      });
  }

  /** Get search query from app search item component
   * @param1 query
   */
  getSearchQuery(query) {
    this.router.navigate(["find-something"], {
      queryParams: { query: query },
    });
  }
}
