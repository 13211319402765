import { config } from "@app/config";
import { Role } from "../usermodule/src/public_api";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";

export type CustomJTWPayload = {
    sub: string;
    session: string;
    role: Role,
    email: string;
    mapHiveId?: string;
    profileUpdated: boolean;
    hasHives: boolean;
}

export const parseJWT = (value: string): CustomJTWPayload|null => {
  if(!value) return null;
  return jwtDecode<CustomJTWPayload>(value, { header: true});
};

export const parseJWTCookie = () => parseJWT(Cookies.get(config.AUTH_COOKIE_KEY));
export const checkUserLoggedIn = () => !!Cookies.get(config.AUTH_COOKIE_KEY);
