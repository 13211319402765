import {Component, EventEmitter, Output, Input} from "@angular/core";

@Component({
  selector: "bee-btn",
  templateUrl: "./bee-btn.component.html",
  styleUrls: [
    "bee-btn.component.scss"
  ]
})

export class BeeBtnComponent {
  @Output() click: EventEmitter<any> = new EventEmitter();
  @Input() type: string; // todo: enum
  @Input() disabled: boolean;
  @Input() size: string;
  @Input() block: boolean;
  @Input() testId: string;

  btnClass() {
    let btnClass = this.size ? `bee-btn--${this.size}` : "";
    btnClass = btnClass + (this.type ? ` bee-btn__${this.type}` : "");
    btnClass = btnClass + (this.block ? " bee-btn--block" : "");
    return btnClass;
  }

  doClick(event: Event): void {
    event.stopImmediatePropagation();
    this.click.emit(event);
  }
}
