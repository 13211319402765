import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import {Observable, Observer, Subject, Subscription} from "rxjs";
import Cookies from "js-cookie";
import { config } from "@app/config";
/**
 * This service is a wrapper for the http client,
 * so that later we can intercept any request of the app
 */

@Injectable()
/**
 * @deprecated replace this with Axios and interceptors
 * Also consider https://www.npmjs.com/package/axios-retry
 */
export class AppHttpService {
  public static EVENT_ERROR = "EVENT_ERROR";
  private static PERMANENT_HEADERS = "PERMANENT_HEADERS";
  private onEvent = new Subject();

  constructor(protected http: HttpClient) { }

  post(url: string, data: object, options = {}): Observable<any> {
    const headers = this.getHeaders();
    const authCookie = Cookies.get(config.AUTH_COOKIE_KEY);
    if(authCookie) {
      headers["Authorization"] = `bearer ${authCookie}`;
    }
    const httpOptions = Object.assign({
      headers: headers
    }, options);

    return this.interceptor(this.http.post(url, data, httpOptions));
  }

  put(url: string, data: { [key: string]: any }): Observable<any> {
    const headers = this.getHeaders();
    const authCookie = Cookies.get(config.AUTH_COOKIE_KEY);
    if(authCookie) {
      headers["Authorization"] = `bearer ${authCookie}`;
    }
    const httpOptions = {
      headers: headers
    };

    return this.interceptor(this.http.put(url, data, httpOptions));
  }

  get(url: string, parameters: Record<string, string> = {}, options = {}): Observable<any> {
    const headers = this.getHeaders();
    const authCookie = Cookies.get(config.AUTH_COOKIE_KEY);
    if(authCookie) {
      headers["Authorization"] = `bearer ${authCookie}`;
    }

    const httpOptions = Object.assign({
      headers: headers,
      params: parameters
    }, options);
    return this.interceptor(this.http.get(url, httpOptions));
  }

  delete(url: string): Observable<any> {
    const headers = this.getHeaders();
    const authCookie = Cookies.get(config.AUTH_COOKIE_KEY);
    if(authCookie) {
      headers["Authorization"] = `bearer ${authCookie}`;
    }
    const httpOptions = {
      headers: headers
    };

    // return this.http.get(url, http_options);
    return this.interceptor(this.http.delete(url, httpOptions));
  }

  /**
   *
   * @param string name: This is an optional field, if you provide this parameter
   * then it will delete that item only, else it will remove
   * the all saved headers, saved by this class
   *
   * returns Promise<any>
   */
  public removeHeaders(name?: string): Promise<void> {
    let headers = this.getOldHeaders();

    if (name) {
      delete headers[name];
    } else {
      headers = {};
    }

    localStorage.setItem(AppHttpService.PERMANENT_HEADERS, JSON.stringify(headers));

    return Promise.resolve();
  }

  /**
   * Listen to http call events
   *
   * @param cb<any>
   * @returns Subscription:<any>
   */
  public listen(cb: any): Subscription {
    return this.onEvent.subscribe(cb);
  }

  private getOldHeaders(): any {
    const old_headers: { [name: string]: string } = JSON
      .parse(localStorage.getItem(AppHttpService.PERMANENT_HEADERS) || "{}");

    return old_headers;
  }
  private getHeaders(): HttpHeaders {
    // const headers: HttpHeaders = new HttpHeaders();
    const headerItems = this.getOldHeaders();

    return headerItems;
  }

  private interceptor(req: Observable<any>): Observable<any> {

    return req.pipe(
      catchError((error: any) => {
        this.onEvent.next({ type: AppHttpService.EVENT_ERROR, error });

        return Observable.create((observer: Observer<any>) => { observer.error(error); });
      })
    );
  }
}
