import {Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation} from "@angular/core";

@Component({
  selector: "image-form",
  templateUrl: "./image-form-component.html",
  styleUrls: ["./image-form.scss"]
  , encapsulation: ViewEncapsulation.None
})
export class ImageFormComponent implements OnInit {
  @Input() imagePath;
  @Input() title;
  @Input() formFields;
  @Input() buttonLabel;
  @Input() back_button_label;
  @Input() subtitle;
  @Output() formSubmitData: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild("dynamic_form", { static: true}) dynamic_form;

  ngOnInit() {
    return undefined;
  }

  /**
   * Emit form input data on clicking submit button
   * param data
   */
  formSubmit(data: any): void {
    this.formSubmitData.emit(data);
  }

  submit() {
    this.dynamic_form.onSubmit();
  }

  back() {
    if (window.history.length > 0) {
      window.history.back();
    } else {
      window.close();
    }
  }
}
