import { Component } from "@angular/core";
import { DomainResource } from "../../../user-auth/configs/config.helper";
import { Role } from "../../../user-auth/shared/user.services";

import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { AppRoutePaths } from "@core/constants/route.path";

@DomainResource({
  name: "ABOUT_SHAREBEE_DETAILS",
  access: [Role.ADMIN, Role.USER],
})
@Component({
  selector: "app-about-sharebee-details",
  templateUrl: "./about-sharebee-details.component.html",
  styleUrls: ["about-sharebee-details.component.scss"],
})
export class AboutSharebeeDetailsComponent {
  videoUrl = "https://www.youtube.com/embed/hA6hldpSTF8";
  sanitizedURL: SafeResourceUrl;
  sipInfo = false;

  constructor(private _sanitizer: DomSanitizer, private router: Router) {
    this.sanitizedURL = this._sanitizer.bypassSecurityTrustResourceUrl(
      this.videoUrl
    );
  }

  signUpUser() {
    this.router.navigate([AppRoutePaths.HOME_GUEST_REGISTER]);
  }
  goHome() {
    this.router.navigate([AppRoutePaths.HOME]);
  }
}
