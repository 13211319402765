import {Component, ViewEncapsulation} from "@angular/core";

@Component({
  selector: "app-signup",
  templateUrl: "./activated.component.html",
  styleUrls: ["./activated.scss"],
  encapsulation: ViewEncapsulation.None
})
export class ActivatedComponent {
}
