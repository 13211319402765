import {Component, Input} from "@angular/core";
import {UserServices} from "../../user-auth/shared/user.services";
import {Router} from "@angular/router";

@Component({
  selector: "app-side-menu",
  templateUrl: "./side-menu.component.html",
  styleUrls: [
    "side-menu.component.scss"
  ],

})

export class SideMenuComponent {
  @Input() sideMenuList: Array<any>;
  submenuList: any;
  toggle = {};

  disableHeader = true;

  constructor(public userService: UserServices,
              public router: Router) {
  }
}


