<div class="side-menu-component">
  <ul class="menu-list">
    <li class="menu-list_item"
        *ngFor="let item of sideMenuList; let i = index"
        [ngClass]="{'hasSubmenu': item.submenus, 'closeSubmenu': toggle[i]}"
        (click)="toggle[i] = !toggle[i]">

      <a class="menu-list_item_title"
          [routerLink]="disableHeader ? item.url : './'"
          [class.disabled]="disableHeader">
        {{item.title}}{{item.disabled}}
      </a>

      <ul class="submenu-wrapper" *ngIf="item.submenus" [ngClass]="toggle[i] ? 'show': 'hide'">
        <li class="submenu_item menu-list_item"
            [class.disabled]="!disableHeader"
            *ngFor="let submenu of item.submenus"
            [routerLink]="disableHeader ? submenu.url : './'">
          {{submenu.title}}
        </li>
      </ul>
    </li>
  </ul>
</div>
