<ngx-spinner
  bdColor="rgba(51, 51, 51, 0.8)"
  size="medium"
  color="#fff"
  type="ball-pulse">
</ngx-spinner>

<section class="sign-in-page full-page">
  <image-form #invite_form [imagePath]="'assets/img/logo.png'"
              [title]="'Invite user'" [formFields]="loginFormBuilder"
              [buttonLabel]="'Submit'"
              [back_button_label]="'Back'"
              (formSubmitData)="signIn($event)">
    <ng-content></ng-content>
  </image-form>
</section>
