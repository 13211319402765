'<app-header-footer-layout [show_sidebar]="false" [blank]="true" [btn_visible]="'sign_in'">
  <section class="app-auth-user-login">
    <div class="login-container">
      <div class="input-container">
        <h1 class="add-margin-btm-15">password recovery</h1>
        <div class="login-capsule mt-2">
          <label class="input--label">A link has been sent to your registered email address. Please click on the link to
            reset your password.</label>
          <label class="input--label mt-2">You can click on the back button in-case you remember your
            password.</label>
          <div class="btn-container mt-2">
            <bee-btn type="secondary" (click)="navigateToHome()">back</bee-btn>
          </div>
        </div>
      </div>
      <div class="img--container">
        <img alt="login_placeholder_image" class="img-preview" src="../../../assets/svg/login.svg">
      </div>
    </div>
  </section>
</app-header-footer-layout>
