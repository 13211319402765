import { AppRoutePaths } from "@core/constants/route.path";
import {Component, OnInit } from "@angular/core";
import {NgxSpinnerService} from "ngx-spinner";
import {Router} from "@angular/router";
import {ApiService} from "../../../app/user-auth/shared/api.service";
import {ToastrService} from "ngx-toastr";



@Component({
  selector: "app-mail-subscription",
  templateUrl: "./mail-subscription.component.html",
  styleUrls: ["./mail-subscription.component.scss"]
})

export class MailSubscriptionComponent implements OnInit {

  subscribeEmailPopUp: boolean;
  subscriptionResponse: any;
  successfulEmailSubscription: boolean;
  subscriptionFormBuilder = [
    {
      key: "email",
      label: "EMAIL",
      type: "email",
      value: "",
      placeholder: "info@example.com",
      validators: {
        required: {value: true, err_msg: "Email is required"},
        email: {value: true, err_msg: "Enter a valid email address"}
      }
    },
    {

      key: "radio",
      type: "radio",
      label: "",
      options: [
        {
          name: "eligible",
          value: true,
          label: "*I am more than 16 years old "
        },

        {
          name: "eligible",
          value: false,
          label: "*I am a robot"
        },
      ]

    }

  ];

  constructor( private spinner: NgxSpinnerService,
               private _router: Router,
               public apiService: ApiService,
               private toast: ToastrService) {

  }


  ngOnInit() {

  }

  goToSignUp() {
    this._router.navigate([AppRoutePaths.HOME_GUEST_REGISTER]);
  }

  /**
   * show pop up to get subscriber email
   */
  goToSubscriptionPopUp() {
    this.subscribeEmailPopUp = true;
  }

  /**
   * go to landing page
   */
  goBackToLandingPage() {
    this._router.navigate([AppRoutePaths.HOME_GUEST]);
  }

  /**
   * close subscribe email pop up
   */
  subscribe(event) {

    this.spinner.show();

    if (event.radio) {
      this.apiService.emailSubscription(event)
        .subscribe(resp => {
          this.spinner.hide();
          this.subscribeEmailPopUp = false;
          this.successfulEmailSubscription = true;
          this.subscriptionResponse = resp;
          this.toast.success("We will use your email address to send you updates about Sharebee", "");
        },
        error => {
          this.spinner.hide();
          const err = error.error.error;
          this.toast.error(err.message, error.title);
        });
    } else {
      this.spinner.hide();
      this.toast.error("You are not eligible");
    }


  }

  /**
   * Close the subscribe email pop up
   */
  cancel() {
    this.subscribeEmailPopUp = false;
  }

  closeSuccessPopUp() {
    this.successfulEmailSubscription = false;
  }



}


