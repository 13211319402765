import {Component, Input, OnInit, ViewEncapsulation} from "@angular/core";

@Component({
  selector: "lib-file-cropper",
  templateUrl: "./file-cropper.component.html",
  styleUrls: [
    "file-cropper.component.scss"
  ],
  encapsulation: ViewEncapsulation.None
})
export class FileCropperComponent implements OnInit {
  @Input() configs: any;

  croppedImage;
  file_url;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  ngOnInit() {}

  imageCropped(event: any) {
    // this.configs.onDone(event);
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    // show cropper
  }

  loadImageFailed() {
    // show message
  }
  done() {
    if (this.croppedImage) {
      fetch(this.croppedImage)
        .then(res => res.blob())
        .then(blob => {
          const file = new File([blob], "something.png", {type: blob.type});
          this.configs.onDone(file);
        });
    }
  }
}
