import { OrderStatus } from "./../shared/models/booking-status.model";
import { ItemObject } from "../shared/models/item.model";
import {pipe, prop, cond, equals, or} from "ramda";


/**
 * @returns list of the next status options available
 * note, does not cover every status
 * @todo type order
 * @see https://www.lucidchart.com/documents/edit/307bca24-41f4-446d-b562-1c7379d69133/0?shared=true&
 */
export const nextStatusActions: (order: any) => OrderStatus[] = pipe(
  prop("current_status"),
  cond([
    [equals(OrderStatus.pending), () => [OrderStatus.payment_pending, OrderStatus.cancelled]],
    [equals(OrderStatus.payment_done), () => [OrderStatus.owner_deliver, OrderStatus.borrower_pickup]],
    // [or(equals(OrderStatus.owner_deliver), equals(OrderStatus.borrower_pickup)), () => [OrderStatus.borrower_deliver, OrderStatus.owner_pickup]],
    // [or(equals(OrderStatus.owner_pickup), equals(OrderStatus.borrower_deliver)), () => [OrderStatus.done]],
    [(equals(OrderStatus.owner_deliver)), () => [OrderStatus.borrower_deliver, OrderStatus.owner_pickup]],
    [(equals(OrderStatus.borrower_pickup)), () => [OrderStatus.borrower_deliver, OrderStatus.owner_pickup]],
    [(equals(OrderStatus.owner_pickup)), () => [OrderStatus.done]],
    [(equals(OrderStatus.borrower_deliver)), () => [OrderStatus.done]],



  ])
);
