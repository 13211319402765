<app-header-footer-layout>
  <div class="app-my-profile">
    <lib-app-profile (update)="profileUpdated($event)"></lib-app-profile>
  </div>

  <popup-layout *ngIf="profile_updated">
    <div class="profile_update_success__popup">
      <label class="label md1 bold-font popup-title">profile</label>
      <label class="label sm-1 quad provide-padding-bottom_updated popup-contents">{{text_content}}</label>
      TODO: add an indicator to show state of completion of profile
<!--          <button class="bee-btn bee-btn__primary" (click)="close()">{{button_text}}</button>-->
      <bee-btn type="primary" (click)="close()">{{button_text}}</bee-btn>
    </div>
  </popup-layout>
</app-header-footer-layout>
