<section class="forgot-password-page full-page">
  <div class="transparent-layer">
    <image-form #forgot_password_form [imagePath]="'assets/img/logo.png'"
                [title]="'password recovery'" [subtitle]="'enter your registered email address to reset your password'" [formFields]="forgotPasswordFormBuilder"
                [buttonLabel]="'Reset'"
                [back_button_label]="'cancel'"
                (formSubmitData)="sendLink($event)">
      <ng-content></ng-content>
    </image-form>
  </div>
</section>
