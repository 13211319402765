import {Component, Input} from "@angular/core";

// import {TeamSlider} from '../slider';

@Component({
  selector: "app-share-members-say",
  templateUrl: "./members-say.component.html",
  styleUrls: ["./members-say.component.scss"]
})

export class MembersSayComponent {
  @Input() title: string;
  @Input() description: Array<{ desc: string, user: string, role: string }>;
  // public TeamSliderConfig: any = TeamSlider;
}
