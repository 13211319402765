import { Component } from "@angular/core";

@Component({
  selector: "alert-banner",
  styleUrls: ["./alert-banner.component.scss"],
  templateUrl: "./alert-banner.component.html",
})
export class AlertBannerComponent {
  sipInfo = false;
  constructor() {}
}
