import {Injectable} from "@angular/core";
import {UserServices} from "../user.services";
import {AccessControl, Query} from "accesscontrol";
import {RbacConfigService} from "../../configs/rbac.config";



@Injectable()
export class RbacService {
  access_control: AccessControl;

  constructor(private user: UserServices, private config: RbacConfigService) {}

  /**
   * @description
   * Check if user has access to a resource
   * param resource
   */
  canAccess(resource: string): Promise<{ roles: string[], granted: boolean }> {
    return Promise
      .resolve(this.getUserAccess())
      .then((user: Query) => {
        if (user) {
          const response = {
            granted:  user.read(resource).granted,
            roles: user.read(resource).roles
          };
          return response;
        }
        const response = {
          granted: false,
          roles: []
        };
        return response;
      });
  }

  getUserAccess(): Promise<Query> {
    return this.config.getAccessInfo()
      .then((info) => {
        this.access_control = this.access_control || new AccessControl(info);

        return Promise.resolve(this.user.getUser())
          .then((user) => this.access_control.hasRole(user.role) ? this.access_control.can(user.role) : null)
          .catch(() => null);
      });
  }

  getUserRole(): Promise<string> {
    return this.user.getUser().then((user) => {
      return user.role;
    });
  }
}
