<app-header-footer-layout [show_sidebar]="false" [centre]="true" class="find-something-page">
  <div custom_sidebar class="filter-component">
    <app-item-filter (filterByCategory)="filterCategory = $event; itemReload()"></app-item-filter>
  </div>
  <div class="app-find-something">

    <div class="search-component">
      <app-search-item (searchQuery)="searchQuery = $event; itemReload()"></app-search-item>

      <!-- message when there are no items -->
      <div *ngIf="showNoItemsMessage" class="item-list-page_message">
        <p>Uh Oh... No result!</p>
        <p class="message_small">want to
          <bee-btn type="primary" (click)="requestItem()">send a request</bee-btn>
          to the community to find a specific item for you?
        </p>
      </div>

      <div class="list-items--container">
        <div class="item__list-container">
          <div class="list-container">
            <div class="list-items">
              <div class="create-item__tile show--cursor item-card"
                    (click)="createNewItem()">
                <div class="create-item__icon">
                </div>
                <div class="create-item_info">
                  <p>{{"MY_ACTIVITY_PAGE.UPLOAD_SOMETHING" | translate}}</p>
                </div>
              </div>

              <ng-container
                *ngFor="let item of itemList['items'] | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalCount}; let i = index;">
                <app-shr-item-card class="item-card"
                                    [title]="item.title"
                                    [img_path]="item.images[0] || ''"
                                    [item_rate]="item['rent_rate']"
                                    [item_location]="item['location']"
                                    (click)="navigateTo(item)"
                ></app-shr-item-card>
              </ng-container>
            </div>
          </div>
        </div>


        <!--            </item-list>-->
        <div class="add__pd top sp30 text-align-center">
          <pagination-controls class="my-pagination"
                                (pageChange)="onChangePage(currentPage = $event)"></pagination-controls>
        </div>
      </div>
    </div>
  </div>
</app-header-footer-layout>

<app-create-item *ngIf="createItem" (createItemFlag)="createItem = false; getItems()">
</app-create-item>

<popup-layout *ngIf="setupStripePopUp">
  <div class="my-stuff__popup align_center">
    <label class="label md1 bold-font popup-title align_center">Stripe Setup</label>
    <div class="label sm-1 make-it-black mt-1 align_center">
      To receive payments for your item you will need to set up stripe.
    </div>
    <div class="label sm-1 make-it-black mt-1 align_center">
      Setting this up will take 5 minutes.
    </div>
    <div class="my-stuff__actions mt-1">
      <bee-btn type="secondary" (click)="createItemFlow()">Setup later</bee-btn>
      <bee-btn type="primary" (click)="navigateToSetupAccount()">Setup now</bee-btn>
    </div>
  </div>
</popup-layout>

<popup-layout *ngIf="updateProfile">
  <div class="item-booking__popup">
    <label class="label md1 bold-font popup-title">Complete Your Profile</label>
    <div class="sm-1 make-it-black mt-1 align_center">
      Complete your profile before you add your item or request to borrow something,
    </div>
    <div class="label sm-1 make-it-black mt-1 align_center">
      this is important to create trust in our community
    </div>
    <div class="popup-actions">
      <bee-btn type="secondary" [size]="'medium'" [block]="true" (click)="updateProfile = false">Later</bee-btn>
      <bee-btn type="primary" [size]="'medium'" [block]="true" (click)="completeProfile()">Setup Profile</bee-btn>
    </div>
  </div>
</popup-layout>

<popup-layout *ngIf="loggInToSharebee">
  <section class="padding-box">
    <div class="mt-2">
      <label>You must be signed into your Sharebee account to create a listing</label>
    </div>
    <div class="button-container ms-fix-container align_center margin--bottom">
      <bee-btn type="secondary" (click)="loginUser()">Login</bee-btn>
      <bee-btn type="primary" (click)="signUpUser()">Sign up</bee-btn>
    </div>
    <p class="close-button" (click)="close()">close</p>
  </section>
</popup-layout>
