<app-header-footer-layout>
  <p class="title home-page__label align_center">{{ 'FAQ_PAGE.TITLE' | translate}}</p>
  <accordion-group>
    <accordion class="t_r_c_bold" *ngFor="let item of items" [title]="item.q">
      <div class="t_r_c" [innerHTML]="item.a | sanitizeHtml"></div>
    </accordion>
  </accordion-group>
</app-header-footer-layout>

<popup-layout *ngIf="displayTermsCondition">
  <app-terms-rules-condition #app_terms_condition>
    <div class="button-container align_center margin--bottom">
      <bee-btn type="primary" (click)="displayTermsCondition = false">{{'CANCEL' | translate}}</bee-btn>
    </div>
  </app-terms-rules-condition>
</popup-layout>

<popup-layout *ngIf="displaySip">
  <app-terms-rules-condition #app_terms_condition>
    <div class="button-container align_center margin--bottom">
      <bee-btn type="primary" (click)="displayTermsCondition = false">{{'CANCEL' | translate}}</bee-btn>
    </div>
  </app-terms-rules-condition>
</popup-layout>

<popup-layout *ngIf="sipInfo">
  <app-sip-terms-and-conditions
    (close)="sipInfo = !sipInfo"></app-sip-terms-and-conditions>
</popup-layout>
