import {EndpointsConstant} from "../../../usermodule/src/lib/core/constants/endpoints.service";

export class AuthEndpoints extends EndpointsConstant {

  public REGISTER = `${this.API_HOST}/auth/register`;
  public LOGIN = `${this.API_HOST}/auth/login`;
  /**
   * Remove the session
   */
  public LOGOUT = `${this.API_HOST}/auth/logout`;

  public VERIFY = `${this.API_HOST}/verify`;

  public INVITE = `${this.API_HOST}/auth/invite/user`;

  public RESET_PASSWORD_LINK = `${this.API_HOST}/auth/forgot-password`;

  public RESET_PASSWORD = `${this.API_HOST}/auth/reset-password`;

  // Get profile config
  public GET_PROFILE_CONFIG = `${this.API_HOST}/user/profile/config`;

  // Get profile details
  // TODO -- Change this to "/user/profile" + cookie.userid
  public GET_PROFILE_DETAILS = `${this.API_HOST}/user/profile/read`;

  // Update profile details
  public UPDATE_PROFILE_DETAILS = `${this.API_HOST}/user/profile/update`;

  public DELETE_MY_ACCOUNT = `${this.API_HOST}/auth/delete-my-account`;
  public PROFILE_IMAGE_UPLOAD = `${this.API_HOST}/user/upload/`;


  public EMAIL_SUBSCRIPTION = `${this.API_HOST}/subscription/email`;

  // Download users
  public USERS_CSV = `${this.API_HOST}/admin-cms/download/users`;

  public GET_KPI = `${this.API_HOST}/kpi/all`;

  // Download users
  public ITEMS_CSV = `${this.API_HOST}/admin-cms/download/items`;

  public PROFILES_CSV = `${this.API_HOST}/admin-cms/download/profiles`;

  public REQUEST_CSV = `${this.API_HOST}/admin-cms/download/request`;

  public ORDERS_CSV = `${this.API_HOST}/admin-cms/download/orders`;

  public HIVES_CSV = `${this.API_HOST}/admin-cms/download/hive`;

  // Invited user
  public INVITED_USER = `${this.API_HOST}/invite/invited`;

  // invite user, note there is a invite as well but does not seem to have a end point in the backend
  public INVITE_USER = `${this.API_HOST}/invite/invite`;

  public ADD_INVITE_CODE = `${this.API_HOST}/invite/add`;

  public LIST_INVITE_CODE = `${this.API_HOST}/invite/list-codes`;

  public DELETE_INVITE_CODE = `${this.API_HOST}/invite/delete`;
}
