<app-header-footer-layout [show_sidebar]="false" [blank]="true" [btn_visible]="'sign_in'">
    <section class="app-auth-user-login">
        <div class="login-container">
            <div class="input-container">
                <h1 class="add-margin-btm-15">password recovery</h1>
                <div class="login-capsule mt-2">
                    <label class="input--label add-margin-btm-15">Enter your registered email address to reset
                        your
                        password</label>
                    <label class="input--label mt-2">Email</label>
                    <input type="text"
                    autocomplete="email"
                    placeholder="eg: buzz@lightyear.com"
                    class="input--field" [(ngModel)]="email">
                    <div class="btn-container mt-2">
                      <bee-btn testId="back-button" type="secondary" (click)="loginPage()">back</bee-btn>
                        <bee-btn type="primary" (click)="submitForm()">reset</bee-btn>
                    </div>
                </div>
            </div>
            <div class="img--container">
                <img alt="login_placeholder_image" class="img-preview" src="../../../assets/svg/login.svg">
            </div>
        </div>
    </section>
</app-header-footer-layout>
