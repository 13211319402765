import {Component, EventEmitter, OnInit, Output} from "@angular/core";
import {Subject} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import { checkUserLoggedIn } from "src/app/utils/backend-jwt";

@Component({
  selector: "app-search-item",
  templateUrl: "search.component.html",
  styleUrls: ["search.component.scss"]
})

/** @deprecated Migrated*/
export class SearchComponent implements OnInit {
  @Output() searchQuery = new EventEmitter<string>();
  query = "";
  search = new Subject();
  isUserLoggedIn = false;

  constructor(private activated_router: ActivatedRoute) {
    if (checkUserLoggedIn()) {
      this.isUserLoggedIn = true;
    }

  }

  ngOnInit() {
    this.activated_router.queryParams.subscribe((query) => {
      if (query["query"]) {
        this.query = (query["query"]).split("=").pop();
      }
    });
  }

  searchItem() {
    this.searchQuery.emit("q=" + this.query);
  }

  change() {
    this.search.next();
  }
}
