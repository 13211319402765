<div class="search-bar">
  <input class="large-screen"
         type="text"
         (keyup)="change()"
         (keydown)="change()"
         autofocus
         placeholder="Baby clothes, carpet cleaners, games, ... Search for anything worth sharing"
         [(ngModel)]="query"
         (keyup.enter)="searchItem()">
  <input class="sm-screen"
         type="text"
         (keyup)="change()"
         (keydown)="change()"
         placeholder="Let's find something..."
         [(ngModel)]="query"
         (keyup.enter)="searchItem()">
  <button
    class="search-bar__btn"
    (click)="searchItem()"
  ></button>
</div>
