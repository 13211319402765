<app-header-footer-layout [show_sidebar]="is_own_item" [centre]="!is_own_item">
  <div class="item-details-container" *ngIf="item_data">
    <!--Start of left details container-->
    <div class="item-details">
      <div class="item-title">
        {{ item_data.item.title }}
      </div>

      <div class="item-info">
        <div class="item-location">
          <span>{{'LOCATION' | translate}}</span>
          <p class="details">{{ location }}</p>
        </div>
      </div>
      <div class="item-info">
        <div class="item-rate">
          <span>rate/day</span>
          <span
            *ngIf="item_data.item.rent_rate > 0; else show_free">£{{rate(item_data.item.rent_rate)}}</span>
        </div>
        <div class="item-rate">
          <span>rate/week</span>
          <span
            *ngIf="item_data.item.rent_rate_per_week > 0; else show_na">£{{rate(item_data.item.rent_rate_per_week)}}</span>
        </div>
        <div class="item-rate">
          <span>rate/28 days</span>
          <span
            *ngIf="item_data.item.rent_rate_per_month > 0; else show_na">£{{rate(item_data.item.rent_rate_per_month)}}</span>
          <ng-template #show_free><span>Free</span></ng-template>
          <ng-template #show_na><span>-</span></ng-template>
        </div>
      </div>
      <div class="item-owner">
        <div class="owner-image">
          <img src="{{ item_data?.owner?.profile_photo }}" alt="Owner">
        </div>
        <div class="owner-details">
          <div class="owner-title">
            {{'OWNER' | translate}}
          </div>
          <div class="owner-name">
            {{ item_data.owner?.beeName }}
          </div>
          <div class="owner-rating">
            {{'RATINGS' | translate}} {{'COMING_SOON' | translate}}
          </div>
        </div>
      </div>

      <div class="item-descriptions" [innerHtml]="item_data.item.description">
        <!--{{ item_data.item.description }}-->
      </div>
    </div>
    <!--End of left details container-->

    <!--Start of right details container-->
    <div class="item-preview">
      <div class="item-image-preview-container">
        <div class="item-image-preview-activated">
          <img class="preview-image" #preview src="{{(item_data?.item?.images || [])[0]}}"/>
        </div>
        <div class="item-preview-images">
          <div class="preview-image" *ngFor="let image of item_data.item.images">
            <img src="{{ image }}" (click)="preview.src = image"/>
          </div>
        </div>
      </div>

      <div class="item-actions">

        <!--<button class="bee-btn" [disabled]="!leftButtonAction" (click)="leftActionButton(item_data)">
          <span class="button-title">{{leftButtonTitle}}</span>
        </button>-->

        <bee-btn type="secondary" [disabled]="!buttonDisable" (click)="leftActionButton()">
          {{leftButtonTitle}}
        </bee-btn>

<!--        <button class="bee-btn bee-btn__primary"
                [ngClass]="buttonDisable ? 'disable' : 'active'"
                [disabled]="buttonDisable"
                (click)="action()">
          <span>{{rightButtonTitle}}</span>
        </button>-->

        <bee-btn type="primary"
                 [disabled]="buttonDisable"
                 (click)="action()">{{rightButtonTitle}}</bee-btn>
      </div>
      <!-- end of buttons -->

    </div>
    <!--End of right details container-->
  </div>
</app-header-footer-layout>

<ng-container *ngIf="edited_data">
  <app-create-item
    [itemObject]="{
      images: edited_data.item.images,
      title: edited_data.item.title,
      rent_rate: edited_data.item.rent_rate,
      rent_rate_per_week: edited_data.item.rent_rate_per_week,
      rent_rate_per_month: edited_data.item.rent_rate_per_month,
      description: edited_data.item.description,
      lend_for_free: edited_data.item.rent_rate === 0,
      item_cost: edited_data.item.item_cost,
      other_information: edited_data.item.other_information,
      service_date: edited_data.item.service_date,
      insured: edited_data.item.insured,
      category: edited_data.item.category,
      location: edited_data.item.location,
      availability: edited_data.item.availability
    }"
    [toc_accepted]="true"
    [edit_item]="edited_data.item._id"
    (onSuccess)="loadDetails(edited_data.item._id)"
    (createItemFlag)="edited_data = undefined"
  >
  </app-create-item>
</ng-container>

<popup-layout *ngIf="showTCPopUp">
  <div class="item-booking__popup">
    <label class="label md1 bold-font popup-title">{{'T_C' | translate}}</label>
    <div class="item-details-tc-pop-up">
      <p class="t_r_c">
        Before you borrow something, you must be able to answer the following truthfully:
      </p>
      <p class="t_r_c">
        I have not been convicted of or charged with any offence other than motoring offences in the past 5 years.
      </p>
      <p class="t_r_c">
        I have not been subjected to a county court judgement (CCJ), a debt relief order (DRO), an individual voluntary
        agreement (IVA) or to bankruptcy proceedings.
      </p>
      <p class="t_r_c">
        I have not made more than two personal insurance claims in the past 5 years.
      </p>
      <p class="t_r_c">
        I have read and understood the full sharebee terms rules and conditions.
      </p>
      <p class="t_r_c">
        You agree:
      </p>
      <ul>
        <li class="t_r_c">
          To keep a record of your communication with the lender and make this available to us in the event of a
          dispute.
        </li>
        <li class="t_r_c">
          To test and document the borrowed item’s condition as soon as you receive it and report any deviation from the
          description immediately to claims@sharebee.co.uk
        </li>
        <li class="t_r_c">
          You are solely responsible and personally liable for the borrowed item and you must make all reasonable effort
          to ensure it is cared for in a way that prevents loss, damage or theft.
        </li>
        <li class="t_r_c">
          Cover the item with suitable travel insurance if you take it abroad.
        </li>
        <li class="t_r_c">
          You will report any loss, theft or damage to us immediately at claims@sharebee.co.uk
        </li>
        <li class="t_r_c">
          If the item is lost or stolen, you need to obtain an official record from the police including a crime number
          in the event of theft.
        </li>
      </ul>
      <p class="t_r_c">
        You understand that:
      </p>
      <ul>
        <li class="t_r_c">
          If you change your mind or are unable to complete the share, neither we nor the lender are obliged to give you
          a refund.
        </li>
        <li class="t_r_c">
          If a claim is made against you this could affect your ability to borrow items in the future, your rating on
          the platform and your future insurance premiums outside of the sharebee platform.
        </li>
      </ul>
    </div>
    <div class="mt-2 align_center">
<!--      <input class="regular-checkbox" type="checkbox" (click)="acceptTC($event)">-->
      <label class="t_r_c">I agree terms & conditions</label>
    </div>
    <div class="popup-actions">
<!--      <button (click)="booking_done()" class="button">{{'CANCEL' | translate}}</button>-->
      <bee-btn type="secondary" (click)="booking_done()">{{'CANCEL' | translate}}</bee-btn>
<!--      <button (click)="booking_move_to(1)" class="button">{{'ACCEPT' | translate}}</button>-->
      <bee-btn type="primary" (click)="booking_move_to(1)">{{'ACCEPT' | translate}}</bee-btn>
    </div>
  </div>
</popup-layout>

<!--<app-choose-duration *ngIf="booking_steps[1]">
</app-choose-duration>-->

<popup-layout *ngIf="showDatePopUp">
  <app-choose-duration (goBack)="booking_done()" (dateRangeSelected)="rangeSelected($event)"></app-choose-duration>
</popup-layout>


<popup-layout *ngIf="bookingConfirmationPopUp">
  <div class="item-booking__popup">
    <label class="label md1 bold-font popup-title">Booking Confirmation</label>
    <div class="t_r_c mt-2">{{ item_data.item.title }}</div>
    <div class="t_r_c mt-2">Booking Dates: {{selectedDateRange?.startDate}} {{selectedDateRange?.endDate}}</div>
    <div class="t_r_c mt-2">Cost: {{totalBookingCost}}</div>
    <div class="t_r_c mt-2">
      To cover the item protection and service costs bookings less than £3.50 will be rounded up. This does not apply to items lent for free as these are not covered by Sharebee Item Protection <link>
    </div>
    <div class="popup-actions">
      <!--      <button (click)="updateProfile = false" class="button">Cancel</button>-->
      <bee-btn type="secondary" [size]="'medium'" [block]="true" (click)="bookingConfirmationPopUp = false">Cancel</bee-btn>
      <!--      <button (click)="completeProfile()" class="button active padding_lr_10">Setup Profile</button>-->
      <bee-btn type="primary" [size]="'medium'" [block]="true" (click)="book()">Accept</bee-btn>
    </div>
  </div>
</popup-layout>


<popup-layout *ngIf="updateProfile">
  <div class="item-booking__popup">
    <label class="label md1 bold-font popup-title">Complete Your Profile</label>
    <div class="sm-1 make-it-black mt-1 align_center">
      Complete your profile before you add your item or request to borrow something,
    </div>
    <div class="label sm-1 make-it-black mt-1 align_center">
      this is important to create trust in our community
    </div>
    <div class="popup-actions">
<!--      <button (click)="updateProfile = false" class="button">Cancel</button>-->
      <bee-btn type="secondary" [size]="'medium'" [block]="true" (click)="updateProfile = false">Later</bee-btn>
<!--      <button (click)="completeProfile()" class="button active padding_lr_10">Setup Profile</button>-->
      <bee-btn type="primary" [size]="'medium'" [block]="true" (click)="completeProfile()">Setup Profile</bee-btn>
    </div>
  </div>
</popup-layout>

<popup-layout *ngIf="booking_steps[2]">
  <div class="item-booking__popup">
    <label class="label md1 bold-font popup-title">{{'ORDER.REQUEST_SENT' | translate}}</label>
    <label class="popup-contents align_center make-it-black">
      {{'ORDER.BORROW_SENT_LINE_1' | translate}}
      <br/>
      <br/>
      {{'ORDER.BORROW_SENT_LINE_2' | translate}}
    </label>
    <div class="popup-actions">
<!--      <button (click)="booking_done()" class="button active">{{'CLOSE' | translate}}</button>-->
      <bee-btn type="primary" (click)="booking_done()">Close</bee-btn>
    </div>
  </div>
</popup-layout>

<popup-layout *ngIf="showOrderSummary">
  <div class="item-booking__popup">
    <label class="label md1 bold-font popup-title">{{'ORDER.ORDER_SUMMARY' | translate}}</label>
    <div>
      <div class="order-summary-details"><label class="label o8 quad">item</label><label
        class="label o8 quad">{{item_data.item.title}}</label></div>
      <div class="order-summary-details"><label class="label o8 quad">duration</label>
        <label class="label o8 quad">{{item_data?.otherInfo.duration}}
          <span *ngIf="item_data?.otherInfo.duration === 1">day</span>
          <span *ngIf="item_data?.otherInfo.duration != 1">days</span></label>
      </div>
      <div class="order-summary-details">
        <label class="label o8 quad">day rate</label>
        <label class="label o8 quad">£{{rate(item_data.item.rent_rate)}}</label>
      </div>
      <div class="order-summary-details" *ngIf="item_data.item.rent_rate_per_week > 0">
        <label class="label o8 quad">week rate</label>
        <label class="label o8 quad">£{{rate(item_data.item.rent_rate_per_week)}}</label>
      </div>
      <div class="order-summary-details" *ngIf="item_data.item.rent_rate_per_month > 0">
        <label class="label o8 quad">month rate</label>
        <label class="label o8 quad">£{{rate(item_data.item.rent_rate_per_month)}}</label>
      </div>
    </div>
    <div class="order-summary-details add-border mt-1"><label
      class="label o8 text_orange">{{'ORDER_TOTAL' | translate}}</label><label
      class="label o8 text_orange">£{{totalRent}}</label></div>
    <div class="popup-actions space__between mt-1">
      <bee-btn type="secondary" (click)="showOrderSummary = !showOrderSummary">Close</bee-btn>
<!--      <button (click)="showOrderSummary = !showOrderSummary" class="button">{{'CLOSE' | translate}}</button>-->
<!--      <button (click)="makePayment()" class="button active">{{'PAY' | translate}} £{{totalRent}}</button>-->
      <bee-btn type="primary" (click)="makePayment()">{{ 'PAY' | translate}}£{{totalRent}}</bee-btn>
    </div>
  </div>
</popup-layout>


<popup-layout *ngIf="paymentSuccess">
  <div class="item-booking__popup">
    <label class="label md1 bold-font popup-title">{{'ORDER.PAYMENT_SUCCESSFUL' | translate}}</label>
    <label class="label o8 quad bold-font popup-title">{{'ORDER.PAYMENT_OF' | translate}}
      £{{totalRent}} {{'ORDER.PAYMENT_SUCCESS_POP_UP' | translate}}</label>
    <label class="label md quad bold-font popup-title">{{ item_data?.item?.title }}</label>
    <div class="popup-actions mt-1">
<!--      <button (click)="closePopUp()" class="button active">{{'CLOSE' | translate}}</button>-->
      <bee-btn type="primary" (click)="closePopUp()">Close</bee-btn>
    </div>
  </div>
</popup-layout>

<popup-layout *ngIf="paymentError">
  <div class="item-booking__popup">
    <label class="label md1 bold-font popup-title">{{'ORDER.PAYMENT_FAILURE' | translate}}</label>
    <label class="label o8 quad bold-font popup-title">{{'ORDER.PAYMENT_FAILURE_MESSAGE' | translate}}</label>
    <div class="popup-actions mt-1">
<!--      <button (click)="paymentError = !paymentError" class="button active">{{'CLOSE' | translate}}</button>-->
      <bee-btn type="primary" (click)="paymentError = !paymentError">Close</bee-btn>
    </div>
  </div>
</popup-layout>

<popup-layout *ngIf="activateOrDeactivateSuccess">
  <div class="item-booking__popup">

    <label class="label md1 bold-font popup-title"
           *ngIf="item_data?.item.deactivated">{{'ITEM_DETAILS.DEACTIVATE_TITLE' | translate}}</label>
    <label class="label o8 quad bold-font popup-title"
           *ngIf="item_data?.item.deactivated">{{'ITEM_DETAILS.DEACTIVATE_SUCCESSFUL_MESSAGE' | translate}}</label>

    <label class="label md1 bold-font popup-title"
           *ngIf="!item_data?.item.deactivated">{{'ITEM_DETAILS.ACTIVATE_TITLE' | translate}}</label>
    <label class="label o8 quad bold-font popup-title"
           *ngIf="!item_data?.item.deactivated">{{'ITEM_DETAILS.ACTIVATE_SUCCESSFUL_MESSAGE' | translate}}</label>

    <div class="popup-actions mt-1">
      <!--<button (click)="activateOrDeactivateSuccess = !activateOrDeactivateSuccess"
              class="button active">{{'CLOSE' | translate}}</button>-->
      <bee-btn type="primary" (click)="activateOrDeactivateSuccess = !activateOrDeactivateSuccess">Close</bee-btn>
    </div>
  </div>
</popup-layout>

<popup-layout *ngIf="bookingCancelledPopUp">
  <div class="item-booking__popup">

    <label class="label md1 bold-font popup-title">{{'ITEM_DETAILS.BOOKING_CANCELLED_TITLE' | translate}}</label>
    <label class="label o8 quad bold-font popup-title">{{'ITEM_DETAILS.BOOKING_CANCELLED_MESSAGE' | translate}}</label>

    <div class="popup-actions mt-1">
      <bee-btn type="primary" (click)="bookingCancelledPopUp = !bookingCancelledPopUp">Close</bee-btn>
    </div>
  </div>
</popup-layout>

<popup-layout *ngIf="loggInToSharebee">
  <section class="padding-box">
    <div class="mt-2">
      <label>You must be signed into your Sharebee account to create a listing</label>
    </div>
    <div class="button-container ms-fix-container align_center margin--bottom">
      <bee-btn type="secondary" (click)="loginUser()">Login</bee-btn>
      <bee-btn type="primary" (click)="signUpUser()">Sign up</bee-btn>
    </div>
    <p class="close-button" (click)="close()">close</p>
  </section>
</popup-layout>


