import {Resources} from "./resources.config";
import {ConfigService, SignupMethods} from "../shared/config.service";
import {Injectable} from "@angular/core";
import {Role} from "../shared/user.services";

// declare const Roles: any;
// This class is redundant and it is just created to avoid some compile time issue
export class RolesValue {
  public static ADMIN = Role.ADMIN;
  public static USER = Role.USER;

  /**
   * Default role for any user who is not logedin
   */
  public static GUEST = Role.GUEST;
}

/**
 * Rbac configurations
 */
export const ACCESS_INFO = {
  [RolesValue.ADMIN]: {
    [Resources.SIGNUP]: { "read:any": ["*"] },
    [Resources.INVITE]: { "read:any": ["*"] },
    [Resources.PROFILE]: { "read:any": ["*"] }
  },
  [RolesValue.USER]: {
    [Resources.PROFILE]: { "read:any": ["*"] }
  },
  [RolesValue.GUEST]: {
    [Resources.SIGNUP]: { "read:any": ["*"] },
    [Resources.ACTIVE_ACCOUNT]: { "read:any": ["*"] },
    [Resources.LOGIN]: { "read:any": ["*"] },
    [Resources.FORGET_PASSWORD]: { "read:any": ["*"] },
    [Resources.RESET_PASSWORD]: { "read:any": ["*"] },
    [Resources.ACTIVATED]: { "read:any": ["*"] }
  }
};

@Injectable()
export class RbacConfigService {
  constructor(private config: ConfigService) {}

  getAccessInfo(): Promise<any> {
    if (this.config.signup_mode === SignupMethods.INVITE) {
      delete ACCESS_INFO[Role.GUEST][Resources.SIGNUP];
    } else {
      delete ACCESS_INFO[Role.ADMIN][Resources.SIGNUP];
    }

    return Promise.resolve()
      .then(() => ACCESS_INFO);
  }
}
