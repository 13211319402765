<div #ref>
  <ng-content></ng-content>
</div>
<div *ngIf="ref.childNodes.length == 0" class="image-form-inner-wrapper">
  <section class="image-form-container">
    <article class="content-container">
      <!-- left side image -->
      <article class="content_image content" [style.background-image]="'url(../../../../' + imagePath + ')'">
      </article>

      <article class="content_form content">
        <div class="form-logo-container">
          <div class="form-logo">

          </div>
        </div>
        <p class="content_form-title">
          {{ title }}
        </p>
        <p class="content_form-subtitle">
          {{ subtitle }}
        </p>
        <!-- form -->
        <app-dynamic-form [dataObject]="formFields" (formSubmit)="formSubmit($event)" id="ngForm" #dynamic_form></app-dynamic-form>
        <!-- form submit button -->

        <div #ref>
          <ng-content></ng-content>
        </div>
        <div *ngIf="ref.childNodes.length == 0" class="button-container">
<!--              <button class="bee-btn secondary add_width" (click)="back()">{{ back_button_label }}</button>-->
          <bee-btn type="secondary" [block]="true" (click)="back()">{{back_button_label}}</bee-btn>
          <bee-btn type="primary" class="mr-top-15"
                    [block]="true"
                    [disabled]="!dynamic_form.isValid"
                    (click)="dynamic_form.onSubmit()">{{buttonLabel}}</bee-btn>
          <!--<button
            class="bee-btn bee-btn__primary add_width"
            [class.active]="dynamic_form.isValid"
            [class.disabled]="!dynamic_form.isValid"
            [disabled]="!dynamic_form.isValid"
            (click)="dynamic_form.onSubmit()">
            {{buttonLabel}}</button>-->
        </div>
      </article>
    </article>
  </section>
</div>
