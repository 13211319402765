import {nextStatusActions} from "../orders/order.helper";
import {Component, Input, OnInit} from "@angular/core";
import {DomainResource} from "../user-auth/configs/config.helper";
import {Role} from "../user-auth/shared/user.services";
import {ItemApiService} from "../items/item-api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {NgxSpinnerService} from "ngx-spinner";
import {ErrorCodeHandler} from "../user-auth/shared/error.code.handler";
import {AppRoutePaths} from "@core/constants/route.path";
import {OrderStatus} from "../shared/models/booking-status.model";
import {commissionRate, durationBreakdownCalc} from "../utils/numbers";
import {ToastrService} from "ngx-toastr";
import {EndpointsService} from "../shared/constants/endpoints.service";
import {config} from "src/config";
import {Stripe} from "@stripe/stripe-js";
import { checkUserLoggedIn } from "../utils/backend-jwt";

@DomainResource({
  name: "MY_ACTIVITY",
  access: [Role.ADMIN, Role.USER]
})

@Component({
  selector: "app-my-activity",
  templateUrl: "./my-activity.component.html",
  styleUrls: ["my-activity.component.scss"]
})

export class MyActivityComponent implements OnInit {
  @Input() onlyLending: boolean;
  @Input() showHiveItems: boolean;
  @Input() hiveId: string;
  shardItems = [];
  borrowedItems = [];
  requestedItems = [];
  showSharedItems = 4;
  showBorrowedItems = 4;
  showNoItemsMessage: boolean;
  itemDeletedFlag: boolean;
  statusUpdate = false;
  stripe_setup = "";
  item_data;
  // totalRent = 0;
  showOrderSummary = false;
  paymentSuccess = false;
  paymentError = false;
  commissionInterest: number;
  paymentStatus: any;
  orderId: any;

  isUserLoggedIn = false;

  get totalRent(): number {
    const tempTotalRent = commissionRate(this.commissionInterest, durationBreakdownCalc(
      this.item_data.otherInfo.duration,
      this.item_data.item.rent_rate_per_month || 0,
      this.item_data.item.rent_rate_per_week || 0,
      this.item_data.item.rent_rate,
    ));
    return (tempTotalRent > 3.5 || tempTotalRent === 0) ? tempTotalRent : 3.5;
  }

  stripe: Stripe;

  constructor(private http: ItemApiService,
              public router: Router,
              public translate: TranslateService,
              public constants: EndpointsService,
              private activatedRoute: ActivatedRoute,
              private spinner: NgxSpinnerService,
              private toast: ToastrService,
              private error_handler: ErrorCodeHandler) {
    this.stripe = window.Stripe(this.constants.PUBLISH_KEY);
    this.activatedRoute.queryParams.subscribe((params) => {
      this.paymentStatus = params.payment;
      // console.warn(params.payment);
      this.orderId = params.orderId;
      // console.warn(params.orderId);
    });
  }

  ngOnInit() {
    if (checkUserLoggedIn()) {
      this.isUserLoggedIn = true;
    }
    this.commissionInterest = config.shareBeeInterest;
    this.load();
    if (this.paymentStatus !== undefined && this.orderId !== undefined) {
      this.updateOrderStatus();
    } /*else {
      if (this.paymentStatus === 'success' && this.orderId === undefined) {
        this.paymentSuccess = !this.paymentSuccess;
        this.router.navigate([], {
          queryParams: {
            payment: undefined,
          },
          queryParamsHandling: 'merge',
        });
      }
    }*/
  }

  load() {

    this.spinner.show();
    this.http.getMyActivity({hiveId: this.hiveId})
      .toPromise()
      .then(({data}) => {
        this.spinner.hide();
        this.stripe_setup = data.setup_stripe;
        // console.warn(this.hiveId);
        // console.warn(data.lend.filter((order) => order.hive.equals(this.hiveId)));
        this.shardItems = data.lend;
        this.borrowedItems = data.borrowed;
        this.requestedItems = data.requested;
      })
      .catch((error) => {
        this.error_handler.handleErrorCode(error.code);
        this.spinner.hide();
      });
  }

  showDetails(item) {
    if (item.deleted) {
      this.itemDeletedFlag = true;
    } else {
      this.router.navigate(["my-activity/item-details"], {
        queryParams: {
          id: item._id, title: item.title
        }});
    }
  }

  openItemDetails(item) {
    if (item.deleted) {
      this.itemDeletedFlag = true;
    } else {
      this.router.navigate([AppRoutePaths.USER_ITEM_DETAILS],
        {
          queryParams: {
            id: item._id,
            order_id: item._id,
            status: item.current_status,
            duration: item.duration,
            borrowed_by_me: true,
            borrowed_status: item.current_status,
            start_date: item.start_date,
            end_date: item.end_date
          }
        });
    }
  }

  actions(order) {
    const actionObj = (nextStatusActions(order) || []).map((status) => ({
      title: mapStatusTitle(status),
      click: ($event: MouseEvent) => {
        // console.warn(order.item.rent_rate);
        if (this.stripe_setup === "pending" && order.item.rent_rate !== 0) {
          this.router.navigate(["users/account-verification"], {queryParams: {howToSetUpStripeFlag: true}});
        } else {
          $event.stopPropagation();
          this.http.changeItemStatus({
            order_id: order._id,
            status
          }).toPromise()
            .then(() => {
              this.statusUpdate = !this.statusUpdate;
              this.load();
            });
        }
      }
    }));
    return actionObj;
  }

  borrowAction(item) {
    // console.warn(item);
    const hiveId = item.hive;
    const itemId = item._id;
    let borrowBtn = [];
    if (item.current_status === OrderStatus.payment_pending) {
      if (item.rent_cost === 0) {
        borrowBtn = [
          OrderStatus.confirm_booking
        ];
      }
      borrowBtn = [
        OrderStatus.make_payment
      ];
    }

    return (borrowBtn || []).map((status) => ({
      title: mapStatusTitle(status),
      click: ($event: MouseEvent) => {
        $event.stopPropagation();
        this.http.getItemDetails({hive_id: hiveId, item_id: itemId}, this.isUserLoggedIn).toPromise()
          .then((data) => {
            this.item_data = data.data;
            this.showOrderSummary = !this.showOrderSummary;
          });
      }
    }));
    // return actionObj;
  }


  makePayment() {
    this.showOrderSummary = !this.showOrderSummary;
    if (this.item_data.item.rent_rate === 0) {
      const packageBody = {
        data: {
          order: this.item_data.otherInfo.order_id
        }
      };
      this.updateOrder(packageBody);
    } else {
      this.spinner.show();
      const packageBody = {
        data: {
          order: this.item_data.otherInfo.order_id,
          amount: this.totalRent,
          link: "my-activity"
        }
      };
      this.http.sessionCheckout(packageBody).toPromise()
        .then((response) => {
          console.warn("response", response);
          return response;
        })
        .then((session) => {
          console.warn("session", session);
          return this.stripe.redirectToCheckout({sessionId: session.data.id});
        })
        .then((result) => {
          // If `redirectToCheckout` fails due to a browser or network
          // error, you should display the localized error message to your
          // customer using `error.message`.
          console.warn("result", result);
          if (result.error) {
            alert(result.error.message);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }

  updateOrder(packageBody) {
    this.http.makePayment(packageBody)
      .subscribe(() => {
        this.paymentSuccess = !this.paymentSuccess;
        this.load();
      }, ({error}) => {
        this.paymentError = !this.paymentError;
        this.toast.error(error, error.title);
      });
  }

  updateOrderStatus() {
    if (this.paymentStatus === "failed") {
      this.paymentError = !this.paymentError;
    } else {
      this.http.updateOrderStatus(this.paymentStatus, this.orderId)
        .subscribe(() => {
          this.paymentSuccess = !this.paymentSuccess;
          this.load();
          this.router.navigate([], {
            queryParams: {
              orderId: undefined,
              payment: undefined
            },
            queryParamsHandling: "merge",
          });
        }, ({error}) => {
          this.paymentError = !this.paymentError;
          this.toast.error(error, error.title);
        });
    }
  }

  rate(item_rate: number): number {
    return commissionRate(this.commissionInterest, item_rate);
  }

  closePopUp(status) {
    if (status === "error") {
      this.router.navigate([], {
        queryParams: {
          orderId: undefined,
          payment: undefined
        },
        queryParamsHandling: "merge",
      });
      this.paymentError = !this.paymentError;
    } else {
      this.paymentSuccess = ! this.paymentSuccess;
    }
  }
}

/**
 * maps a status to text to display on page
 * reason this is not exported or in the order helpers is this is just
 * for the view on this component
 */
const mapStatusTitle = (status: OrderStatus) => {
  switch (status) {
  case OrderStatus.payment_pending:
    return "Accept";
  case OrderStatus.cancelled:
    return "Reject";
  case OrderStatus.owner_deliver:
    return "I deliver";
  case OrderStatus.borrower_pickup:
    return "Borrower collects";
  case OrderStatus.owner_pickup:
    return "I collect";
  case OrderStatus.borrower_deliver:
    return "Borrower delivers";
  case OrderStatus.done:
    return "Share complete";
  case OrderStatus.make_payment:
    return "Make Payment";
  case OrderStatus.confirm_booking:
    return "Confirm Booking";
  }
};
