<section class="lib-app-profile full-page" *ngIf="profileFieldConfig">
  <section class="image-form-container" #myProfileView>
    <div class="logo">
      <img [src]="imageService.SHAREBEE_LOGO"/>
    </div>
    <article class="content-container">
      <article class="content_form content">
        <p class="content_form-title">
          {{profileForm.title}}
        </p>
        <!-- form -->
        <ng-container *ngIf="configObject">
          <app-dynamic-form
            [dataObject]="profileFieldConfig"
            [configObject]="configObject"
            (formSubmit)="updateProfile($event)"
            id="ngForm"
            #formRef
            [class.view--profile]="mode === 'view'">
          </app-dynamic-form>
        </ng-container>
        <p class="align_center">why are we asking for this information (<span class="primary_text add-anchor" (click)="exitProfileInfo()">?</span>)</p>
        <!-- form submit button -->
        <!-- <div class="button-container">
            <button class="btn-save button" (click)="onAction()">{{profileForm.buttonLabel}}</button>
          </div>-->
      </article>
    </article>
  </section>
</section>

<popup-layout *ngIf="profileInfo">
  <div class="profile-info align_center">
    <div class="label sm-1 make-it-black mt-1 align_center">
      <h4 class="align_center">Why are we asking for this information now?</h4>
      <p class="text_normal font_nu">When you charge for an item, we add protection <span class="primary_text" (click)="sipInfo = !sipInfo">(SIP)</span>
        in case it's lost broken or stolen. In order to provide this, we need to know your location and DOB. This
        information is also used to process payment to your account.
      </p>
      <p class="text_normal font_nu">When you borrow something there needs to be some trust between you and the lender. Whilst we don't share
        your profile information with other members (Apart from email and name with people you share with, see
        <span class="primary_text" (click)="displayPrivacyPolicy = !displayPrivacyPolicy">privacy policy</span>) It's important to ensure that we can verify your identity and contact you in case of a dispute. Just like
        in a normal hire shop.
      </p>
      <p class="text_normal">All profile information is encrypted and protected</p>
    </div>
    <div class="my-stuff__actions to--center mt-1">
<!--          <button (click)="exitProfileInfo()" class="button active">Close</button>-->
      <bee-btn type="primary" (click)="exitProfileInfo()">Close</bee-btn>
    </div>
  </div>
</popup-layout>

<popup-layout *ngIf="sipInfo">
  <app-sip-terms-and-conditions
    (close)="sipInfo = !sipInfo"
  ></app-sip-terms-and-conditions>
</popup-layout>

<popup-layout *ngIf="displayPrivacyPolicy">
  <app-privacy-policy #app_privacy_policy>
    <div class="button-container align_center margin--bottom">
<!--          <button class="button" (click)="displayPrivacyPolicy = false">Close</button>-->
      <bee-btn type="primary" (click)="displayPrivacyPolicy = false">Close</bee-btn>
    </div>
  </app-privacy-policy>
</popup-layout>
<!--<div class="button-container">
  <button class="button" (click)="deleteMyAccount()">Delete My Account</button>
</div>-->
