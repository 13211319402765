<section class="app-colony">
  <div class="colony_wrapper">
    <article class="colony_info">
      <app-shr-home-title>
      {{'COMMUNITY.THE_COMMUNITY' | translate}}
      </app-shr-home-title>
      <div class="colony_info__text">
        <p>{{'HOME_PAGE.COMMUNITY_DESC_1' | translate}}</p>
        <p>{{'HOME_PAGE.COMMUNITY_DESC_2' | translate}}</p>
      </div>
      <bee-btn type="primary" (click)="goToColonyPage()">{{ 'HOME_PAGE.KNOW_MORE' | translate}}</bee-btn>
    </article>

    <article class="colony_image image-info-wrapper__image">
      <img [src]="imageService.HOMEPAGE_COMMUNITY">
    </article>

  </div>
</section>
