<popup-layout [showLogo]="false" *ngIf="inviteByEmail">
  <div class="app-hive-invite-popup">
    <h3>Invite user</h3>
    <div class="item-share__rate">
      <label class="label sm-1 primary" [ngClass]="{'set--active': true}">{{ 'Email id' }}</label>
      <input #email class="input-field sm1" placeholder="User email id" name="itemObject.month_rent">
      <p class="validation-alert">{{''}}</p>
    </div>
    <!--        <button type="submit" class="provide-margin__button button" (click)="invite(email.value)">Invite</button>-->
    <bee-btn type="primary" (click)="invite(email.value)">Invite</bee-btn>
  </div>
</popup-layout>

<popup-layout *ngIf="guestUser">
  <section class="padding-box">
    <div class="mt-2">
      <label>Please login or sign up to Sharebee to enroll to the hive.</label>
    </div>
    <div class="button-container ms-fix-container align_center margin--bottom">
      <bee-btn type="secondary" (click)="loginUser()">Login</bee-btn>
      <bee-btn type="primary" (click)="signUpUser()">Sign up</bee-btn>
    </div>
    <p class="close-button" (click)="cancelGuestUser()">close</p>
  </section>
</popup-layout>

<popup-layout *ngIf="isApprovedPopUp">
  <div class="hive_pending__popup align_center">
    <div class="label sm-1 make-it-black mt-1 align_center">
      Please give us a moment, we’re just waiting for <span
      class="primary_text">{{hiveDetails?.owner_details?.name}}</span> to accept your membership
    </div>
    <div class="my-stuff__actions to--center mt-1">
      <bee-btn type="primary" (click)="cancelIsApproved()">Okay</bee-btn>
    </div>
  </div>
</popup-layout>

<div class="hive__container" [class.disabled]="!hiveId">

  <div class="hive_new_item_container">
    <div class="hive-invite between padding_box flex_grow add_margin">
      <div class="hive-invite-title">Add new item</div>
      <div class="add-item-block" (click)="createNewItem()">
        <div class="create-item-icon"></div>
      </div>
    </div>

    <div class="hive-invite between padding_box flex_grow">
      <div class="hive-invite-title">Add existing item</div>
      <div class="add-item-block" (click)="addExistingItem()">
        <div class="create-item-icon"></div>
      </div>
    </div>
  </div>

  <div class="hive-invite between mt-2" *ngIf="hiveDetails?.isOwn">
    <div class="hive-invite-title">Invite members</div>
    <!--        <p>{{localUrl}}</p>-->
    <!--        <div class="gap"></div>-->
    <div class="hive-invite-actions">
      <div *ngIf="isMobile">
        <share-buttons [theme]="'material-dark'"
                        [include]="['facebook','whatsapp','email','twitter','linkedin', 'copy']"
                        [show]="6"
                        [size]="-4"
                        [url]="localUrl"
                        [title]="'Hive'"
                        [description]="description"
                        [autoSetMeta]="false">
        </share-buttons>
      </div>
      <div *ngIf="!isMobile">
        <share-buttons [theme]="'material-dark'"
                        [include]="['facebook','email','twitter','linkedin', 'copy']"
                        [show]="5"
                        [size]="-4"
                        [url]="localUrl"
                        [title]="'Hive'"
                        [description]="description"
                        [autoSetMeta]="false">
        </share-buttons>
      </div>
    </div>
  </div>
</div>

<div class="hive-invite-info-text" *ngIf="hiveDetails?.isOwn">
  Do not post url's on your public feed, to avoid unknown users
</div>

<app-create-item *ngIf="createItem" [isPrivate]="true" (createItemFlag)="createItem = false"
                  (onSuccess)="itemCreated($event)">
</app-create-item>
