import {Inject, Injectable, Injector, ReflectiveInjector} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {Observable} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
//import {ResourcesService} from "../user-auth/configs/resources.config";
//import {ResourcesService} from "../user-auth/configs/resources.config";

@Injectable()
export class PullTranslationService implements Resolve<any> {
  constructor(@Inject("ResourcesService") public  resourcesService,
              public translate: TranslateService) {
    //this.injector.get('ResourcesService')
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    if (!route.component) {
      return Promise.resolve({});
    }

    return this.getComponentTranslationStrings(route.component);
  }

  getComponentTranslationStrings(component): Observable<any> {

    const resource: any = this.resourcesService.getResourceName(component);
    /*this.translate.get(resource).subscribe((data) => {
    });*/
    return (this.translate.get(resource));
  }
}
