<div [ngClass]="{'member-gradient': title !== 'what our members say'}">
  <section class="app-share-members-say">
    <article class="container">

      <div class="align_center mr-btm-30">
        <app-shr-home-title>
          {{title}}
        </app-shr-home-title>
      </div>

      <article class="capsule">
        <div *ngFor="let desc of description"
              class="member-container">
          <div class="text-container">
            <div class="box-capsule">
              {{desc.desc}}
            </div>
          </div>
          <div class="m-profile-container">
            <div class="m_capsule">
              <div class="m-brdr">
                <img class="m-img"
                      src="../../../../assets/img/no_image_available.jpeg">
              </div>
              <div class="m--details">
                <p class="m-user text_orange">{{desc.user}}</p>
                <span class="m-desc">{{desc.role}}</span>
              </div>
            </div>
          </div>
        </div>
      </article>
    </article>
  </section>
</div>
