<app-header-footer-layout [show_sidebar]="false" [blank]="true" [btn_visible]="'sign_in'">
    <section class="app-auth-user-login">
        <div class="login-container">
            <div class="input-container reset-margin-1">
                <h1 class="add-margin-btm-15">new password</h1>
                <div class="login-capsule">
                  <form class="w-100">
                    <label class="input--label mt-2">New Password</label>
                    <input autocomplete="new-password" placeholder="e.g., ***********" type="password" [(ngModel)]="password" class="input--field">
                    <label class="input--label mt-2">Confirm Password</label>
                    <input autocomplete="new-password" type="password" [(ngModel)]="confirmPassword" class="input--field">
                    <div class="btn-container at-end mt-2">
                        <bee-btn type="primary" (click)="resetPassword()">confirm</bee-btn>
                    </div>
                  </form>
                </div>
            </div>
            <div class="img--container">
                <img alt="login_placeholder_image" class="img-preview" src="../../../assets/svg/login.svg">
            </div>
        </div>
    </section>
</app-header-footer-layout>
