
<div [ngClass]="{ 'column': start !== 'row', 'row': start === 'row' }">
  <ng-container *ngIf="isArray(props); else create_input">
    <ng-container *ngFor="let prop of props">
      <app-dynamic-custom-input
        [start]="start === 'row' ? 'column' : 'row'"
        [form]="form" [prop]="prop"></app-dynamic-custom-input>
    </ng-container>
  </ng-container>
  <ng-template #create_input>
    <app-dynamic-input [key]="key" [prop]="props" [form]="form"></app-dynamic-input>
  </ng-template>
</div>
