<div class="image-viewer" [ngSwitch]="file_type">
  <!-- Show Image file preview -->
  <ng-container *ngSwitchCase="'image'">
    <img alt="preview" [src]="file_url | SafeUrl"/>
  </ng-container>

  <!-- Show video file preview -->
  <ng-container *ngSwitchCase="'video'">
    <video [src]="file_url | SafeUrl"></video>
  </ng-container>

  <!-- Show audio file preview -->
  <ng-container *ngSwitchCase="'audio'">
    <audio [src]="file_url | SafeUrl"></audio>
  </ng-container>

  <!-- If fle is not of type image or video show an alert -->
  <ng-container *ngSwitchDefault>
    File preview is not available
  </ng-container>
</div>
