<div class="header-component" [ngClass]="{'header-component--side-active': openOrClose}">
  <ng-template #links let-items="items">
    <div *ngIf="user_role === 'Admin'">
      <ul>
        <li class="list">
          <button (click)="goToInviteCodes()">Add Invite Code</button>
        </li>
        <li class="list">
          <button (click)="goToKPI()">KPI</button>
        </li>
        <li class="list">
          <a data-testid="invite-link" href="/users/auth/invite">Invite Users</a>
        </li>
        <li class="list">
          <button (click)="downloadUsers()">Users</button>
        </li>
        <li class="list">
          <button (click)="downloadProfiles()">Profiles CSV</button>
        </li>
        <li class="list">
          <button (click)="downloadItems()">Items CSV</button>
        </li>
        <li class="list">
          <button (click)="downloadRequest()">Requests CSV</button>
        </li>
        <li class="list">
          <button (click)="downloadOrders()">Orders CSV</button>
        </li>
        <li class="list">
          <button (click)="downloadHive()">Hive CSV</button>
        </li>
      </ul>
    </div>
    <li class="list side-menu" *ngFor="let item of items ; let i = index"
        [class.disabled]="item.disabled"
        [ngClass]="{'active': openOrClose, 'list__title' : item.submenus,  'show': toggle[i]}">

      <div *ngIf="!item.submenus" [routerLink]="!item.disabled ? item.url : './'"
            (click)="toggleSmallScreenMenuList()">{{item.title}}</div>
      <div *ngIf="item.submenus" (click)="toggle[i] = !toggle[i]">{{item.groupTitle || item.title}}</div>

      <ul class="submenu-wrapper" *ngIf="item.submenus" [ngClass]="toggle[i] ? 'show': 'hide'">

        <li class="list side-menu"
            *ngIf="item.title !== 'hive menu'"
            [routerLink]="!item.disabled ? item.url : './'"
            [class.disabled]="item.disabled"
            [ngClass]="{'active-menu':selectedMenu === item.title}"
            (click)="toggleSmallScreenMenuList(item.title)">
          {{item.title}}
        </li>

        <li class="submenu_items menu-list_item"
            [class.disabled]="submenu.disabled"
            [ngClass]="{'active-menu':selectedMenu === submenu.title}"
            *ngFor="let submenu of item.submenus"
            [routerLink]="!submenu.disabled ? submenu.url : './'"
            (click)="toggleSmallScreenMenuList(submenu.title)">
          {{submenu.title}}
        </li>
      </ul>


    </li>
    <!-- TODO -- still useful? -->
    <li class="list side-menu-only" [ngClass]="{'active': openOrClose}">
      <button *ngIf="isUserLoggedIn" class="logout-button button active" (click)="logout()">logout</button>
      <button *ngIf="!isUserLoggedIn" class="logout-button button active" (click)="login()">login</button>
    </li>
  </ng-template>

  <section class="left-content">
    <a href="/">
      <img alt="sharebee logo" class="left-content_logo" [src]="imageService.HEADER_LOGO">
    </a>
  </section>

  <section class="right-content align-items-center" [ngClass]="{'active': openOrClose}" *ngIf="isUserLoggedIn">
    <ul class="right-content_menu">
      <ng-container
        [ngTemplateOutlet]="links"
        [ngTemplateOutletContext]="{items:headerMenuList}">
      </ng-container>
      <li class="hamburger-icon" (click)="toggleSmallScreenMenuList()">
        <img alt="hamburger-menu" class="icon" [src]="imageService.HAMBURGER"/>
      </li>

    <div class="dropdown d-inline">
      <button data-testid="button-my-sharebee" class="btn btn-ligh dropdown-toggle" id="dropdownMenuLink" type="button" data-bs-toggle="dropdown" aria-expanded="false">
        <img class="user-icon" alt="user-icon" src="../../../assets/img/user-icon.png"/>
        <span> my sharebee</span>
     </button>
      <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
        <li>
          <a data-testid="dropdown-profile" class="dropdown-item" href="/users/profile">My Account</a>
        </li>
        <li>
          <a data-testid="dropdown-my-stuff"  class="dropdown-item" href="/items">My Stuff</a>
        </li>
        <li>
          <a data-testid="dropdown-my-activity"  class="dropdown-item" href="/my-activity">My Activity</a>
        </li>
        <li>
          <a data-testid="dropdown-transaction" class="dropdown-item" href="/transaction-history">Transaction history</a>
        </li>
        <li>
          <button (click)="logout()" class="dropdown-item text-danger">Sign out</button>
        </li>
        <li>
          <a href="/request-item">Request Item</a>
        </li>
      </ul>
    </div>
    </ul>
  </section>

  <section class="right-content header-container align-items-center" [ngClass]="{'active': openOrClose}" *ngIf="!isUserLoggedIn">
    <ul class="right-content_menu">
      <ng-container
        [ngTemplateOutlet]="links"
        [ngTemplateOutletContext]="{items:guestUserHeaderMenuList}">
      </ng-container>
      <li class="hamburger-icon" (click)="toggleSmallScreenMenuList()">
        <img class="icon" [src]="imageService.HAMBURGER"/>
      </li>
    </ul>
    <div class="action-btn">
      <bee-btn testId="button-login" type="secondary" (click)="login()" *ngIf="btn_visible === 'sign_in'">Login</bee-btn>
      <bee-btn testId="button-signup" type="secondary" (click)="signUp()" *ngIf="btn_visible === 'sign_up'">sign up</bee-btn>
    </div>
  </section>
</div>
<section class="side-nav" [ngClass]="{'side-nav--active': openOrClose}">
  <div class="side-nav__inner">
    <a href="/">
      <img class="side-nav__logo" [src]="imageService.HEADER_LOGO">
    </a>
    <div class="side-nav__close" (click)="toggleSmallScreenMenuList()"></div>
    <ul>
      <ng-container
        [ngTemplateOutlet]="links"
        [ngTemplateOutletContext]="{items:allItems}">
      </ng-container>
    </ul>
  </div>
</section>
