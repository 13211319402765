import {Component} from "@angular/core";
import {DomainResource} from "../../user-auth/configs/config.helper";
import {Role} from "../../user-auth/shared/user.services";
import {ActivatedRoute} from "@angular/router";

@DomainResource({
  name: "HIVE_ACTIVITY_DETAILS",
  access: [Role.ADMIN, Role.USER]
})
@Component({
  selector: "app-hive-activity-container",
  templateUrl: "./hive-activity.component.html",
  styleUrls: ["hive-list.component.scss"]
})
export class HiveActivityContainerComponent {
  hiveId = "";

  constructor(private route: ActivatedRoute) {
    this.route.paramMap.subscribe(params => {
      this.hiveId = params.get("id");
    });
  }

}
