import {Component, OnDestroy, ViewChild, ViewEncapsulation} from "@angular/core";

@Component({
  selector: "app-popup-layout",
  templateUrl: "./popup-layout.component.html",
  styleUrls: [
    "popup-layout.component.scss"
  ],
  encapsulation: ViewEncapsulation.None
})
export class PopupLayoutComponent implements OnDestroy {
  @ViewChild("popup", { static: true}) popup;

  ngOnDestroy() {
    return undefined;
  }

  constructor(){
    return undefined;
  }
  close() {
    this.popup.close();
  }
}
