import {Component, Input, OnInit, ViewChild, ViewEncapsulation} from "@angular/core";
import {Role, UserServices} from "../../../app/user-auth/shared/user.services";
import {DomainResource} from "../../../app/user-auth/configs/config.helper";
import {Router} from "@angular/router";
import {config} from "src/config";
import {ItemApiService} from "../item-api.service";
import {TranslateService} from "@ngx-translate/core";
import {AppRoutePaths} from "@core/constants/route.path";
import {IActionConfig} from "../../shared/item-card/item-card.component";
import {ToastrService} from "ngx-toastr";
import { Title } from "@angular/platform-browser";
import { checkUserLoggedIn } from "src/app/utils/backend-jwt";

@DomainResource({
  name: "MY_ITEMS",
  access: [Role.ADMIN, Role.USER]
})
@Component({
  selector: "app-my-list",
  templateUrl: "my-stuff.component.html",
  styleUrls: [
    "my-stuff.component.scss"
  ],
  encapsulation: ViewEncapsulation.None
})
export class MyStuffComponent implements OnInit {
  // @ViewChild('itemlist') itemlist;
  @Input() createItemFlag: boolean;
  @ViewChild("itemlist", { static: true}) item_list;

  createItem = false;
  item_deleted = false;
  delete_item: any;
  api_endpoint = config.api_host + "/items/my-stuff";
  setupStripePopUp = false;
  canCreateItem = true;
  updateProfile: boolean;
  itemList: any[] = [];
  itemsPerPage = 7;
  currentPage = 1;
  totalCount: number;
  isUserLoggedIn = false;

  constructor(public router: Router,
              private toastService: ToastrService,
              private http: ItemApiService,
              private user: UserServices,
              private title: Title,
              public translate: TranslateService) {
    this.title.setTitle("My Stuff | Sharebee");
  }

  /*createItem() {
    this.router.navigate(['users/items']);
  }*/

  /**
   * Navigate user to item details on click on a item
   */
  navigateTo(item: any) {
    this.router.navigate([AppRoutePaths.USER_ITEM_DETAILS], {queryParams: {id: item._id, own: true}});
  }

  /**
   * Configure delete action for the card
   */
  deleteAction(item): IActionConfig {
    return {
      title: "delete",
      click: () => {
        this.delete_item = item;
      }
    };
  }

  setupStripeAccount() {
    // this.setupStripePopUp = true;
    this.canCreateItem = false;
  }

  createNewItem() {
    this.user.getUser()
      .then((user) => {
        if (user["profileUpdated"] || user["role"] === Role.GUEST) {
          if (this.canCreateItem) {
            this.createItem = !this.createItem;
          } else {
            this.setupStripePopUp = true;
          }
        } else {
          this.updateProfile = true;
        }
      });
  }

  createItemFlow() {
    this.setupStripePopUp = !this.setupStripePopUp;
    this.createItem = !this.createItem;
  }

  completeProfile() {
    this.updateProfile = false;
    this.router.navigate([AppRoutePaths.MY_PROFILE]);
  }

  navigateToSetupAccount() {
    this.setupStripePopUp = false;
    this.router.navigate(["users/account-verification"], {queryParams: {howToSetUpStripeFlag: true}});
  }

  closeDelete() {
    this.delete_item = undefined;
  }

  /**
   * Delete an item
   */
  deleteItem(item) {
    this.http.deleteItem(item._id)
      .subscribe(this.onDeleteSuccess.bind(this), this.onDeleteError.bind(this));
  }

  /**
   * Handle item delete success
   */
  onDeleteSuccess(data) {
    this.item_deleted = true;
    this.delete_item = undefined;
    this.getItems();
  }

  /**
   * handle item delete error
   */
  onDeleteError(err) {
  }

  ngOnInit(): void {
    if (checkUserLoggedIn()) {
      this.isUserLoggedIn = true;
      this.getItems();
    }
  }

  getItems() {
    if (this.isUserLoggedIn) {
      this.http.getMyItems(undefined)
        .subscribe(
          (result: { items: any[], totalCount: number, alerts: any[]}) => {
            this.itemList = result.items;
            this.totalCount = result.totalCount;
            if(result.alerts.length > 0) {
              result.alerts.forEach(alert => {
                this.toastService[alert.level](alert.message, alert.title);
              });
            }
          },
          ({error}) => {
            this.toastService.error(error, "Error");
          }
        );
    }
  }

  onChangePage(page: number): void {
    console.warn(page);
  }
}
