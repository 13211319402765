import * as R from "ramda";

//
// ──────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────── I ──────────
//   :::::: H E L P E R   F U N C T I O N S   W I T H   N O   S I D E   E F F E C T S   F O R   U S E R D A T A   O B J E C T S : :  :   :    :     :        :          :
// ──────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────
//



/**
 * Function that when given a userData object will return a formatted location string
 * formatted location string is {city + postcode.slice(0.3)}
 * 
 * limitation: currently if user has city but no postcode the you will get `city ` with space at end of city
 * This case in theory is not reachable as user always has postcode
 * 
 * 
 * Note : :( there does not seem to be a proper userData type
 */
export const FormattedUserLocation: (userData: any) => string = R.converge(R.concat, [
  R.pipe(
    R.prop("city"),
    R.defaultTo(""),
    R.when(
      R.pipe(R.isEmpty, R.not),
      R.concat(R.__, " ")
    )
  ), 
  R.pipe(
    R.prop("postalCode"),
    R.defaultTo(""),
    R.slice(0, 3)
  )
]);
