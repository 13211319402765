import {Component, OnInit} from "@angular/core";
import {ApiService} from "../../user-auth/shared/api.service";
import {UserServices} from "../../user-auth/shared/user.services";
import {NgxSpinnerService} from "ngx-spinner";
import {ActivatedRoute, Router} from "@angular/router";
import {ErrorCodeHandler} from "../../user-auth/shared/error.code.handler";
import {ToastrService} from "ngx-toastr";
import {ConfigService} from "../../user-auth/shared/config.service";
import {TranslateService} from "@ngx-translate/core";
import {AppRoutePaths} from "@core/constants/route.path";
import { SignInRequest } from "../../user-auth/shared/requests";
import { parseJWTCookie } from "src/app/utils/backend-jwt";

@Component({
  selector: "app-auth-user-login",
  templateUrl: "./login.component.html",
  styleUrls: ["../auth-common.component.scss"]
})
export class AuthLoginComponent implements OnInit {
  private hiveId: any;
  private redirectRoute: AppRoutePaths;
  password: string;
  emailId: string;

  constructor(private apiService: ApiService,
              private user: UserServices,
              private spinner: NgxSpinnerService,
              private router: Router,
              private errorHandler: ErrorCodeHandler,
              private toastrService: ToastrService,
              private config: ConfigService,
              public translate: TranslateService,
              public activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    const params = new URLSearchParams(window.location.search);
    const email = params.get("email");
    if(email){
      this.emailId = email;
    }
    if(params.has("signup")) {
      this.toastrService.success("Please check your emails to finish your account creation", "Registered successfully", {
        disableTimeOut: true
      });
    }
    if(params.has("verified")){
      this.toastrService.success("Your account was successfully verified", "Account verified", {
        disableTimeOut: true
      });
    }
    this.activatedRoute.queryParams.subscribe((query) => {
      this.hiveId = query["hiveId"];
      this.redirectRoute = query["redirectRoute"];
    });
    const hiveId = localStorage.getItem("hiveId");
    if (hiveId !== null) {
      this.hiveId = hiveId;
      localStorage.removeItem("hiveId");
    }
  }

  /**
   * Function for users to signIn
   * param data:<any>
   */
  signIn(): void {
    if (this.emailId && this.password) {
      this.spinner.show();
      const requestData: SignInRequest = {
        email: this.emailId.toLowerCase(),
        password: this.password
      };

      if (this.hiveId !== undefined) {
        requestData.hiveId = this.hiveId;
      }

      this.apiService.signIn(requestData)
        .subscribe(
          () => {
            this.signInSuccess();
          },
          (error) => {
            this.processSignInError(error);
          });
    } else {
      this.toastrService.error("email & password cannot be empty", "");
    }
  }

  /**
   * Function to handle data if sign in successfully
   * param result:<any>
   */
  signInSuccess(): void {
    this.spinner.hide();
    const user = parseJWTCookie();

    this.user.setUser(user);
    if (this.hiveId === undefined) {
      const userObject = user;
      if (userObject.mapHiveId) {
        this.hiveId = userObject.mapHiveId;
      }
    }
    if (this.hiveId !== undefined) {
      this.router.navigate([`/users/my-hive/${this.hiveId}/items`]);
    } else if (user.hasHives) {
      this.router.navigate(["/users/my-hive"]);
    } else if (this.redirectRoute) {
      this.router.navigate([this.redirectRoute]);
    } else if (user.profileUpdated) {
      this.router.navigate(["/"]);
    } else {
      this.router.navigate([this.config.post_signin_redirection]);
    }
  }

  /**
   * Function to handle data if sign in failed
   * param err:<any>
   */
  processSignInError(error: any): void {
    this.spinner.hide();
    if (error.error === "INVALID_USERNAME_OR_PASSWORD") {
      this.toastrService.error("Incorrect emailId or password", "", {
        progressBar: true,
      });
    } else if (error.error === "LOGIN_FAILED_THRICE") {
      this.router.navigate(["users/auth/forgot-password"]);
      this.toastrService.error("Sorry it looks like there is an issue with your login please reset your password.");
    } else {
      this.toastrService.error(error.error, "Could not sign in");
    }
  }

  handleForgotPassword() {
    this.router.navigate(["users/auth/forgot-password"]);
  }

  navigateToSignUp() {
    this.router.navigate([AppRoutePaths.HOME_GUEST_REGISTER]);
  }

  homePage() {
    this.router.navigate(["/"]);
  }

}
