import {Component, OnInit, ViewEncapsulation} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {DomainResource} from "../../user-auth/configs/config.helper";
import {Role, UserServices} from "../../user-auth/shared/user.services";
import {ActivatedRoute, Router} from "@angular/router";
import {switchMap} from "rxjs/operators";
import {HiveApiService} from "../hive-api.service";
import {AppRoutePaths} from "@core/constants/route.path";
import {ToastrService} from "ngx-toastr";
import {StateService} from "../../service/state.service";
import { checkUserLoggedIn, parseJWTCookie } from "src/app/utils/backend-jwt";

@DomainResource({
  name: "HIVE_ITEM_LIST",
  access: [Role.ADMIN, Role.USER, Role.GUEST]
})
@Component({
  selector: "app-hive-item-list",
  templateUrl: "./hive-item-list.component.html",
  styleUrls: ["./hive-item-list.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class HiveItemListComponent implements OnInit {

  items: Array<any>;
  newMember = false;
  canCreateItem = true;
  createItem = false;
  setupStripePopUp = false;
  isApproved = false;
  updateProfile: boolean;
  uploadItem = false;
  hiveId = "";
  guestUser = false;
  hiveDetails: any;
  private isUserLoggedIn: boolean;
  // beeStatus: any;
  loggedInUser: any;
  isGuestUser = false;
  // isHiveOwner = false;
  isHiveEnroller = false;

  itemsPerPage = 11;
  currentPage = 1;
  totalCount: number;

  constructor(public translate: TranslateService,
              protected router: Router,
              private user: UserServices,
              private stateService: StateService,
              private toast: ToastrService,
              private route: ActivatedRoute,
              private api: HiveApiService) {
    this.route.paramMap.subscribe(params => {
      this.hiveId = params.get("id");
    });
  }

  create() {
    this.router.navigate(["/hive/create"]);
  }

  navigateTo(item: any) {
    if (this.isUserLoggedIn) {
      if (this.hiveDetails.isOwn) {
        this.itemDetails(item);
      } else {
        // const userId = this.loggedInUser.userid;
        // const emailId = this.loggedInUser.email;
        const existing: any = this.hiveDetails.bees.find((i: any) => i.user_id === this.loggedInUser.userid || i.email === this.loggedInUser.email);
        console.warn(existing);
        if (existing.status === "accepted") {
          this.itemDetails(item);
        } else if (existing.status === "enrolled") {
          this.isApproved = !this.isApproved;
        } else {
          this.isHiveEnroller = !this.isHiveEnroller;
        }
      }
    } else {
      this.guestUser = !this.guestUser;
    }
  }

  itemDetails(item) {
    this.route.paramMap.subscribe((params) => {
      this.router.navigate([AppRoutePaths.USER_ITEM_DETAILS], {
        queryParams: {
          id: item._id,
          hive: params.get("id"),
          own: true
        }
      });
    });
  }

  ngOnInit(): void {
    this.loadData();
    if (checkUserLoggedIn()) {
      this.loggedInUser = parseJWTCookie();
      this.isUserLoggedIn = true;
    } else {
      this.isGuestUser = !this.isGuestUser;
    }
  }

  uploadItemToHive() {
    if (this.isUserLoggedIn) {
      if (this.hiveDetails.isOwn) {
        this.uploadItem = !this.uploadItem;
      } else {
        const existing: any = this.hiveDetails.bees.find((i: any) => i.user_id === this.loggedInUser.userid || i.email === this.loggedInUser.email);
        if (existing.status === "accepted") {
          if (this.canCreateItem) {
            this.uploadItem = !this.uploadItem;
          }
        } else if (existing.status === "enrolled") {
          this.isApproved = !this.isApproved;
        }
      }
    } else {
      this.guestUser = !this.guestUser;
    }
  }

  createNewItem() {
    if (this.isUserLoggedIn) {
      this.user.getUser()
        .then((user) => {
          if (user["profileUpdated"]) {
            if (this.hiveDetails.isOwn) {
              if (this.canCreateItem) {
                this.createItem = !this.createItem;
              } else {
                this.setupStripePopUp = true;
              }
            } else {
              // const userId = this.loggedInUser.userid;
              // const emailId = this.loggedInUser.email;
              // console.warn(this.hiveDetails.bees);
              const existing: any = this.hiveDetails.bees.find((i: any) => i.user_id === this.loggedInUser.userid || i.email === this.loggedInUser.email);
              // console.warn(existing);
              if (existing.status === "accepted") {
                if (this.canCreateItem) {
                  this.createItem = !this.createItem;
                } else {
                  this.setupStripePopUp = true;
                }
              } else if (existing.status === "enrolled") {
                this.isApproved = !this.isApproved;
              } else {
                this.toast.error("You dont have permission to create item");
              }
            }
          } else {
            this.updateProfile = true;
          }
        });
    } else {
      this.guestUser = !this.guestUser;
    }
  }

  itemCreated(itemObject: any) {
    this.api.addItemsIntoHive(this.hiveId, {
      item_id: itemObject._id,
      prices: {
        rate_per_day: itemObject.rent_rate,
        rate_per_week: itemObject.rent_rate_per_week,
        rate_per_month: itemObject.rent_rate_per_month
      }
    }).subscribe((result) => {
      this.loadData();
    });
  }

  loadData(): void {
    this.api.getHive(this.hiveId).subscribe((data) => {
      this.hiveDetails = data.data;
      if (!this.hiveDetails.isOwn && this.isUserLoggedIn) {
        const isBee = this.hiveDetails.bees.find((i: any) => i.user_id === this.loggedInUser.userid || i.email === this.loggedInUser.email);
        this.isHiveEnroller = isBee === undefined;
      }
    });
    // let existing: any = hive.bees.find((i: any) => i.user_id.equals(userId) || i.email === emailId);


    this.route.paramMap.pipe(
      switchMap((params) => this.api.getHiveItems(params.get("id"))),
    ).subscribe(({data}) => {
      this.items = data;
      this.totalCount = this.items.length;
    });
  }


  loginUser() {
    this.router.navigate([AppRoutePaths.LOGIN_USER], {
      queryParams: {
        hiveId: this.hiveId
      }
    });
  }

  signUpUser() {
    this.router.navigate([AppRoutePaths.HOME_GUEST_REGISTER], {
      queryParams: {
        hiveId: this.hiveId
      }
    });
  }

  completeProfile() {
    this.updateProfile = false;
    this.stateService.setHiveId(this.hiveId);
    this.router.navigate([AppRoutePaths.MY_PROFILE]);
    // this.router.navigateByUrl(RoutePaths.MY_PROFILE, {hiveId: this.hiveId});
  }

  createItemFlow() {
    this.setupStripePopUp = !this.setupStripePopUp;
    this.createItem = !this.createItem;
  }

  navigateToSetupAccount() {
    this.setupStripePopUp = false;
    this.router.navigate(["users/account-verification"], {queryParams: {howToSetUpStripeFlag: true}});
  }

  cancelIsApproved() {
    this.isApproved = !this.isApproved;
  }

  cancelGuestUser() {
    this.guestUser = !this.guestUser;
  }

  cancelIsGuestUser() {
    this.isGuestUser = !this.isGuestUser;
  }

  cancelIsHiveEnroller() {
    this.isHiveEnroller = !this.isHiveEnroller;
  }

  enrollToHive() {
    this.api.joinHive(this.hiveId).subscribe((result) => {
      console.warn(result);
      this.hiveDetails = result.data;
      this.toast.success("successfully enrolled to hive");
      this.cancelIsHiveEnroller();
    }, (error) => {
      console.warn(error);
      this.toast.success("something went wrong");
    });
  }

  existingItem() {
    if (this.isUserLoggedIn) {
      this.user.getUser()
        .then((user) => {
          if (user["profileUpdated"]) {
            if (this.hiveDetails.isOwn) {
              this.router.navigate([`/users/my-hive/${this.hiveId}/manage-items`]);
            } else {
              // const userId = this.loggedInUser.userid;
              // const emailId = this.loggedInUser.email;
              const existing: any = this.hiveDetails.bees.find((i: any) => i.user_id === this.loggedInUser.userid || i.email === this.loggedInUser.email);
              if (existing.status === "accepted") {
                this.router.navigate([`/users/my-hive/${this.hiveId}/manage-items`]);
              } else if (existing.status === "enrolled") {
                this.isApproved = !this.isApproved;
              } else {
                this.toast.error("You dont have permission to create item");
              }
            }
          } else {
            this.updateProfile = true;
          }
        });
    } else {
      this.guestUser = !this.guestUser;
    }
  }

  onChangePage(number: number) {

  }
}
