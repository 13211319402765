<section style="justify-content:center; display:flex; align-items: center;flex-direction: column">
  <div style="width: 90%; overflow: auto; height: 400px; padding: 10px 30px;">
    <p class="t_r_c">
      Before you list something, you must be able to answer the following truthfully:
    </p>
    <p class="t_r_c">
      I have not been convicted of or charged with any offence other than motoring offences in the past 5 years.
    </p>
    <p class="t_r_c">
      I have not been subjected to a county court judgement (CCJ), a debt relief order (DRO), an individual
      voluntary agreement (IVA) or to bankruptcy proceedings.
    </p>
    <p class="t_r_c">
      I have not made more than two personal insurance claims in the past 5 years.
    </p>
    <p class="t_r_c">
      I have read and understood the full sharebee terms rules and conditions.
    </p>
    <p class="t_r_c">
      I have read and understood the Sharebee Item Protection
    </p>
    <p class="t_r_c">
      Your item listing must:
    </p>
    <ul>
      <li class="t_r_c">
        Be honest and true
      </li>
      <li class="t_r_c">
        Clearly describe any defects or errors
      </li>
      <li class="t_r_c">
        Give reasonable usage instructions where required (this could be a web link) and explain any restrictions
        and cautions that the borrower may need to use the item safely and happily.
      </li>
      <li class="t_r_c">
        Include a true indication of the item value in the ‘private item information’ section. We use this value to
        ensure we can offer Sharebee Item Protection to all items on our platform. Please be aware, the value you
        give may not be the value you receive in the event of a loss.
      </li>
    </ul>
    <p class="t_r_c">
      You agree:
    </p>
    <ul>
      <li class="t_r_c">
        To keep a record of your communication with the borrower and make this available to us in the event of a
        dispute.
      </li>
      <li class="t_r_c">
        That by accepting a booking, the item will be available to be borrowed on the requested dates and that if
        for any reason this is not possible you will refund the borrower in full.
      </li>
      <li class="t_r_c">
        To inform us via email to claims@sharebee.co.uk as soon as an item is returned damaged or reported lost by
        the borrower. Any claims made more than 8 hours after the end of the borrowing period will not be valid.
      </li>
      <li class="t_r_c">
        To make any items returned broken or damaged immediately available to us for the purposes of assessment,
        repair or replacement.
      </li>
    </ul>
    <p class="t_r_c">
      For the avoidance of doubt, you should make a record of the condition of your item immediately prior to
      lending it. This should include images and video of the item performing its primary function taken using a
      smartphone or time and date-stamped camera.
    </p>
    <p class="t_r_c">
      You understand that if your item is lost, damaged or stolen, we will do our best to get it repaired or
      replaced as quickly as possible under Sharebee Item Protection but, in some cases, this may take up to 30
      days.
    </p>
  </div>
  <ng-content></ng-content>
</section>
