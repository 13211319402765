import {Injectable} from "@angular/core";
import { Router } from "@angular/router";
import {Subject} from "rxjs";
import { parseJWTCookie } from "src/app/utils/backend-jwt";
import {ApiService} from "./api.service";
import {ErrorCodeHandler} from "./error.code.handler";

/**
 * User roles
 */
export enum Role {
  ADMIN = "admin",
  USER = "user",

  /**
   * Default role for any user who is not logged-in
   */
  GUEST = "guest"
}


export interface UserInterface {
  email?: string;
  role?: Role;
  token?: string;
  sub?: string;
}

@Injectable()
export class UserServices {
  private user: UserInterface;
  public isUserLoggedIn: Subject<boolean> = new Subject<boolean>();

  constructor(private api: ApiService, private errorHandler: ErrorCodeHandler, private router: Router) {
  }

  public logout() {
    return this.api.logout()
      .toPromise()
      .catch(({error}) => {
        this.errorHandler.handleErrorCode(error);
        throw error;
      })
      .finally(() => {
        this.clearUser();
        this.router.navigateByUrl("/", {skipLocationChange: true}).then(() => {
          this.router.navigate([this.router.url]);
        });
      });
  }

  /**
   * @description
   */
  public getUser(): Promise<UserInterface> {

    return Promise.resolve(this._getUser())
      .then((user) => user || this.getGuestUser())
      .then((user: UserInterface) => {
        this.user = user;

        return this.user;
      });
  }

  private _getUser(): Promise<UserInterface> {
    this.user = this.user || parseJWTCookie();

    return Promise.resolve(this.user);
  }

  public checkUserLoggedIn(): Promise<boolean> {
    return this.getUser()
      .then((user: UserInterface) => {
        return user.role !== Role.GUEST;
      });
  }

  /**
   * @description
   * Store user data in cookie
   *
   * param user
   */
  public setUser(user: any): void {
    this.user = user;
    this.isUserLoggedIn.next(user.role !== Role.GUEST);
  }

  /**
   * @description
   * Get the default guest user
   */
  public getGuestUser(): Promise<UserInterface> {
    const user: UserInterface = {
      email: null,
      role: Role.GUEST,
      token: null
    };


    return Promise.resolve(user);
  }

  public clearUser(): void {
    this.user = undefined;
    this.isUserLoggedIn.next(false);
  }
}
