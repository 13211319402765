import {Injectable} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {HiveApiService} from "./hive-api.service";
import {map, switchMap} from "rxjs/operators";

@Injectable()
export class HiveService {

  constructor(protected router: Router, private api: HiveApiService) {
  }

  getActiveHive(route: ActivatedRoute) {
    return route.paramMap
      .pipe(
        switchMap((params) => {
          console.warn(params.get("id"));
          const id = params.get("id");
          return this.api.getHive(id);
        }),
        map(({data}) => data)
      );
  }
}


