import {NgxSpinnerModule} from "ngx-spinner";
import {AuthEndpoints} from "./shared/constants/endpoints.service";
import {LibSharedModule} from "../usermodule/src/lib/shared/lib-shared.module";
import {ModuleWithProviders, NgModule} from "@angular/core";
import {AuthConfig, ConfigService} from "./shared/config.service";
import {ResetPasswordComponent} from "./reset-password/reset-password.component";
import {ForgotPasswordComponent} from "./forgot-password/forgot-password.component";
import {AccessGuard} from "./shared/access.guard";
import {RbacService} from "./shared/rbac/rbac.service";
import {UserServices} from "./shared/user.services";
import {ApiService} from "./shared/api.service";
import {ResourcesService} from "./configs/resources.config";
import {RbacConfigService} from "./configs/rbac.config";
import {ImageFormComponent} from "./shared/image-form-component/image-form-component";
import {TranslateModule} from "@ngx-translate/core";
import {InviteComponent} from "./invite/invite.component";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ProfileComponent} from "./profile/profile.component";
import {ErrorCodeHandler} from "./shared/error.code.handler";
import {ActivatedComponent} from "./activated/activated.component";
import {PopupLayoutComponent} from "../user-auth/pop-up-component/popup-layout/popup-layout.component";
import {
  PopupHelperComponent,
  ViewModalComponent
} from "../user-auth/pop-up-component/view-modal/view-modal.component";
import {ContainerComponent} from "../user-auth/pop-up-component/container/container.component";
import {PopupService} from "../user-auth/pop-up-component/popup.service";
import {TermsConditionComponent} from "../user-auth/TC/terms-condition.component";
import {SharedModule} from "../shared/shared.module";

@NgModule({
  imports: [
    NgxSpinnerModule,
    LibSharedModule,
    TranslateModule.forChild(),
    BrowserAnimationsModule,
    SharedModule
  ],
  declarations: [
    ImageFormComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    InviteComponent,
    ProfileComponent,
    ActivatedComponent,
    ContainerComponent,
    ViewModalComponent,
    PopupHelperComponent,
    PopupLayoutComponent,
    TermsConditionComponent
  ],
  exports: [
    ImageFormComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    InviteComponent,
    ProfileComponent,
  ],
  providers: [
    PopupService,
    AuthEndpoints,
    AccessGuard,
    RbacService,
    UserServices,
    ApiService,
    ConfigService,
    ResourcesService,
    RbacConfigService,
    ErrorCodeHandler,
    { provide: "ResourcesService", useExisting: ResourcesService }
  ],
  entryComponents: []
})
export class UserModule {
  constructor(private config: AuthConfig) {
  }
  public static forRoot(config: AuthConfig): ModuleWithProviders<UserModule> {
    return {
      ngModule: UserModule
      , providers: [
        {provide: AuthConfig, useValue: config}
      ]
    };
  }
}
