import { ViewChild } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "../../../app/user-auth/shared/api.service";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-invite-codes",
  templateUrl: "./invite-codes.component.html",
  styleUrls: ["./invite-codes.scss"]
})
export class InviteCodesComponent implements OnInit {
  public registerFormBuilder = [
    {
      key: "code",
      label: "Invite Code",
      type: "text",
      value: "",
      placeholder: "invite code",
    },
  ];

  public inviteCodes: Observable<any[]>;
  @ViewChild("dynamic_form", { static: true}) dynamic_form;

  public error = false;

  constructor(private apiService: ApiService) {}

  ngOnInit() {
    this.inviteCodes = this.apiService.listInviteCodes();
  }

  addCode(data) {
    this.apiService.addInviteCode(data.code).
      subscribe(
        res => {
          // cheap way without having a service provider and exposing the observable
          this.inviteCodes = this.apiService.listInviteCodes();
          this.dynamic_form.form.reset();
        },
        err => console.warn(err, "err")
      );
  }

  deleteCode(code) {
    this.apiService.deleteInviteCode(code).
      subscribe(
        res => {
          // cheap way without having a service provider and exposing the observable
          this.inviteCodes = this.apiService.listInviteCodes();
        },
      );
  }
}
