<section>
  <div class="create-hive-input">
    <div>
      <label class="padding-bottom-5">hive avatar</label>
      <div class="item-list__image" *ngIf="!isReadOnly">
        <img class="hive_image_view" [src]="imagePath" onError="this.src='assets/img/no_image_available.jpeg'">
      </div>

      <div class="item-list__image" *ngIf="isReadOnly">
        <file-uploader
          class="hive-item-file-uploader"
          [upload_url]="uploadImage"
          [file_formats]="['jpg', 'jpeg','JPEG','JPG','png', 'PNG']"
          [file_type]="['image']"
          [allow_retry]="true"
          [max_file_size]="1e+9"
          [disabled]=""
          [img_path]="imagePath"
          [crop_disable]=true
          [(isFileUploaded)]="isFileUploaded"
          (fileUploadTrigger)="imageUploading($event)"
          (onUploadDone)="onFileUploadDone($event,0)"
          (onValidationError)="safeCall($event)">
          <div class="uploader-title">
            <div class="image--placeholder"></div>
            <!--<img src="../../../assets/svg/plus.svg">-->
            <!--Drag and drop or select-->
          </div>
        </file-uploader>
      </div>
    </div>
    <div class="hive-name-container">
      <label>Name your hive</label>
      <input autocomplete="off" name="name" [readonly]="!isReadOnly" class="input-field" type="text">
      <div class="mt-2">
        <label>description</label>
        <textarea rows="3" [readonly]="!isReadOnly" class="hive_description add_text-area__padding"
                  [(ngModel)]="description"></textarea>
      </div>
    </div>
  </div>
  <div class="hive_button mt-2" *ngIf="isReadOnly">
    <!--<button class="bee-btn bee-btn__primary" (click)="updateHive()">
      {{buttonTitle}}
    </button>-->
    <bee-btn type="primary" (click)="updateHive()">{{buttonTitle}}</bee-btn>
  </div>
</section>
