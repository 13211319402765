<section>
  <div class="my-activity-page">
    <!-- shared items -->
    <div class="shared-items-container">
      <h2 class="shared-items_title">
        {{ 'MY_ACTIVITY_PAGE.LENDING_TITLE' | translate}}
      </h2>
      <div class="shared-items-box">
        <app-shr-item-card class="item-card" *ngFor="let item of shardItems | slice:0:showSharedItems; let i=index"
                            [title]="item.title"
                            [borrowerName]="item?.renter_details?.email"
                            [img_path]="item.images[0] || ''"
                            [item_current_status]="item.current_status"
                            [item_return_date]="item.end_date"
                            (click)="showDetails(item)"
                            [item_pickup_date]="item.start_date"
                            [actions]="actions(item)">
        </app-shr-item-card>
      </div>

      <div class="button-wrapper">
        <bee-btn type="primary" *ngIf="shardItems.length != 0" (click)="showSharedItems = shardItems.length">
          view more
        </bee-btn>

        <div *ngIf="shardItems.length === 0">
          <p class="no-items-message">
            No item
          </p>
        </div>

      </div>
    </div>

    <!-- borrowed items -->
    <div class="borrowed-items-container" *ngIf="!onlyLending">
      <h2 class="borrowed-items_title">
        {{ 'MY_ACTIVITY_PAGE.BORROWING_TITLE' | translate}}
      </h2>
      <div class="borrowed-items-box">

        <app-shr-item-card class="item-card"
                            *ngFor="let item of borrowedItems | slice:0:showBorrowedItems; let i=index"
                            [title]="item.title"
                            [img_path]="item.images[0] || ''"
                            [item_current_status]="item.current_status"
                            [item_return_date]="item.end_date"
                            (onClick)="openItemDetails(item)"
                            [item_pickup_date]="item.start_date"
                            [actions]="borrowAction(item)">
        </app-shr-item-card>
      </div>
    </div>

    <div *ngIf="!onlyLending">
      <div class="button-wrapper">
        <bee-btn type="primary" *ngIf="borrowedItems.length != 0"
                  (click)="showBorrowedItems = borrowedItems.length">
          view more
        </bee-btn>
        <div *ngIf="borrowedItems.length === 0">
          <p class="no-items-message">
            No item
          </p>
        </div>
      </div>
    </div>

    <!--REQUESTED ITEMS-->
    <div class="borrowed-items-container" *ngIf="!onlyLending">
      <h2 class="borrowed-items_title">
        {{ 'MY_ACTIVITY_PAGE.REQUESTED_ITEMS' | translate}}
      </h2>
      <div class="borrowed-items-box">

        <app-shr-item-card class="item-card" *ngFor="let item of requestedItems | slice:0:4; let i=index"
                            [title]="item.title"
                            [img_path]="item.images[0] || ''"
                            [item_current_status]="item.current_status"
                            [item_return_date]="item.end_date"
                            (onClick)="openItemDetails(item)"
                            [item_pickup_date]="item.start_date">
        </app-shr-item-card>
      </div>

      <div *ngIf="requestedItems.length === 0">
        <p class="no-items-message">
          No item
        </p>
      </div>

    </div>
  </div>
</section>

<app-confirmation-popup
  *ngIf='statusUpdate'
  (confirm)='statusUpdate = !statusUpdate'
>
  Status has been updated successfully
</app-confirmation-popup>

<popup-layout *ngIf="itemDeletedFlag">
  <div class="my-activity-item__popup">
    <label class="label sm1 make-it-black">
      Item has been deleted by the owner
    </label>
    <div class="my-activity-item__popup-actions">
      <!--          <button (click)="itemDeletedFlag = !itemDeletedFlag" class="button active">{{'CLOSE' | translate}}</button>-->
      <bee-btn type="primary" (click)="itemDeletedFlag = !itemDeletedFlag">{{'CLOSE' | translate}}</bee-btn>
    </div>
  </div>
</popup-layout>

<popup-layout *ngIf="showOrderSummary">
  <div class="item-booking__popup">
    <label class="label md1 bold-font popup-title">{{'ORDER.ORDER_SUMMARY' | translate}}</label>
    <div>
      <div class="order-summary-details"><label class="label o8 quad">item</label><label
        class="label o8 quad">{{item_data.item.title}}</label></div>
      <div class="order-summary-details"><label class="label o8 quad">duration</label>
        <label class="label o8 quad">{{item_data?.otherInfo.duration}}
          <span *ngIf="item_data?.otherInfo.duration === 1">day</span>
          <span *ngIf="item_data?.otherInfo.duration != 1">days</span></label>
      </div>
      <div class="order-summary-details">
        <label class="label o8 quad">day rate</label>
        <label class="label o8 quad">£{{rate(item_data.item.rent_rate)}}</label>
      </div>
      <div class="order-summary-details" *ngIf="item_data.item.rent_rate_per_week > 0">
        <label class="label o8 quad">week rate</label>
        <label class="label o8 quad">£{{rate(item_data.item.rent_rate_per_week)}}</label>
      </div>
      <div class="order-summary-details" *ngIf="item_data.item.rent_rate_per_month > 0">
        <label class="label o8 quad">month rate</label>
        <label class="label o8 quad">£{{rate(item_data.item.rent_rate_per_month)}}</label>
      </div>
    </div>
    <div class="order-summary-details add-border mt-1"><label
      class="label o8 text_orange">{{'ORDER_TOTAL' | translate}}</label><label
      class="label o8 text_orange">£{{totalRent}}</label></div>
    <div class="popup-actions space__between mt-1">
      <bee-btn type="secondary" (click)="showOrderSummary = !showOrderSummary">Close</bee-btn>
      <bee-btn type="primary" (click)="makePayment()">{{ 'PAY' | translate}}£{{totalRent}}</bee-btn>
    </div>
  </div>
</popup-layout>

<popup-layout *ngIf="paymentSuccess">
  <div class="item-booking__popup">
    <label class="label md1 bold-font popup-title">{{'ORDER.PAYMENT_SUCCESSFUL' | translate}}</label>
    <label class="label o8 quad bold-font popup-title">{{'ORDER.PAYMENT_OF' | translate}}
      £{{totalRent}} {{'ORDER.PAYMENT_SUCCESS_POP_UP' | translate}}</label>
    <label class="label md quad bold-font popup-title">{{ item_data?.item?.title }}</label>
    <div class="popup-actions mt-1">
      <bee-btn type="primary" (click)="closePopUp('success')">Close</bee-btn>
    </div>
  </div>
</popup-layout>

<popup-layout *ngIf="paymentError">
  <div class="item-booking__popup">
    <label class="label md1 bold-font popup-title">{{'ORDER.PAYMENT_FAILURE' | translate}}</label>
    <label class="label o8 quad bold-font popup-title">{{'ORDER.PAYMENT_FAILURE_MESSAGE' | translate}}</label>
    <div class="popup-actions mt-1">
      <bee-btn type="primary" (click)="closePopUp('error')">Close</bee-btn>
    </div>
  </div>
</popup-layout>
