<section class="app-item-filter">
  <div class="category-filter">
    <div class="category-filter--list" (click)="toggleCategory()">
      <div class="category-filter--list-container">
        <span class="category_text">categories:</span>
      </div>
      <div>
        <span class="category__underline"></span>
      </div>
    </div>
    <div class="category-filter__container">
      <div class="category-filter__content" *ngFor="let category of categories; index as i">
      <div class="form-check">
        <input id="category={{category}}" class="form-check-input" type="checkbox" name="fruit" value="{{category}}" #cat (change)="applyFilter(category, $event)"/>
        <label for="category={{category}}" class="form-check-label" [ngClass]="{'set_checked': cat.checked}">{{category}}</label>
      </div>
      </div>
    </div>
  </div>
</section>
