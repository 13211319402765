import {Component, OnInit} from "@angular/core";
import {DomainResource} from "../user-auth/configs/config.helper";
import {Role} from "../user-auth/shared/user.services";
import {ItemApiService} from "../items/item-api.service";
import {ActivatedRoute} from "@angular/router";
import {OrderStatus} from "../shared/models/booking-status.model";


@DomainResource({
  name: "MY_ACTIVITY_DETAILS",
  access: [ Role.ADMIN, Role.USER ]
})
@Component({
  selector: "app-my-activity-details",
  templateUrl: "./my-activity-details.html",
  styleUrls: ["my-activity.component.scss"]
})
export class MyActivityDetailsComponent implements OnInit {
  public orderStatus: typeof OrderStatus = OrderStatus;
  labels = [ "borrower", "share start", "share end", "current status" ];
  items = [];
  item_id: string;
  item_name: string;
  statusUpdate: boolean;


  public status_change_sequence = [
    this.orderStatus.pending,
    this.orderStatus.payment_pending,
    this.orderStatus.payment_done,
    this.orderStatus.cancelled,
    this.orderStatus.owner_deliver,
    this.orderStatus.borrower_pickup,
    this.orderStatus.borrower_deliver,
    this.orderStatus.owner_pickup,
    this.orderStatus.done
  ];

  statusCategories = [
    {
      "label": "status",
      "options": [
        {
          "label": "pending",
          "key": this.orderStatus.pending
        },
        {
          "label": "make payment",
          "key": this.orderStatus.payment_pending
        },
        {
          "label": "payment done",
          "key": this.orderStatus.payment_done
        },
        {
          "label": "done",
          "key": this.orderStatus.done
        },
        {
          "label": "cancelled",
          "key": this.orderStatus.cancelled
        }]
    },

    {
      "label": "delivery options",
      "options": [
        {
          "label": "owner deliver",
          "key": this.orderStatus.owner_deliver},
        {
          "label": "borrower pickup",
          "key": this.orderStatus.borrower_pickup}]
    },
    {
      "label": "return options",
      "options": [
        {
          "label": "borrower deliver",
          "key": this.orderStatus.borrower_deliver
        },
        {
          "label": "owner pickup",
          "key": this.orderStatus.owner_pickup
        }]
    },
    {
      "label": "coming soon",
      "options": [
        {
          "label": "doorstep delivery",
          "key": "doorstep delivery"
        },
        {
          "label": "by sharebee",
          "key": "by sharebee"
        }]
    }
  ];


  constructor(private http: ItemApiService, private activated_router: ActivatedRoute) {}

  ngOnInit() {
    this.activated_router.queryParams.subscribe((query) => {
      this.item_id = query["id"];
      this.item_name = query["title"];
      this.load();
    });
  }

  load() {
    this.http.getItemActivityDetails({ id: this.item_id })
      .toPromise()
      .then((data) => this.onItemLoad({ data: data.data }));
  }

  onItemLoad({ data }) {
    this.items = data;
  }

  /**
   * Change status of booked item
   * @param1 order
   * @param2 {OrderStatus} status
   */
  changeStatus(order, status: OrderStatus) {
    this.http.changeItemStatus({
      order_id: order._id,
      status: status
    }).toPromise()
      .then((result) => {
        this.statusUpdate = !this.statusUpdate;
      })
      .catch(() => {});
  }

  isStatusAllowedToChange(item, status): boolean {
    return this.status_change_sequence.indexOf(status) > this.status_change_sequence.indexOf(item.current_status);
  }
}

