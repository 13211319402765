import {Component, OnInit} from "@angular/core";
import {NgxSpinnerService} from "ngx-spinner";
import {first} from "rxjs/operators";
import {config} from "src/config";
import {ApiService} from "../../user-auth/shared/api.service";
import {DomainResource} from "../../user-auth/configs/config.helper";
import {Role} from "../../user-auth/shared/user.services";
import {ImagePathService} from "../../shared/constants/image-path.service";
import {Router} from "@angular/router";
@DomainResource({
  name: "KPI",
  access: [Role.ADMIN]
})

@Component({
  selector: "app-why-kpi",
  templateUrl: "kpi.component.html",
  styleUrls: ["./kpi.component.scss"]
})

export class KpiComponent implements OnInit {
  url = config.base_url;
  downloadLink = "";
  header = [];
  KpiList = [
    {
      value: "month",
      name: "by month"
    },
    {
      value: "week",
      name: "by week"
    },
    {
      value: "day",
      name: "by day"
    }
  ];
  selectedKPI = "";
  RowsData: Array<any> = [];
  kpiObj: any;
  kpiList: Array<any>;

  constructor(private apiService: ApiService,
              public router: Router,
              public imageService: ImagePathService,
              private spinner: NgxSpinnerService) {
  }

  ngOnInit(): void {
    this.getKpi();
  }

  getKpi(): void {

    this.spinner.show();
    this.apiService.getKPI(this.selectedKPI)
      .pipe(first())
      .subscribe((content) => {
        this.spinner.hide();
        this.RowsData = content.filteredData;
        this.header = content.headers;
        this.downloadLink = content.downloadLink;
      }, (error) => {
        console.error("error", error);
        this.spinner.hide();
      });
  }

  kpiSelected(obj): void {
    const kpiObj = this.KpiList.find(template => template.name === obj.target.value);
    console.warn(kpiObj.value);
    this.selectedKPI = kpiObj.value;
    this.getKpi();
  }

  downloadCSV(): void {
    console.warn(this.downloadLink);
    window.location.href = this.url + this.downloadLink;
  }
}
