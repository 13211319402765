<div class="item-creation__popup">
  <div class="item-tc-pop-up">
    <p>Sharebee Item Protection (SIP) terms and conditions</p>
    <p>
      Covers items that have been lent out for a fee If the item is damaged,
      lost or stolen whilst being used by the borrower
    </p>
    <p>Items lent for free are not currently covered</p>

    <b>You:</b>
    <p>
      Must be able to prove that the item was working and in your possession
      before you lent it out. This should be with a date stamped video of the
      item preforming its primary function.
    </p>
    <p>Must provide a record of all communications with the borrower</p>

    <b>Once you have provided these things sharebee will:</b>
    <p>
      Repair it - repairing something is much better for the environment than
      throwing it away and buying something new so we will always try this
      first.
    </p>
    <p>
      If the item cannot be repaired, we will replace it with an item of similar
      age and condition.
    </p>
    <p>
      If the item cannot be repaired or replaced with a similar item, we will
      pay you the original purchase cost of the item.
    </p>
    <p>
      It is at sharebee’s discretion whether we pay out or organise you a
      replacement item.
    </p>

    <b>Sharebee will not:</b>
    <p>
      Replace old items with new ones unless we are unable to find a suitable
      similar replacement.
    </p>
    <p>
      Cover normal wear and tear, such as would happen in the normal use of the
      item. This includes but is not limited to: cosmetic damage that does not
      affect the functioning of the item; punctures; scratches; use of
      consumables; mud and dirt or other contamination; wearing out or failure
      of bearings, gears or other moving parts.
    </p>
    <p>Cover items over the value of £3500.</p>
    <p>Cover jewellery, watches or cash.</p>
    <p>Cover anything which requires a license to operate.</p>
    <p>
      Cover items listed for free. In the future we hope to cover items which
      are lent for free - we will let you know when this happens.
    </p>
    <p>
      If you need to make a claim, you must inform us via email to
      claims@sharebee.co.uk as soon as the item is returned or reported lost by
      the borrower. Any claims made more than 8 hours after the end of the
      borrowing period will not be valid.
    </p>
    <p>
      You understand that if your item is lost, damaged or stolen, we will do
      our best to get it repaired or replaced as quickly as possible under
      Sharebee Item Protection but, in some cases, this may take up to 30 days.
    </p>
    <p>
      If you have commercial insurance, you need to claim on this before you
      claim on Sharebee Item Protection. If your commercial insurance will not
      cover it, or your claim is rejected for any reason, you can claim on
      Sharebee Item Protection
    </p>
  </div>
  <div class="mt-2">
    <!--    <button class="button add-margin-10" (click)="exit()">{{'CLOSE' | translate}}</button>-->
    <bee-btn type="primary" (click)="exit()">Close</bee-btn>
  </div>
</div>
