import {Component, EventEmitter, OnInit, Output, ViewChild} from "@angular/core";
import {ApiService} from "../shared/api.service";
import {ToastrService} from "ngx-toastr";
import {NgxSpinnerService} from "ngx-spinner";
import {ErrorCodeHandler} from "../shared/error.code.handler";
import {ConfigService} from "../shared/config.service";
import {AuthEndpoints} from "../shared/constants/endpoints.service";
import {UserInterface, UserServices} from "../shared/user.services";
import {ImagePathService} from "../../shared/constants/image-path.service";
import { getIDToken } from "src/app/utils/user-token";

type DynamicField = {
  type: string;
  label?: string;
  key?: string;
  required: boolean;
  pattern?: { value: any, err_msg?: string} | RegExp;
  enum?: string[] | number[];
  unique?: boolean;
  placeholder?: string;
}

@Component({
  selector: "lib-app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"]
})

export class ProfileComponent implements OnInit {
  @Output() update = new EventEmitter<Object>();
  profileData: any;
  sipInfo = false;
  displayPrivacyPolicy = false;
  profileInfo = false;
  profileFieldsObj: any;
  // to make rows and columns of the fields
  configObject: any[][][];
  profileFieldConfig: any[];
  profileForm: any = {
    title: "Profile",
    buttonLabel: "Edit"
  };
  public mode: "edit" | "view";
  @ViewChild("formRef", { static: true}) inputForm;
  @ViewChild("myProfileView", { static: true}) myProfileView;
  public userObject: UserInterface;

  constructor(private apiService: ApiService,
              private toast: ToastrService,
              private spinner: NgxSpinnerService,
              private errorHandler: ErrorCodeHandler,
              private configs: ConfigService,
              public imageService: ImagePathService,
              public endPoint: AuthEndpoints,
              public userService: UserServices) {
  }

  ngOnInit(): void {
    // Call get config on Init
    this._getConfiguration();
  }

  /**
   * function to Get the configuration from server to construct the Profile
   **/
  _getConfiguration() {
    const token = getIDToken();

    this.spinner.show();
    this.apiService.getProfileConfig()
      .toPromise()
      .then((config) => {
        return this.apiService.getProfileDetails({userId: token.sub})
          .toPromise()
          .then((profile) => {
            this.profileData = {profileFields: config.profileFields, profile};
            return this.profileData;
          });
      })
      .then((combinedData) => {
        this.prepareConfiguration(combinedData);
      })
      .then(() => this.spinner.hide())
      .catch((c) => {
        // TODO add logging
        this.spinner.hide();
      });
  }

  private prepareConfiguration(data: { profileFields: DynamicField[], profile: any }) {
    const form = [];
    this.userService.getUser()
      .then((user: UserInterface) => {
        this.userObject = user;
        data.profileFields.forEach((field) => {

          form.push({
            key: field.key,
            label: field.label,
            type: field.enum ? "select" : this.getType(field.type),
            options: (field.enum || []).map(value => ({value, label: value})),
            value: field.key === "email" ? user.email : data.profile[field.key], // || this.getType(field.type) === 'file' && '/assets/img/logo.png',
            placeholder: field.placeholder || "",
            requiredFlag: field.required ? true : false,
            // Todo: email disable config should be sent from backend
            disabled: (field.key === "email") ? true : this.mode === "view",
            crop_disable: true,
            validators: {
              required: {value: !!field.required, err_msg: field.label + " is required"},
            },
            // pattern: {
            //   value: field.pattern.value,
            //   err_msg: field.pattern.err_msg
            // },
            pattern: field.pattern,
            endpoint: this.endPoint.PROFILE_IMAGE_UPLOAD,
            onFileUploadError: this.showError.bind(this)
          });
        });
      });


    form.push({
      key: "submit",
      value: this.mode === "view" ? "edit" : "update",
      type: "button",
      click: this.onAction.bind(this)
    });

    // this.mode === 'view' ? 'my profile' : 'Edit profile';
    this.profileForm.title = "my profile";
    this.profileForm.buttonLabel = this.mode === "edit" ? "Update" : "Edit";
    this.profileFieldConfig = form;
    this.configObject = this.configs.profile_form.structure;
  }

  /**
   * function to build dynamic form based on the Object received from backend
   **/
  getType(type) {
    switch (type) {
    case "String":
      return "text";
    case "Number":
      return "number";
    case "Boolean":
      return "boolean";
    case "File":
      return "file";
    case "Date":
      return "date";
    }
  }

  onAction(): void {
    if (this.mode === "edit") {
      this.inputForm.onSubmit();
      // this.updateProfile($event);
    } else {
      this.changeView("edit")
        .then(() => {
          this.prepareConfiguration(this.profileData);
        });
    }
  }

  /**
   * function to delete my account
   **/
  deleteMyAccount() {
    this.spinner.show();
    this.apiService.deleteMyAccount()
      .subscribe(
        (result: any) => {
          this.spinner.hide();
          // this.toastrService.success('Success!!', 'Email Sent Successfully');
        },
        ({error}) => {
          this.spinner.hide();
          this.errorHandler.handleErrorCode(error.type);
        }
      );
  }

  /**
   * function for users to signIn
   * @param data:<any>
   */
  updateProfile(data: any): void {
    // Todo: remove the email, email should be populated from backend
    data.email = this.userObject.email;
    this.spinner.show();
    this.apiService.updateProfile(data)
      .subscribe(
        (result: any) => {
          this.changeView("edit")
            .then(() => {
              Object.assign(this.profileData, {profile: result.data});
              this.prepareConfiguration(this.profileData);
              this.spinner.hide();
              // this.toastrService.success('Success!!', 'Profile edit successful');
              this.update.emit(data);
            });
        },
        ({error}) => {
          this.spinner.hide();
          this.errorHandler.handleErrorCode(error.type);
        }
      );
  }

  public showError(error: any) {
    this.toast.error(error.message, error.title);
  }

  private changeView(mode: "edit" | "view"): Promise<void> {
    this.mode = mode;
    this.profileFieldConfig = undefined;
    return new Promise(resolve => {
      setTimeout(() => {
        resolve();
      });
    });
  }

  exitProfileInfo() {
    this.profileInfo = !this.profileInfo;
  }
}
