import { Component } from "@angular/core";
import { DynamicFormInputInterface } from "../../usermodule/src/lib/shared/dynamic-form/dynamic-form-interface";
import { TranslateService } from "@ngx-translate/core";
import { ItemApiService } from "../../items/item-api.service";

import { NgxSpinnerService } from "ngx-spinner";
import {ToastrService} from "ngx-toastr";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-contact-us",
  templateUrl: "./contact-us.component.html",
  styleUrls: ["./contact-us.component.scss"]
})

export class ContactUsComponent {
  configFields: Array<DynamicFormInputInterface>;
  contactInfo = [];
  showSuccessPopUp: boolean;
  loginFormBuilder = [
    {
      key: "name",
      label: "name",
      type: "text",
      value: "",
      placeholder: "Your name",
      validators: {
        required: {value: true, err_msg: "Name is required"}
      }
    },
    {
      key: "email",
      label: "EMAIL",
      type: "email",
      value: "",
      placeholder: "hello@example.com",
      validators: {
        required: {value: true, err_msg: "Email is required"},
        email: {value: true, err_msg: "Enter a valid email address"}
      }
    }, {
      key: "message",
      label: "message",
      type: "textarea",
      placeholder: "",
      value: "",
      validators: {
        required: {value: true, err_msg: "Message is required"},

      }
    }
  ];

  constructor(public translate: TranslateService,
              public apiService: ItemApiService,
              public spinner: NgxSpinnerService,
              private title: Title,
              private toast: ToastrService) {
    this.title.setTitle("Contact Us | Sharebee");
    this.contactInfo = [
      {label: "general enquiries:", key: "hello@sharebee.co.uk"},
      {label: "help and support:", key: "help@sharebee.co.uk"},
      {label: "sharebee items protection claims:", key: "claims@sharebee.co.uk"}
    ];
  }

  mailToLink(link: string): string {
    return "mailto:" + link;
  }

  /**
   * Submit form
   * @param1 event
   */
  submitForm(event) {
    this.spinner.show();
    this.apiService.submitContactUsForm(event)
      .subscribe((response) => {
        this.spinner.hide();
        this.showSuccessPopUp = true;
      },
      (error) => {
        this.spinner.hide();
        this.toast.error(error.code);
      });
  }

  /**
   * Close pop us
   */
  closePopUp() {
    this.showSuccessPopUp = !this.showSuccessPopUp;
  }

}
