import { Component } from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {ImagePathService} from "../../shared/constants/image-path.service";
import {EndpointsService} from "../../shared/constants/endpoints.service";
import {DomainResource} from "../../user-auth/configs/config.helper";
import {Role} from "../../user-auth/shared/user.services";
import { Title } from "@angular/platform-browser";

@DomainResource({
  name: "REQUEST_COMMUNITY",
  access: [ Role.USER, Role.ADMIN ]
})

@Component({
  selector: "app-request-community",
  templateUrl: "./request-community.component.html",
  styleUrls: ["./request-community.component.scss"]
})
export class RequestCommunityComponent {
  //todo -- figure out what this is about
  items = [1, 2, 3, 4];
  itemObject = {title : "",
    description: "",
    user: "",
    src: "",
    images: [],
    _id: ""
  };
  api_host: string;

  requestUploadPopUp: boolean;
  createItem = false;

  constructor(public translate: TranslateService,
              public imageService: ImagePathService,
              private title: Title,
              public endPoints: EndpointsService) {
    this.title.setTitle("Request Community | Sharebee");
    this.api_host = this.endPoints.GET_COMMUNITY_REQUESTS;
  }

  requestUpload(item) {
    this.itemObject = item;
    this.itemObject.src = this.itemObject.images[0];

    this.requestUploadPopUp =  !this.requestUploadPopUp;
  }

  requestItem() {
    this.createItem = !this.createItem;
  }
}
