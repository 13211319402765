import { Component } from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import { Router } from "@angular/router";
import { ImagePathService } from "src/app/shared/constants/image-path.service";
import { AppRoutePaths } from "@core/constants/route.path";
import { checkUserLoggedIn } from "src/app/utils/backend-jwt";

@Component({
  selector: "app-my-hive",
  templateUrl: "./my-hive.component.html",
  styleUrls: ["./myhive.component.scss"]
})

export class MyHiveComponent {

  isUserLoggedIn = false;
  showHiveLogin = false;

  constructor(private router: Router,
              public translate: TranslateService,
              public imageService: ImagePathService) {
    if (checkUserLoggedIn()) {
      this.isUserLoggedIn = true;
    }

  }
  goToHive() {
    this.router.navigate([AppRoutePaths.MY_HIVE]);
    /*if (!this.isUserLoggedIn) {
      this.showHiveLogin = true;
    } else {
      this.router.navigate([RoutePaths.MY_HIVE]);
    }*/
  }


  loginUser() {
    this.router.navigate([AppRoutePaths.LOGIN_USER], {
      queryParams: {
        redirectRoute: AppRoutePaths.MY_HIVE
      }
    });
    this.showHiveLogin = false;
  }

  signUpUser() {
    this.router.navigate([AppRoutePaths.HOME_GUEST_REGISTER], {
      queryParams: {
        redirectRoute: AppRoutePaths.MY_HIVE
      }
    });
    window.scroll(0, 0);
    this.showHiveLogin = false;
  }


}
