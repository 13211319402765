import { SearchAreaComponent } from "./search-filter/search/search-area.component";
import {NgModule} from "@angular/core";
import {CreateItemComponent} from "./create-item/create-item.component";
import {PopupLayoutComponent} from "../shared/pop-up/popup-layout/popup-layout.component";
import {PopupService} from "../shared/pop-up/popup.service";
import {SharedModule} from "../shared/shared.module";
import {ImageCropperModule} from "ngx-image-cropper";
import {UploaderModule} from "src/app/uploader/src/lib/uploader.module";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {ItemApiService} from "./item-api.service";
import {TranslateModule} from "@ngx-translate/core";
import {SearchComponent} from "./search-filter/search/search.component";
import {FilterComponent} from "./search-filter/filter/filter.component";
import {MyStuffComponent} from "./my-stuff/my-stuff.component";
import {FindSomethingComponent} from "./search-filter/find-something/find-something.component";
import {RequestItemComponent} from "./request-item/request-item.component";
import {NgxSpinnerModule} from "ngx-spinner";
import {NgxPaginationModule} from "ngx-pagination";

@NgModule({
  imports: [
    SharedModule,
    ImageCropperModule,
    UploaderModule,
    CommonModule,
    FormsModule,
    NgxSpinnerModule,
    TranslateModule.forChild(),
    NgxPaginationModule
  ],
  exports: [
    SearchComponent,
    SearchAreaComponent,
    FilterComponent,
    MyStuffComponent,
    FindSomethingComponent,
    RequestItemComponent
  ],
  declarations: [
    FilterComponent,
    SearchComponent,
    SearchAreaComponent,
    MyStuffComponent,
    FindSomethingComponent,
    RequestItemComponent
  ],
  providers: [
    ItemApiService
  ]
})
export class ItemModule {

}
