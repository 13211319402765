import { Component } from "@angular/core";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import { checkUserLoggedIn } from "src/app/utils/backend-jwt";
import {ImagePathService} from "../../../shared/constants/image-path.service";

@Component({
  selector: "app-community",
  templateUrl: "./community.component.html",
  styleUrls: ["./community.component.scss"]
})

export class ColonyComponent {
  isUserLoggedIn = false;

  constructor(private _router: Router,
              public translate: TranslateService,
              public imageService: ImagePathService) {
    this.isUserLoggedIn = checkUserLoggedIn();
  }
  goToColonyPage() {
    this._router.navigate(["request/community"]);
  }

}
