import {Component} from "@angular/core";
import { Title } from "@angular/platform-browser";
import {DomainResource} from "../user-auth/configs/config.helper";
import {Role} from "../user-auth/shared/user.services";

@DomainResource({
  name: "HIVE_ACTIVITY_DETAILS",
  access: [Role.ADMIN, Role.USER]
})
@Component({
  selector: "app-my-activity-container",
  templateUrl: "./my-activity-container.html",
  styleUrls: ["my-activity.component.scss"]
})
export class MyActivityContainerComponent {
  constructor(private title: Title){
    this.title.setTitle("My Activity | Sharebee");
  }
}
