// This file can be replaced during build by using the `fileReplacements` array.
// The list of file replacements can be found in `angular.json`.

import { ConfigInterface  } from "./interface";
import {SignupMethods} from "../app/user-auth/shared/config.service";
import { AppRoutePaths } from "@core/constants/route.path";

export const config: ConfigInterface = {
  base_url: process.env.URL || "",
  api_host: process.env.API_HOST || "",
  production: process.env.APP_ENV === "prod",
  shareBeeInterest: 0.15, // value is in decimal as it's in percentage
  AUTH_COOKIE_KEY: "session_jwt",
  user_module_config: {
    base_url: process.env.URL || "",
    signup_mode: SignupMethods.INVITE,
    post_signin_redirection: AppRoutePaths.MY_PROFILE,
    post_signup_redirection: AppRoutePaths.HOME_GUEST,
    post_reset_redirection: AppRoutePaths.HOME_GUEST,
    guest_access_denied_redirection: AppRoutePaths.HOME_GUEST,
    user_access_denied_redirection: AppRoutePaths.HOME_GUEST,
    admin_access_denied_redirection: AppRoutePaths.HOME_GUEST,
    post_forgot_password_redirection:
      "users/auth/password-recovery-notification",
    password_reset_token_visibility: "true",
    profile_form: {
      form: [],
      // Config to build profile
      structure: [
        [
          [[["name", "lastName"], "beeName"], "profile_photo"],
          [
            ["email", "address", "mobile"],
            ["date_of_birth", "city", "postalCode"],
          ],
          [["submit"]],
        ],
        // ['name', 'email', 'mobile', 'address'],
        // ['profile_photo', 'date_of_birth', 'submit']
      ],
    },
  },
  emailRegex: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
