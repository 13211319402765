<app-header-footer-layout class="app-my-list">

  <!--  <item-list [URL]="api_endpoint" [items_per_page]="0" [limit]="0" #itemlist (setupStripe)="setupStripeAccount()">-->
  <!--(setupStripe)="setupStripePopUp = true">-->
  <div class="item__list-container">
    <div class="list-container">
      <div class="list-items">

        <div class="create-item__tile show--cursor item-card" (click)="createNewItem()">
          <div class="create-item__icon">
          </div>
          <div class="create-item_info">
            <p>{{"MY_ACTIVITY_PAGE.UPLOAD_SOMETHING" | translate}}</p>
          </div>
        </div>

        <!--<p>{{itemlist.items.length}}</p>-->
        <ng-container *ngFor="let item of itemList['items'] | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalCount}; let i = index;">
          <app-shr-item-card class="item-card show--cursor"
                             [title]="item.title"
                             [img_path]="item.images[0] || ''"
                             [item_rate]="item.rent_rate"
                             [item_location]="item.location"
                             (onClick)="navigateTo(item)"
                             [actions]="[ deleteAction(item) ]"
          ></app-shr-item-card>
        </ng-container>
      </div>
    </div>
    <div class="add__pd top sp30 text-align-center">
      <pagination-controls class="my-pagination"
                           (pageChange)="onChangePage(currentPage = $event)"></pagination-controls>
    </div>
  </div>
  <!--  </item-list>-->
</app-header-footer-layout>

<app-create-item *ngIf="createItem" (createItemFlag)="createItem = false; getItems()">
</app-create-item>

<!--<popup-layout *ngIf="setupStripePopUp">
  <div class="my-stuff__popup align_center">
    <label class="label md1 bold-font popup-title align_center">{{'ITEM_DETAILS.STRIPE_SETUP_REQUIRED'  | translate}}</label>
    <div class="label sm-1 make-it-black mt-1 align_center">
      {{'MY_ACTIVITY_PAGE.STRIPE_SETUP_LINE_1' | translate}}
      <br/>
      {{'MY_ACTIVITY_PAGE.STRIPE_SETUP_LINE_2' | translate}}
      <br/>
      {{'MY_ACTIVITY_PAGE.STRIPE_SETUP_LINE_3' | translate}}
    </div>
    <div class="my-stuff__actions mt-1">
      <button (click)="setupStripePopUp = !setupStripePopUp" class="button">{{'CANCEL' | translate}}</button>
      <button (click)="navigateToSetupAccount()" class="button active">{{'SETUP_STRIPE' | translate}}</button>
    </div>
  </div>
</popup-layout>-->

<popup-layout *ngIf="setupStripePopUp">
  <div class="my-stuff__popup align_center">
    <label class="label md1 bold-font popup-title align_center">Stripe Setup</label>
    <div class="label sm-1 make-it-black mt-1 align_center">
      To receive payments for your item you will need to set up stripe.
    </div>
    <div class="label sm-1 make-it-black mt-1 align_center">
      Setting this up will take 5 minutes.
    </div>
    <div class="my-stuff__actions mt-1">
      <bee-btn type="secondary" (click)="createItemFlow()">Setup later</bee-btn>
      <bee-btn type="primary" (click)="navigateToSetupAccount()">Setup now</bee-btn>
    </div>
  </div>
</popup-layout>


<popup-layout *ngIf="item_deleted">
  <div class="item-booking__popup">
    <label class="label md1 bold-font popup-title">{{'MY_ACTIVITY_PAGE.ITEM_DELETED' | translate}}</label>
    <div class="popup-contents" align="center">

      {{'MY_ACTIVITY_PAGE.ITEM_DELETED_MSG' | translate}}
    </div>
    <div class="popup-actions">
      <!--      <button (click)="item_deleted = false" class="button active">{{'DONE' | translate}}</button>-->
      <bee-btn type="primary" (click)="item_deleted = false">Done</bee-btn>
    </div>
  </div>
</popup-layout>

<popup-layout *ngIf="updateProfile">
  <div class="item-booking__popup">
    <label class="label md1 bold-font popup-title">Complete Your Profile</label>
    <div class="sm-1 make-it-black mt-1 align_center">
      Complete your profile before you add your item or request to borrow something,
    </div>
    <div class="label sm-1 make-it-black mt-1 align_center">
      this is important to create trust in our community
    </div>
    <div class="popup-actions">
      <bee-btn type="secondary" [size]="'medium'" [block]="true" (click)="updateProfile = false">Later</bee-btn>
      <bee-btn type="primary" [size]="'medium'" [block]="true" (click)="completeProfile()">Setup Profile</bee-btn>
    </div>
  </div>
</popup-layout>


<popup-layout *ngIf="delete_item">
  <div class="item-booking__popup">
    <label class="label md1 bold-font popup-title">Delete listing</label>
    <div class="popup-contents" align="center">
      are you sure you want to delete your listing
    </div>
    <div class="popup-actions space__between">
      <!--      <button (click)="closeDelete()" class="button">close</button>-->
      <!--      <button (click)="deleteItem(delete_item)" class="button active">delete</button>-->
      <bee-btn type="secondary" (click)="closeDelete()">Close</bee-btn>
      <bee-btn type="primary" (click)="deleteItem(delete_item)">Delete</bee-btn>
    </div>
  </div>
</popup-layout>
