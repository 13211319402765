import {Component, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation} from "@angular/core";
import {ApiService} from "../shared/api.service";
import {UserServices} from "../shared/user.services";
import {NgxSpinnerService} from "ngx-spinner";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {PullTranslationService} from "../../usermodule/src/lib/shared/translate.service";

@Component({
  selector: "app-invite",
  templateUrl: "./invite.component.html",
  styleUrls: ["./invite.scss"],
  encapsulation: ViewEncapsulation.None
})
export class InviteComponent implements OnInit {
  @ViewChild("invite_form", { static: true}) invite_form;
  @Output() onSuccess = new EventEmitter();

  translatedObj: any;
  loginFormBuilder = [
    {
      key: "email",
      label: "email",
      type: "email",
      value: "",
      placeholder: "info@example.com",
      validators: {
        required: {value: true, err_msg: "Email is required"},
        email: {value: true, err_msg: "Enter a valid email address"}
      }
    }
  ];

  constructor(private apiService: ApiService,
              private user: UserServices,
              private spinner: NgxSpinnerService,
              private toastrService: ToastrService,
              private translateService: PullTranslationService,
              public translate: TranslateService) {
  }

  ngOnInit(): void {

    this.translateService
      .getComponentTranslationStrings(InviteComponent)
      .subscribe((translatedLabels) => {
        this.loginFormBuilder = this.loginFormBuilder.map(value => {
          return Object.assign(value, {label: translatedLabels[value.label]});
        });
      }, () => {
        console.warn("Unable to retrieve Translation");
      });
  }

  /**
   * Function for users to signIn
   * param data:<any>
   */
  signIn(data: any): void {
    this.spinner.show();
    const params: any = {
      email: data.email
    };
    this.apiService.invite(params)
      .subscribe(
        (result: any) => this.inviteSuccess(result),
        (error) => this.inviteError(error)
      );
  }

  /**
   * Function to handle data if sign in successfully
   * param result:<any>
   */
  inviteSuccess(result: any): any {
    this.spinner.hide();
    // TODO: Add toast message to i18 en.json file
    this.toastrService.success("User has been invited successfully!", "");
    this.onSuccess.emit();
  }

  /**
   * Function to handle data if sign in failed
   * param err:<any>
   */
  inviteError(error: any): any {
    this.spinner.hide();
    this.toastrService.error("Error", "Something went wrong");
    console.error("error", JSON.stringify(error));
  }

  invite() {
    this.invite_form.submit();
  }
}
