<app-header-footer-layout [show_sidebar]="false" [blank]="true" [btn_visible]="'sign_up'">
  <section class="app-auth-user-login">
    <div class="login-container">
      <div class="input-container">
          <div class="mt-5">
            <span>Don’t have an account? <a data-testid="signup-link" class="bee-btn bee-btn__tertiary" href="/register">SIGN UP</a></span>
          </div>
        <h1 class="">login</h1>
        <div class="login-capsule mt-2">
          <form class="w-100">
            <label class="input--label">Email</label>
            <input autocomplete="email" placeholder="buzz@lightyear.com" type="text" class="input--field" [(ngModel)]="emailId">
            <label class="input--label mt-2">Password</label>
            <input autocomplete="current-password" placeholder="e.g., ***********" type="password" [(ngModel)]="password" class="input--field">
            <div class="text-align-center mt-2">
              <label class="show--cursor forgot-pwd-txt" (click)="handleForgotPassword()">Forgot your password?</label>
            </div>
            <div class="btn-container mt-5 justify-content-center">
              <bee-btn type="primary" (click)="signIn()">login</bee-btn>
            </div>
          </form>
        </div>
      </div>
      <div class="img--container">
        <img alt="login_placeholder_image" class="img-preview" src="../../../assets/svg/login.svg">
      </div>
    </div>
  </section>
</app-header-footer-layout>
