// todo, add all route paths to enum
export enum AppRoutePaths {
  CONTACT_US = "contact-us",
  FAQ = "faq",
  USER_ITEM_DETAILS = "users/item/details",
  CHILD_REGISTER = "register",
  HOME_GUEST_REGISTER = "register",
  // LOGIN_USER = 'auth/user/login',
  LOGIN_USER = "/login",
  KPI = "admin/kpi",
  INVITE_CODES = "invite-codes",
  MY_HIVE = "/users/my-hive",
  HOME = "/",
  HOME_GUEST = "/", // was /home/guest
  POST_SIGNUP = "auth/auth/post-signup",
  MY_PROFILE = "/users/profile"
}
