import {Injectable} from "@angular/core";

@Injectable()
export class ImagePathService {

  public HOMEPAGE_ABOUT_SHAREBEE = "../../../../assets/img/about-sharebee.jpeg";
  public HOMEPAGE_COMMUNITY = "../../../../assets/img/community.jpeg";
  public HOMEPAGE_HIVE = "../../../../assets/img/hive-homepage.jpeg";
  public NO_IMAGE_AVAILABLE = "../../../../assets/img/no_image_available.jpeg";
  public SHAREBEE_LOGO = "../../../../assets/svg/logo_for_popup.svg";
  public FULL_LOGO = "../../../../assets/svg/full_logo.svg";
  public ABOUT_SHAREBEE = "../../../../assets/img/about-sharebee.jpeg";
  public KNOW_MORE = "../../../../assets/svg/know_more.svg";
  public HEADER_LOGO = "../../../../assets/svg/header_logo.svg";
  public HAMBURGER = "../../../../assets/svg/hamburger.svg";
  public FACEBOOK = "../../../../assets/svg/facebook.svg";
  public LINKEDIN = "../../../../assets/svg/linkedin.svg";
  public YOUTUBE = "../../../../assets/svg/youtube.svg";
  public INSTAGRAM = "../../../../assets/svg/instagram.svg";
  public TWITTER = "../../../../assets/svg/twitter_1.svg";
  public CLIPS_CONTAINER_LEFT = "../../../../assets/placeholders/climbing.jpg";

  public STRIPE_2 = "../../../../assets/img/faq/stripe_2.PNG";
}
