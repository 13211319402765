import {Injectable} from "@angular/core";
import { EndpointsConstant } from "@core/constants/endpoints.service";

@Injectable()
export class EndpointsService extends EndpointsConstant {

  public STRIPE_CLIENT_ID = process.env.STRIPE_CLIENT_ID;
  public STRIPE_KEY = "sk_test_CG4mCAkmPCQiCl8q5Fnop1aG";
  public PUBLISH_KEY = "pk_test_VshrnHokmFCKtoYwaq0NVGSb";
  public STRIPE_API = "https://connect.stripe.com/oauth/authorize";

  /**
   * Craete Item API
   *
   * @example
   * @request {
   * "category": "Test",
   * "images": [],
   * "title": "Test",
   * "rent_rate": 300,
   * "lend_for_free": "false",
   * "description": "Test",
   * "item_cost": 40000,
   * "other_information": "Test",
   * "service_date":"2012-1-1",
   * "insured": "false"
   * }
   *
   * @success {
   *    data: {
   *    "category": "Test",
   *    "images": [],
   *    "title": "Test",
   *    "rent_rate": 300,
   *    "lend_for_free": "false",
   *    "description": "Test",
   *    "item_cost": 40000,
   *    "other_information": "Test",
   *    "service_date":"2012-1-1",
   *    "insured": "false",
   *    "status": "Available",
   *    "location": "Bangalore"
   *    }
   * }
   *
   * @error
   * {
   * "error": {
   *    "code": "",
   *    "message": ""
   *    }
   * }

   */
  public CREATE_ITEM = `${this.API_HOST}/item`;
  public ITEM_DETAILS = `${this.API_HOST}/item`;

  public ITEM_BOOK = `${this.API_HOST}/order/book`;

  public GET_MY_ACTIVATES = `${this.API_HOST}/order/my-activity`;
  public GET_MY_ACTIVITY_DETAILS = `${this.API_HOST}/order/item-history`;
  public UPDATE_ORDER_STATUS = `${this.API_HOST}/order/update`;

  public GET_CATEGORY = `${this.API_HOST}/category`;

  public CREATE_REQUEST = `${this.API_HOST}/request`;

  public IMAGE_UPLOAD = `${this.API_HOST}/items/image/upload`;

  public SEARCH_ITEM = `${this.API_HOST}/items`;
  //  Search item
  public GUEST_USER_SEARCH_ITEM = `${this.API_HOST}/items/guest`;

  //  Search item
  public GET_ITEMS_UPLOADED_BY_ME = `${this.API_HOST}/items/my-stuff`;


  //  Search item
  public SETUP_STRIPE = `${this.API_HOST}/payment/authorize-stripe`;


  public SAVE_STRIPE_DETAILS = `${this.API_HOST}/payment/save-user-account-details`;


  public MAKE_PAYMENT = `${this.API_HOST}/payment`;

  // Delete an item
  public DELETE_ITEM = `${this.API_HOST}/items/delete`;

  // Delete an request
  public DELETE_REQUEST = `${this.API_HOST}/request/delete`;

  // Delete an item
  public DEACTIVATE_ITEM = `${this.API_HOST}/items/deactivate`;

  // Get Transaction History
  public GET_TRANSACTION_HISTORY = `${this.API_HOST}/order/my-history`;

  // Delete an item
  public EDIT_ITEM = `${this.API_HOST}/items/edit`;

  // Recently shared Item
  public RECENTLY_SHARED_ITEMS = `${this.API_HOST}/order/recent-transactions`;
  public LIST_CATEGORIES = `${this.API_HOST}/category/list`;


  public CONTACT_US_FORM_SUBMIT = `${this.API_HOST}/submit-query`;
  public ACCOUNT_VERIFICATION = `${this.API_HOST}/payment/account-verification`;

  public GET_COMMUNITY_REQUESTS = `${this.API_HOST}/request/list`;

  public POST__CREATE_HIVE = `${this.API_HOST}/hives/create`;
  public STRIPE_ACCOUNT_SETUP = `${this.API_HOST}/stripe/setup`;
  public VERIFY_ACCOUNT = `${this.API_HOST}/stripe/verify-token`;
  public STRIPE_CHECKOUT = `${this.API_HOST}/stripe/make-payment`;
  public UPDATE_PAYMENT_STATUS = `${this.API_HOST}/stripe/update-payment/`;
  public DELETE__REMOVE_HIVE = ({id}) => `${this.API_HOST}/hives/${id}`;
  public PUT__LEAVE_HIVE = ({id}) => `${this.API_HOST}/hives/${id}/leave`;
  public PUT__ADD_ITEM_INTO_HIVE = ({id}) => `${this.API_HOST}/hives/${id}/add`;
  public GET__HIVE_DETAILS = ({id}) => `${this.API_HOST}/hives/list/${id}/details`; // hive details
  public GET_HIVES = () => `${this.API_HOST}/hives`;
  public GET__HIVE_ITEM_LIST = ({id}) => `${this.API_HOST}/hives/${id}/items`; // hive list items
  public DELETE__REMOVE_HIVE_ITEM_FROM_LIST = ({id, itemId}) => `${this.API_HOST}/hives/${id}/items/${itemId}`;
  public PUT__UPDATE_NAME = ({id}) => `${this.API_HOST}/hives/${id}`;
  public PUT__INVITE_MEMBER = ({id}) => `${this.API_HOST}/hives/${id}/invite`;
  public GET__FETCH_MEMBERS = ({id}) => `${this.API_HOST}/hives/${id}/members`;
  public GET__ENROLL_INVITE = ({id, inviteId}) => `${this.API_HOST}/hives/${id}/enroll/${inviteId}`;
  public ADD__USER__TO__HIVE = ({hiveId}) => `${this.API_HOST}/hives/add-user-hive/${hiveId}`;
  public GET__ACCEPT_HIVE_MEMBER = ({id, userId}) => `${this.API_HOST}/hives/${id}/accept/${userId}`;
  public DELETE__REMOVE_HIVE_MEMBER = ({id, userId}) => `${this.API_HOST}/hives/${id}/member/${userId}`;
}
