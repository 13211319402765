<app-header-footer-layout [show_sidebar]='true'>
  <div class='transaction-history-page'>

    <section class="transaction-history_title-container">
      <p class='transaction-history_title'
         (click)="switchTransactionsHistory()"
         [ngClass]="{'active': showBottomBorrow}">
        {{'TRANSACTION_HISTORY.BORROWED_TITLE' | translate}}
      </p>

      <p class="transaction-history_title"
         (click)="switchTransactionsHistory()"
         [ngClass]="{'active': !showBottomBorrow}">
        {{'TRANSACTION_HISTORY.LENT_TITLE' | translate}}
      </p>
    </section>

    <section class='transaction-history_content'>

      <div class='transaction-history-label-container'>
            <span class='transaction-history-label_title' *ngFor='let label of labels'>
              {{ label }}
            </span>
      </div>

      <div class='transaction-history_details-container'
           *ngFor="let item of currentData">

            <span class='transaction-history_details_title'>
              {{item.item_name}}
            </span>

        <span class='transaction-history_details_title'>
              {{item.transaction_date | TimePipe}}
            </span>

        <span class='transaction-history_details_title'>
          {{item.customer_name}}
        </span>

        <span class='transaction-history_details_title' *ngIf="showBottomBorrow">
          {{item.rent_cost | number}}
          <!--{{item.rent_cost + (interestDeduction * item.rent_cost)}}-->
        </span>

        <span class='transaction-history_details_title' *ngIf="!showBottomBorrow">
          {{(item.rent_cost - ((item.rent_cost - (item.rent_cost/(1+interestDeduction)))*2)) | number}}
        </span>

      </div>

      <!--message when there are no items-->
      <div class="message" *ngIf="currentData?.length === 0">
        {{'TRANSACTION_HISTORY.NO_ITEMS_MESSAGE' | translate}}
      </div>
    </section>
  </div>

</app-header-footer-layout>
