<app-header-footer-layout [show_sidebar]="false" [centre]="true" class="request-community-item">
  <section class="request-colony_items">
    <h1 class="title">
      {{'COMMUNITY.TITLE' | translate}}
    </h1>
    <item-list [URL]="api_host" [items_per_page]="10" [limit]="30" #itemList>
      <div class="list-items">
        <ng-container *ngFor="let item of itemList.items">
          <app-shr-item-card class="item-card"
                      [title]="item.title"
                      [img_path]="item.images[0] || ''"
                      [item_rate]=""
                      [item_location]=""
                      (click)="requestUpload(item)"
          ></app-shr-item-card>
        </ng-container>

      </div>
    </item-list>
  </section>

  <popup-layout *ngIf="requestUploadPopUp && !createItem">
    <section class="request-item-colony__container">
      <div class="container-element">
        <label class="label md1 bold-font popup-title align_center">{{itemObject.title}}</label>
        <label class="label sm-1 quad font_abs align_center">requested by: {{itemObject.user?.name}}</label>
        <div class="request-item-colony__img">
          <img *ngIf = "itemObject?.src" [src]="itemObject.src">
          <img *ngIf="itemObject.src === ''" [src]="imageService.NO_IMAGE_AVAILABLE">
        </div>
        <div class="add-margin-10">
          <label class="text_area_colony sm-1" >{{(itemObject.description.length > 450) ? (itemObject.description | slice:0:450) + '....' : (itemObject.description)}}</label>
        </div>
        <div class="request-item__actions">
          <button (click)="requestUploadPopUp = !requestUploadPopUp" class="button">cancel</button>
          <button (click)="requestItem()" class="button active">upload</button>
        </div>
      </div>
    </section>
  </popup-layout>

  <app-create-item *ngIf="createItem" [request_item_id]="itemObject._id" (createItemFlag)="createItem = false; itemList.refresh()">

  </app-create-item>
</app-header-footer-layout>
