import {Component, Input, ViewEncapsulation, OnInit} from "@angular/core";
import {ItemApiService} from "../../item-api.service";
import {ToastrService} from "ngx-toastr";
import {EndpointsService} from "../../../shared/constants/endpoints.service";
import {ActivatedRoute, Router} from "@angular/router";
import {DomainResource} from "../../../../app/user-auth/configs/config.helper";
import {Role, UserServices} from "../../../../app/user-auth/shared/user.services";
import {ErrorCodeHandler} from "../../../../app/user-auth/shared/error.code.handler";
import {AppRoutePaths} from "@core/constants/route.path";
import { Title } from "@angular/platform-browser";
import qs from "qs";
import { checkUserLoggedIn } from "src/app/utils/backend-jwt";

@DomainResource({
  name: "FIND_SOMETHING",
  access: [Role.USER, Role.ADMIN]
})
@Component({
  selector: "app-find-something",
  templateUrl: "./find-something.component.html",
  styleUrls: ["find-something.component.scss"],
  encapsulation: ViewEncapsulation.None
})


export class FindSomethingComponent implements OnInit {
  // @ViewChild('item_list') item_list;
  @Input() filterCategory: string;
  @Input() searchQuery: string;
  queryItems: {[key: string]: unknown};
  itemList: any[] = [];
  findURL: string;
  showNoItemsMessage: boolean;
  createItem = false;
  canCreateItem = true;
  setupStripePopUp = false;
  updateProfile: boolean;
  itemsPerPage = 11;
  currentPage = 1;
  totalCount: number;
  isUserLoggedIn = false;
  loggInToSharebee: boolean;

  constructor(public apiService: ItemApiService,
              private toastService: ToastrService,
              private router: Router,
              public endPoints: EndpointsService,
              private user: UserServices,
              private http: ItemApiService,
              private error_handler: ErrorCodeHandler,
              private title: Title,
              private activatedRoute: ActivatedRoute) {
    this.findURL = this.endPoints.SEARCH_ITEM;
    if (checkUserLoggedIn()) {
      this.isUserLoggedIn = true;
      this.title.setTitle("Find Something | Sharebee");
    }
  }

  ngOnInit() {
    this.activatedRoute.queryParams
      .subscribe((search) => {
        const { query, category } = search;
        this.searchQuery = query;
        this.filterCategory = category;
        this.queryItems = search;
        this.itemReload();
      });
  }

  itemReload() {
    const search = Object.assign({},
      qs.parse(this.searchQuery),
      qs.parse(this.filterCategory)
    );

    this.router.navigate([],{
      relativeTo: this.activatedRoute,
      queryParams: search,
      queryParamsHandling: "merge", // remove to replace all query params by provided
    });

    this.getItems();
  }

  getItems() {
    this.apiService.getItems(this.queryItems, this.isUserLoggedIn)
      .subscribe(
        (result: any) => {
          console.warn({result});
          this.itemList = result.items;
          this.totalCount = result.totalCount;
          if (this.totalCount === 0) {
            this.showNoItemsMessage = true;
          } else {
            this.showNoItemsMessage = false;
          }
        },
        ({error}) => {
          this.toastService.error(error, "Error");
        }
      );
  }

  navigateTo(item: any) {
    this.router.navigate([AppRoutePaths.USER_ITEM_DETAILS],
      {
        queryParams: {
          id: item._id,
          borrowed_status: item.borrowed_status,
          duration: item.duration,
          borrowed_by_me: item.borrowed_by_me,
          order_id: item.order_id
        }
      });
  }

  showMessage(event) {
    this.showNoItemsMessage = event;
  }

  /**
   * Go to request page to request an item
   */
  requestItem() {
    if (this.isUserLoggedIn) {
      this.router.navigate(["request-item"],
        {
          queryParams: {
            createNewRequestItem: true
          }
        });
    } else {
      this.loggInToSharebee = true;
    }
  }

  createNewItem() {
    this.user.getUser()
      .then((user) => {
        if (user["profileUpdated"] || user["role"] === Role.GUEST) {
          if (this.canCreateItem) {
            this.createItem = !this.createItem;
          } else {
            this.setupStripePopUp = true;
          }
        } else {
          this.updateProfile = true;
        }
      });
  }

  createItemFlow() {
    this.setupStripePopUp = !this.setupStripePopUp;
    this.createItem = !this.createItem;
  }

  navigateToSetupAccount() {
    this.setupStripePopUp = false;
    this.router.navigate(["users/account-verification"], {queryParams: {howToSetUpStripeFlag: true}});
  }

  completeProfile() {
    this.updateProfile = false;
    this.router.navigate([AppRoutePaths.MY_PROFILE]);
  }

  onChangePage(page: number) {
    console.warn(page);
  }

  loginUser() {
    this.router.navigate([AppRoutePaths.LOGIN_USER]);
  }

  signUpUser() {
    this.router.navigate([AppRoutePaths.HOME_GUEST_REGISTER]);
  }

  close() {
    this.loggInToSharebee = false;
    this.showNoItemsMessage = false;
    this.searchQuery = "";
    this.itemReload();
  }
}

