import { NgModule } from "@angular/core";
import { RequestCommunityComponent } from "./request-community/request-community.component";
import { SharedModule } from "../shared/shared.module";
import { CommonModule } from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule.forChild()
  ],
  exports: [
  ],
  declarations: [
    RequestCommunityComponent,
  ],
  providers: [

  ]
})
export class RequestsModule {

}
